import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ActivitySelectBox = ({ placeholder, className, activities, onChange, bool, edit, defaultValue, clickOnSelect, handleAddNewAct, newActLoading }) => {

    const [showNewAct, setShowNewAct] = useState(false)

    const [selectedItems, setSelectedItems] = useState([])
    const [selectActType, setSelectType] = useState([])
    const [searchStaff, setSearchStaff] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    const [addAct, setAddAct] = useState()

    useEffect(() => {
        let orderedActivityIdsList = []
        let parentActivityId = []
        activities.map((i) => {
            if (i?.parent_activity_id === null || i?.parent_activity_id === 0) {
                parentActivityId.push(i)
            }
        })
        parentActivityId.map((i) => {
            orderedActivityIdsList.push(i)
            activities.map((j) => {
                if (i?.id === j?.parent_activity_id) {
                    orderedActivityIdsList.push(j)
                }
            })
        })
        setSearchStaff(orderedActivityIdsList)
    }, [activities])

    useEffect(() => {
        setSelectType([])
        setSelectedItems([])
    }, [bool])

    const handleSearchActivity = (e) => {
        setSearchStaff(activities.filter(item => item.name.toLowerCase().includes((e.target.value).toLowerCase())))
    }

    const handleFocus = () => {
        setIsOpen(!isOpen)
    }

    const handleActivityClick = (itemId, type) => {
        setShowNewAct(false)
        setSelectedItems([itemId])
        setSelectType(type)
        if (clickOnSelect) {
            setIsOpen(false)
        }
        onChange([itemId, type])
    }

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
            setShowNewAct(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const formatSelectedNames = () => {
        let temp
        activities?.map((i) => {
            if (i?.id === selectedItems[0] && i?.type === selectActType) {
                temp = i?.name
            }
        })
        return temp
    }

    const renderActivity = (activity) => {
        const { id, name, parent_activity_id, type } = activity
        const isSubActivity = parent_activity_id !== null
        const marginLeft = isSubActivity ? 'ml-5' : ""

        const isActive = selectedItems.includes(id)

        return (
            <li
                key={id}
                className={`flex bg-white cursor-pointer ${parent_activity_id ? "font-medium" : "font-extrabold"} ${marginLeft} ${isActive ? "bg-gray-200" : "hover: bg-gray-100"}`}
                onClick={() => { handleActivityClick(id, activity.type) }}
                style={{ textTransform: "capitalize" }}
            >
                <span className={`mx-2 py-2 flex active:bg-gray-300 active:text-black ${type === "workhours_activity" ? "pl-0" : null} `}>
                    {type === "workhours_activity" ?
                        <p className='pl-0'>{name}</p>
                        :
                        <p className={`${parent_activity_id ? "ml-1" : null}`}>{name}</p>
                    }
                </span>
            </li>
        )
    }

    return (
        <div className='relative flex' ref={dropdownRef}>
            <div>
                <input
                    type='text'
                    className={`${clickOnSelect ? "" : "ml-4"} cursor-pointer ${className} rounded-xl text-base border`}
                    style={{ minWidth: edit ? "200px" : "480px" }}
                    placeholder={placeholder}
                    onClick={handleFocus}
                    defaultValue={defaultValue}
                    value={formatSelectedNames()}
                    readOnly
                />

                {isOpen && (
                    <div className={`${clickOnSelect ? "" : "ml-4"} absolute z-10 bg-white shadow border border-gray-300 rounded-xl`} style={{ minWidth: edit ? "210px" : "480px" }}>
                        {!edit ?
                            <div className='mx-4 mt-4'>
                                <input
                                    type="search"
                                    className='rounded-2xl border py-2 px-4'
                                    placeholder='Search Activity'
                                    style={{ minWidth: "450px" }}
                                    onChange={e => handleSearchActivity(e)}
                                />
                            </div>
                            :
                            null
                        }
                        <ul className='py-2 max-h-60 overflow-y-auto '>
                            {searchStaff.map((activity) => renderActivity(activity))}
                        </ul>
                        {!edit ?
                            <div className='mx-4 mt-2 my-4'>
                                <p className='font-semibold text-base mb-2 cursor-pointer' style={{ color: "#186DEC" }} onClick={() => { setShowNewAct(!showNewAct); setIsOpen(false) }}>Add new activity +</p>
                                {showNewAct &&
                                    <div className='relative'>
                                        <input type='text' className='border rounded-xl py-2 px-4' disabled={newActLoading} placeholder='Enter Activity' style={{ minWidth: "450px" }} onChange={e => setAddAct(e.target.value)} />
                                        {!newActLoading ?
                                            <p className='absolute cursor-pointer right-8 bottom-3 text-sm' style={{ color: addAct ? "#F16253" : "#696F79" }} onClick={() => { handleAddNewAct(addAct); setIsOpen(false) }}>Add</p>
                                            :
                                            <p className='absolute cursor-pointer right-8 bottom-3 text-sm' style={{ color: "#696F79" }}>Adding...</p>

                                        }

                                    </div>
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                )}
            </div>
            {isOpen ?
                <FaChevronUp onClick={handleFocus} className={`cursor-pointer relative   text-l ${edit ? "right-8 top-3" : "right-8 top-3"}`} />
                :
                <FaChevronDown onClick={handleFocus} className={`cursor-pointer relative text-l ${edit ? "right-8 top-3" : "right-8 top-3"}`} />
            }
        </div>
    )
}

export default ActivitySelectBox