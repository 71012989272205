import produce from 'immer'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import Modal from 'react-modal';
import qcAtom, { fetchPierLegends, fetchChecklist, fetchTypeTracker, fetchTrackerTypeDataAtom, updateMapType, createUpdateDeleteQcChecklist } from '../../../atoms/QcChecklistAtom/qcAtom'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { RiEdit2Fill } from 'react-icons/ri';
import { unstable_usePrompt } from 'react-router-dom/dist';
import MultipleValueTextInput from 'react-multivalue-text-input'
import QcBlock from './qcBlock';
import { toLower } from 'lodash';
import { create_update_checklist } from '../../../dal/qcCheckpoints/qc-dal';
import { AiOutlineInfoCircle } from 'react-icons/ai';

function Tab({ currentTab, text, editMode, onClick }) {
    return (
        <div className='w-1/3 text-center h-full'>
            {editMode ?
                <p className={`text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`}>{text}</p>
                :
                <p className={`cursor-pointer text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`} onClick={() => onClick(text)}>{text}</p>
            }
        </div>
    )
}

function QcTrackerChecklist(props) {

    const params = useParams()

    const [listQcData] = useAtom(qcAtom)

    const [, setFetchPierLegends] = useAtom(fetchPierLegends)
    const [, setMapType] = useAtom(fetchTypeTracker)
    const [, setfetchTrackerDatas] = useAtom(fetchTrackerTypeDataAtom)
    const [, setFetchChecklist] = useAtom(fetchChecklist)
    const [, setUpdateMapType] = useAtom(updateMapType)
    const [, setUpdateCreatecheckpoints] = useAtom(createUpdateDeleteQcChecklist)

    const [loading, setLoading] = useState({ boolean: false, data: "Fetching..." })
    const [checklistLoading, setChecklistoading] = useState({ boolean: false, data: "Fetching..." })
    const [btnLoading, setBtnLoading] = useState({ type: null, bool: false })
    const [editMode, setEditMode] = useState(false)
    const [checklistData, setChecklistData] = useState({ tab: "Piers", status: 0, project_mapped_checklist: false, type: 0, lable: "", id: null, design_pier_ht: "", custom_inputs: [], checkpoints: [{ name: "", acceptance_criteria: "", inspection_type: "" }] })
    const [selectedItem, setSeletedItem] = useState()
    const [dropdownModal, setDropdownModal] = useState({ boolean: false, index: null, values: { values: [] } })
    const [fieldConfModal, SetFieldConfModal] = useState({ boolean: false, index: null, changedValue: null })


    const QcTabs = ["Piers", "Structure", "Module"]

    useEffect(() => {
        init()
    }, [params.id])

    useEffect(() => {
        setChecklistData(produce((draft) => { draft.tab = QcTabs[0]; draft.type = 1 }))
        setEditMode(false)
    }, [params.id])

    unstable_usePrompt({
        when: editMode,
        message: "You are leaving the page without completing the edits, the Checkpoint will revert to last saved version"
    })

    useEffect(() => {
        let tracker_type
        if (listQcData?.typeMode[`${params?.id}_${toLower(checklistData?.tab)}`] === 0) {
            tracker_type = parseInt(params?.id)
        }
        else {
            tracker_type = selectedItem
        }
        if (listQcData?.mappedChecklist[`${tracker_type}_${toLower(checklistData?.tab)}`]) {
            if (editMode) {
                if (checklistData?.type === 1) {
                    let labelName = null
                    if (checklistData.tab === QcTabs[0]) {
                        labelName = listQcData?.trackerDataPierTT?.pier_parts?.find(o => o?.id === selectedItem)?.label
                    }
                    else {
                        labelName = listQcData?.trackerDataPierTT?.tt_parts?.find(o => o?.id === selectedItem)?.label
                    }
                    setChecklistData(produce((draft) => { draft.lable = labelName }))
                }
            }
            else {
                loadQcChecklist(listQcData?.mappedChecklist[`${tracker_type}_${toLower(checklistData?.tab)}`])
            }
        }
        else {
            setChecklistoading({ boolean: false, data: "Fetching..." })
            let labelName = null
            if (checklistData?.type && listQcData?.trackerDataPierTT?.pier_parts && listQcData?.trackerDataPierTT?.tt_parts) {
                if (checklistData.tab === QcTabs[0]) {
                    console.log(selectedItem)
                    labelName = listQcData?.trackerDataPierTT?.pier_parts?.find(o => o?.id === selectedItem)?.label
                }
                else {
                    labelName = listQcData?.trackerDataPierTT?.tt_parts?.find(o => o?.id === selectedItem)?.label
                }
            }
            setChecklistData(produce((draft) => {
                draft.checkpoints = [{ name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.id = null;
                draft.lable = labelName;
                draft.custom_inputs = []
            }))
        }
    }, [listQcData?.mappedChecklist, selectedItem, checklistData?.tab, params?.id])

    const init = async () => {
        try {
            setLoading({ boolean: true, data: "Fetching..." })
            await setMapType()
            await setFetchPierLegends()
            await setfetchTrackerDatas({ tracker_type_id: params?.id })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading({ boolean: false, data: "Fetching..." })
            setChecklistoading({ boolean: !checklistLoading?.boolean, data: "Fetching..." })
        }
    }

    const loadQcChecklist = async (id) => {
        try {
            setChecklistoading({ boolean: true, data: "Fetching..." })
            await setFetchChecklist({ checklistId: id })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setChecklistoading({ boolean: false, data: "Fetching..." })
        }
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleChangePart = async (e) => {
        try {
            setSeletedItem(e)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleToggleType = (id) => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.type = id }))
        }
        let labelName = null
        if (checklistData.tab === QcTabs[0]) {
            labelName = listQcData?.trackerDataPierTT?.pier_parts?.find(o => o?.id === selectedItem)?.label
        }
        else {
            labelName = listQcData?.trackerDataPierTT?.tt_parts?.find(o => o?.id === selectedItem)?.label
        }
        setChecklistData(produce((draft) => { draft.lable = labelName }))
    }

    useEffect(() => {
        let partIt
        if (listQcData.trackerDataPierTT?.pier_parts && listQcData.trackerDataPierTT?.tt_parts) {
            if (checklistData.tab === QcTabs[0]) {
                let pierData = listQcData.trackerDataPierTT.pier_parts.find(o => o?.order === 0)
                partIt = pierData?.id
            }
            else {
                let ttData = listQcData.trackerDataPierTT.tt_parts.find(o => o?.order === 0)
                partIt = ttData?.id
            }
            setSeletedItem(partIt)
        }
    }, [listQcData.trackerDataPierTT?.pier_parts, listQcData.trackerDataPierTT?.tt_parts])

    function fetchChecklistDatas() {
        let labelName = null
        if (checklistData?.type && listQcData?.trackerDataPierTT?.pier_parts && listQcData?.trackerDataPierTT?.tt_parts) {
            if (checklistData.tab === QcTabs[0]) {
                labelName = listQcData?.trackerDataPierTT?.pier_parts?.find(o => o?.id === selectedItem)?.label
            }
            else {
                labelName = listQcData?.trackerDataPierTT?.tt_parts?.find(o => o?.id === selectedItem)?.label
            }
        }
        let mapType
        if (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === 0) {
            mapType = listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`]
        }
        else {
            mapType = 1
        }
        let tracker_type
        if (listQcData?.typeMode[`${params?.id}_${toLower(checklistData?.tab)}`] === 0) {
            tracker_type = parseInt(params?.id)
        }
        else {
            tracker_type = selectedItem
        }
        if (listQcData.checklistDeatils && listQcData?.mappedChecklist[`${tracker_type}_${toLower(checklistData?.tab)}`]) {
            const sortedCustomInputs = [...(listQcData?.checklistDeatils?.custom_inputs || [])].sort((a, b) => a.id - b.id);
            setChecklistData(produce((draft) => {
                draft.type = mapType
                draft.checkpoints = listQcData.checklistDeatils?.checkpoints?.length > 0 ? listQcData.checklistDeatils?.checkpoints : [{ name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.project_mapped_checklist = listQcData.checklistDeatils?.project_mapped_checklist
                draft.id = listQcData.checklistDeatils?.id;
                draft.lable = labelName;
                draft.custom_inputs = sortedCustomInputs;
            }))
        }
        else {
            setChecklistData(produce((draft) => {
                draft.type = mapType;
                draft.checkpoints = [{ name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.id = null;
                draft.lable = labelName;
                draft.project_mapped_checklist = false
                draft.custom_inputs = []
            }))
        }
    }

    useEffect(() => {
        fetchChecklistDatas()
    }, [listQcData?.trackerDataPierTT, listQcData.checklistDeatils])

    const handleChangetab = (e) => {
        setChecklistData(produce((draft) => { draft.tab = e }))
        let partIt
        if (listQcData.trackerDataPierTT?.pier_parts && listQcData.trackerDataPierTT?.tt_parts) {
            if (e === QcTabs[0]) {
                partIt = listQcData.trackerDataPierTT.pier_parts.find(o => o?.order === 0)?.id
            }
            else {
                partIt = listQcData.trackerDataPierTT.tt_parts.find(o => o?.order === 0)?.id
            }
            setSeletedItem(partIt)
        }
    }

    const handleSaveApply = async () => {
        try {
            setBtnLoading({ type: "saveAndApply", bool: true })
            let disableIds = false
            if (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] !== checklistData.type) {
                await setUpdateMapType({ map_type: checklistData.type, tracker_type_id: parseInt(params?.id), checklist_type: toLower(checklistData?.tab) })
                console.log("updated Map type")
                disableIds = true
            }
            await setUpdateCreatecheckpoints({ data: create_update_checklist(checklistData, disableIds), tab: toLower(checklistData?.tab), checklist_map_type: checklistData?.type, tracker_type_id: parseInt(params?.id), part_id: selectedItem, applyStatus: true })
            toast.success("Created Successfully")
            setEditMode(false)
        }
        catch (err) {
            console.log(err)
            toast.err("Something went wrong")
        }
        finally {
            setBtnLoading({ type: null, bool: false })
        }
    }

    const onChecklistSubmit = async () => {
        try {
            setBtnLoading({ type: "save", bool: true })
            let disableIds = false
            if (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] !== checklistData.type) {
                await setUpdateMapType({ map_type: checklistData.type, tracker_type_id: parseInt(params?.id), checklist_type: toLower(checklistData?.tab) })
                console.log("updated Map type")
                disableIds = true
            }
            await setUpdateCreatecheckpoints({ data: create_update_checklist(checklistData, disableIds), tab: toLower(checklistData?.tab), checklist_map_type: checklistData?.type, tracker_type_id: parseInt(params?.id), part_id: selectedItem })
            toast.success("Created Successfully")
            setEditMode(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBtnLoading({ type: null, bool: false })
        }
    }

    const handleCancel = () => {
        setEditMode(false)
        fetchChecklistDatas()
    }

    const handleAddCheckPoint = () => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.checkpoints.push({ name: "", acceptance_criteria: "", inspection_type: "" }) }))
        }
    }

    const handleRemoveCheckpoint = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.checkpoints[index]["delete"] = true }))
        }
    }

    const handleFindTrackerName = () => {
        let temp = props.listTracker.find(o => o.id === parseInt(params.id))
        return temp?.tracker_type
    }

    const handleMultiInputSubmit = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[dropdownModal.index].values = dropdownModal.values }))
        setDropdownModal({ boolean: false, index: null, values: { values: [] } })
    }

    const handleAddCustom = () => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs.push({
                    type: 0,
                    values: {},
                    name: "Custom Label"
                })
            }))
        }
    }

    const handleDeleteCustomFields = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs[index]["delete"] = true
            }))
        }
    }

    const handleCheckCountCustomFields = () => {
        let temp = []
        checklistData?.custom_inputs.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        return temp
    }

    const handleChangeFields = (e, index) => {
        if (parseInt(e.target.value) === 3) {
            setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.values?.values ? checklistData.custom_inputs[index]?.values?.values : [] } })
        }
        setChecklistData(produce((draft) => { draft.custom_inputs[index].type = parseInt(e.target.value) }))
    }

    const handleFieldConfirmCancel = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].type = 3 }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleFieldConfirm = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].type = fieldConfModal.changedValue }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    if (loading.boolean || checklistLoading?.boolean) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full' style={{ height: "calc(100vh - 200px)" }}>
                <p className='loader mb-1'></p>
                <p className='font-bold text-lg ml-3'>{loading.data}</p>
            </div>
        )
    }

    return (
        <div className='w-full flex h-full'>
            <div className={`w-[100px] border pt-[120px]`}>
                {listQcData?.trackerDataPierTT?.pier_parts && listQcData?.trackerDataPierTT?.tt_parts &&
                    <QcBlock
                        editMode={editMode}
                        data={listQcData.trackerDataPierTT}
                        lockAll={checklistData.type}
                        pierLegend={listQcData?.pierLegends}
                        selectedTab={checklistData.tab}
                        onSelectItem={e => editMode ? listQcData?.typeMode[`${params?.id}_${toLower(checklistData?.tab)}`] === 0 ? handleChangePart(e) : toast.error("Please save your changes before switching") : handleChangePart(e)}
                        selectedItem={selectedItem}
                    />
                }
            </div>
            <div className='h-full' style={{ width: "calc(100% - 100px)" }}>
                <div className='flex justify-between space-x-5 py-3'>
                    <div className='flex'>
                        <p className='text-base font-medium px-4 flex items-center'>{handleFindTrackerName()}</p>
                        <div className={`flex text-sm cursor-pointer border rounded-full font-medium p-1 ${!editMode ? "border-gray-100 bg-gray-50" : "border-red-100 bg-red-50"} mr-4`}>
                            <div className={`flex ${checklistData.type ? "" : `${!editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full`}`}>
                                <p className={`${checklistData.type ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => handleToggleType(0)}>Combined</p>
                            </div>
                            <div className={`flex ${checklistData.type ? `${!editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full` : ""}`}>
                                <p className={`${checklistData.type ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => handleToggleType(1)}>Individual {checklistData.tab === QcTabs[0] ? "Piers" : "Bays"}</p>
                            </div>
                        </div>
                    </div>
                    {editMode ?
                        <div className='bg-white flex items-center space-x-5 px-5 justify-end'>
                            {/* if its is combined checklist save and apply is disabled */}
                            <button className='border-none btn bg-red-550 flex space-x-3 text-xs items-center text-white rounded-full px-4' disabled={checklistData.type === 0} onClick={() => handleSaveApply()}>
                                {btnLoading.bool && btnLoading.type === "saveAndApply" &&
                                    <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                }
                                <p>
                                    {checklistData.tab === QcTabs[0] ?
                                        <div>
                                            <p>Save and Apply to all Piers</p>
                                            <p>in this Tracker type</p>
                                        </div>
                                        :
                                        <div>
                                            <p>Save and Apply to all Bays</p>
                                            <p>in this Tracker type</p>
                                        </div>
                                    }
                                </p>
                            </button>
                            <button className='border-none flex space-x-3 items-center btn bg-red-550 text-white rounded-full px-4' onClick={onChecklistSubmit}>
                                {btnLoading.bool && btnLoading.type === "save" &&
                                    <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                }
                                <p>Save</p>
                            </button>
                            <button className='btn border-2 cursor-pointer px-4 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => handleCancel()}>Cancel</button>
                        </div>
                        :
                        <div className='bg-white z-10 flex items-center space-x-5 px-5'>
                            <button className='btn border-2 rounded-full bg-white px-5 cursor-pointer text-gray-500 hover:text-white' onClick={() => setEditMode(true)}>Edit</button>
                        </div>
                    }
                </div>
                <hr />
                <div className={`flex justify-around border-b w-full h-[40px]`}>
                    {QcTabs.map((tab) => {
                        return (
                            <Tab currentTab={checklistData.tab} text={tab} editMode={editMode} onClick={e => {
                                if (!editMode) {
                                    handleChangetab(e)
                                }
                            }} />
                        )
                    })}
                </div>
                <div className={`w-full overflow-auto ${editMode ? "" : "bg-gray-100 cursor-auto"}`} style={{ height: "calc(100vh - 195px)" }}>
                    <div className='w-full'>
                        {checklistData.type ?
                            <div className='flex px-5 items-center space-y-3 mt-3 my-4 w-[55%]'>
                                <p className='text-base font-bold w-[50%]'>Label</p>
                                {checklistData?.tab === QcTabs[0] ?
                                    <p className='text-base font-bold flex space-x-2 items-center'>
                                        <p>Pier</p>
                                        <p>{checklistData?.lable}</p>
                                    </p>
                                    :
                                    <p className='text-base font-bold flex space-x-2 items-center'>
                                        <p>Bay</p>
                                        <p>{checklistData?.lable?.split("bay")[1]}</p>
                                    </p>
                                }
                            </div>
                            :
                            null
                        }
                        <div className='w-full'>
                            {checklistData?.custom_inputs?.length > 0 && checklistData?.custom_inputs.map((i, index) => {
                                if (!i?.delete) {
                                    return (
                                        <div className='flex space-x-8 px-5 items-center space-y-3'>
                                            <div className='w-[50%] flex items-center space-x-8 space-y-3 justify-between'>
                                                <div className='flex space-x-2 items-center w-[45%]'>
                                                    <input className={`text-base font-medium border-2 rounded-xl px-4 py-2 mt-2 flex items-center w-full focus:outline-none ${!editMode && "cursor-pointer"}`} disabled={!editMode} value={i?.name} name="label_01" onChange={e => setChecklistData(produce((draft) => { draft.custom_inputs[index].name = e.target.value }))} />
                                                    <RiEdit2Fill className='text-xl mt-1' />
                                                </div>
                                                <select required disabled={!editMode} className={`px-4 py-2 rounded-xl select-bordered border-2 w-[40%] ${editMode ? "cursor-pointer" : "bg-gray-100"}`} onChange={e => handleChangeFields(e, index)}>
                                                    <option selected={checklistData.custom_inputs[index]?.type === 0} value={0}>Integer</option>
                                                    <option selected={checklistData.custom_inputs[index]?.type === 1} value={1}>Float</option>
                                                    <option selected={checklistData.custom_inputs[index]?.type === 2} value={2}>Free Text</option>
                                                    <option selected={checklistData.custom_inputs[index]?.type === 3} value={3}>Dropdown</option>
                                                </select>
                                                <MdDelete className='cursor-pointer' onClick={() => handleDeleteCustomFields(index)} />
                                            </div>
                                            <div style={{ display: checklistData.custom_inputs[index]?.type === 3 ? "" : "none" }}>
                                                <p className={`${editMode ? "cursor-pointer underline underline-offset-2 text-blue-500" : "text-gray-500"} text-sm`} onClick={() => { editMode && setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.values ? checklistData.custom_inputs[index]?.values : [] } }) }}>View & edit dropdown options</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {handleCheckCountCustomFields().length < 3 && <p className='cursor-pointer flex ml-5 font-semibold w-[40%] my-3' onClick={handleAddCustom}>+ Add Custom Fields</p>}
                            <Modal
                                isOpen={fieldConfModal.boolean}
                                style={customConfirmStyle}
                                contentLabel='Type Confirmation'
                            >
                                <div className='p-2'>
                                    <p className='mb-4'>The dropdown options added already will get deleted, please confirm</p>
                                    <div className='flex justify-end space-x-5 font-semibold'>
                                        <p className='cursor-pointer text-gray-500' onClick={handleFieldConfirmCancel}>Cancel</p>
                                        <p className='cursor-pointer text-red-550' onClick={handleFieldConfirm}>Confirm</p>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={dropdownModal.boolean}
                                style={customConfirmStyle}
                                contentLabel='MultipleValueTextInput'
                            >
                                <div className='p-3 w-[500px]'>
                                    <p className='font-medium text-base mb-2'>{checklistData.custom_inputs[dropdownModal.index]?.name}</p>
                                    <div className='flex space-x-3 items-center text-xs mb-3'>
                                        <AiOutlineInfoCircle className='text-gray-400' />
                                        <p className='text-gray-400'>Add required drop down options and press Enter</p>
                                    </div>
                                    <MultipleValueTextInput
                                        onItemAdded={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                        onItemDeleted={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                        name="item-input"
                                        className={"w-[40%] p-1 mt-5 border-b-2"}
                                        placeholder=""
                                        values={checklistData.custom_inputs[dropdownModal.index]?.values?.values ? checklistData.custom_inputs[dropdownModal.index]?.values?.values : []}
                                    />
                                    <div className='flex space-x-5 justify-end my-3'>
                                        <p className='cursor-pointer font-medium text-gray-500' onClick={() => setDropdownModal({ boolean: false, index: null, values: { values: [] } })}>Cancel</p>
                                        <button className='cursor-pointer font-medium text-red-550' onClick={handleMultiInputSubmit}>Save</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className='w-full mt-6'>
                        <div className='flex justify-between px-5 mb-2'>
                            <p className='font-bold w-[30%]'>Checkpoint</p>
                            <p className='font-bold w-[30%]'>Acceptance Criteria</p>
                            <p className='font-bold w-[30%]'>Inspection Type</p>
                            <p></p>
                        </div>
                        <hr />
                        <div className='w-full'>
                            {checklistData.checkpoints && checklistData.checkpoints.map((formData, formIndex) => {
                                if (!formData.delete) {
                                    return (
                                        <div className='w-full flex justify-between px-5 my-4' key={`${formIndex}`}>
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.name} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].name = e.target.value }))} />
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.acceptance_criteria} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].acceptance_criteria = e.target.value }))} />
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.inspection_type} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.checkpoints[formIndex].inspection_type = e.target.value }))} />
                                            {/* <p className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)}>D</p> */}
                                            <p><MdDelete className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)} /></p>
                                        </div>
                                    )
                                }
                            })}
                            <p className='cursor-pointer mx-5 text-base font-bold' onClick={handleAddCheckPoint}>+ Add More</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QcTrackerChecklist
