import React from 'react'
import { GoDotFill } from 'react-icons/go'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

function LaydownList({
    currentLaydown,
    scopeEditMode,
    listLaydown,
    viewToggle,
    setViewToggle,
    loading,
    inventoryLoader,
    selectedBlocks,
    setSelectedBlocks,
    setIndividualBlockData,
    handleClickLaydown
}) {

    const navigate = useNavigate()

    return (
        <div className='border-x h-full'>
            <div className='overflow-auto flex flex-col relative h-full pr-2' style={{ height: "calc(100vh - 100px)", width: "445px" }} >
                <div className='flex justify-between items-center px-4'>
                    <div className='flex justify-center items-center h-[60px]'>
                        {currentLaydown && !scopeEditMode ?
                            <div className={`flex text-xs font-semibold ${currentLaydown ? "cursor-pointer" : "cursor-auto"} border rounded-full p-0.5 bg-red-50 border-red-100 mr-4`}>
                                <div className={`flex items-center ${viewToggle === "Inventory" ? "bg-red-550 border-none rounded-full" : ""} px-1`} onClick={() => {
                                    if (currentLaydown) {
                                        setViewToggle("Inventory")
                                        // setSelectedBlocks({ bool: true, data: selectedBlocks?.data, initSelectedBlocks: {} })
                                        // setIndividualBlockData({ bool: false, block_id: null, trackerData: {} })
                                        if (viewToggle === "Scope") {
                                            navigate(`/dashboard/logistics-management/${currentLaydown}/inventory`)
                                        }
                                    }
                                }}>
                                    <p className={`${viewToggle === "Inventory" ? "text-white pr-3 pl-1.5 py-1.5" : "text-gray-400 pr-3 pl-1.5 py-1"} mr-1`}>Inventory</p>
                                </div>
                                <div className={`flex ${viewToggle === "Scope" ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => {
                                    if (currentLaydown && !inventoryLoader.bool) {
                                        setViewToggle("Scope")
                                        if (viewToggle === "Inventory") {
                                            navigate(`/dashboard/logistics-management/${currentLaydown}/scope`)
                                        }
                                    }
                                }}>
                                    <p className={`${viewToggle === "Scope" ? "text-white pr-3 pl-1.5 py-1.5" : "text-gray-400 pr-3 pl-1.5 py-1 mt-0.5"} ml-1`}>
                                        <p>Scope</p>
                                    </p>
                                </div>
                            </div>
                            :
                            <div className={`flex text-xs cursor-auto border rounded-full font-semibold p-0.5 bg-gray-50 border-gray-100 mr-4`}>
                                <div className={`flex items-center ${viewToggle === "Inventory" ? "bg-gray-200 border-none rounded-full" : ""} px-1`}>
                                    <p className={`${viewToggle === "Inventory" ? "text-white pr-3 pl-1.5 py-1.5" : "text-gray-400 pr-3 pl-1.5 py-1"} mr-1`}>
                                        <p>Inventory</p>
                                    </p>
                                </div>
                                <div className={`flex items-center ${viewToggle === "Scope" ? "bg-gray-200 border-none rounded-full" : ""} px-1`}>
                                    <p className={`${viewToggle === "Scope" ? "text-white pr-3 pl-1.5 py-1.5" : "text-gray-400 pr-3 pl-1.5 py-1"} ml-1`}>
                                        <p>Scope</p>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <hr />
                <p className='font-medium text-xl flex space-x-3 items-center mx-5 py-4 items-center mt-0.5'>
                    <p>Laydown List</p>
                    {loading && <p className='loader' style={{ width: "21px", height: "21px" }}></p>}
                </p>
                <div className='mx-5 flex flex-col'>
                    {listLaydown?.data?.map((laydown) => {
                        return (
                            <div className={`${!scopeEditMode && "cursor-pointer"} text-sm flex justify-between items-center px-3 py-2 my-2 rounded-full ${currentLaydown === laydown?.id ? "font-semibold text-base bg-gray-200" : `font-medium ${!scopeEditMode && "hover:bg-gray-100"}`}`} onClick={() => handleClickLaydown(laydown?.id)}>
                                <div className='flex space-x-2 items-center'>
                                    {currentLaydown === laydown?.id ?
                                        <IoIosArrowBack />
                                        :
                                        <IoIosArrowForward />
                                    }
                                    <p>{laydown?.name}</p>
                                </div>
                                {currentLaydown === laydown?.id && <GoDotFill className='text-green-500 text-xl' />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default LaydownList
