import { useAtom } from 'jotai'
import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import toast from 'react-hot-toast';
import MultipleValueTextInput from 'react-multivalue-text-input'
import Modal from 'react-modal';
import layoutAtom, { createCustomFields, createDelivery, createDeliveryLaydownId, deleteDelivery, downloadDeliveryData, fetchAllInventoryDatas, fetchCustomFields, fetchDeliveryData, fetchDeliveryLaydownId, fetchGetLayout } from '../../../atoms/layoutAtom';
import mileStone, { fetchMileStone, getDeliveryReferenceAliasNameAtom, updateDeliveryReferenceAliasNameAtom } from '../../../atoms/mileStoneAtom';
import { BiArrowBack } from "react-icons/bi"
import { IoMdRemoveCircleOutline } from "react-icons/io"
import { MdModeEditOutline } from "react-icons/md"
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import { RxUpload } from "react-icons/rx"
import { IoSearchSharp } from "react-icons/io5"
import { MdOutlineFileDownload } from "react-icons/md"
import { VscClose } from "react-icons/vsc"
import { RiEdit2Fill } from "react-icons/ri"
import { MdDelete } from "react-icons/md"
import { SlCalender } from "react-icons/sl"
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaFileMedical } from 'react-icons/fa'
import { CgSoftwareDownload } from "react-icons/cg"
import axios from 'axios'
import { saveAs } from 'file-saver';
import ImageViewer from "react-simple-image-viewer";
import 'react-clock/dist/Clock.css';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from "uuid";
import produce from "immer";
import PDFViewer from '../../manageStaff/PDFViewer';
import TimePicker from 'react-time-picker';
import { DateFormat } from '../../../components/dateFormat';
import Dropdown from '../../../components/dropdown';
import PrimaryButton from '../../../components/primaryButton';

function ManageDelivery({
    deliveryModal,
    setDeliveryModal,
    project_name,
    formattedDate
}) {

    const _ = require("lodash")
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
    const { register, handleSubmit, reset } = useForm()

    const searchRef = useRef(null)
    const deliveryNameRef = useRef(null)
    const tableRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false);
    const [deleteDeliveryLoader, setDeleteDeliveryLoader] = useState(false)
    const [imageViewerLoad, setImageViewerLoad] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [laydownLoader, setLaydownLoader] = useState(false)
    const [deliveryLoader, setDeliveryLoader] = useState(false)
    const [showDescription, setShowDescription] = useState()
    const [exitModal, setExitModal] = useState(false)
    const [isPDFModalShow, setIsPDFModalShow] = useState(false);
    const [customDltWarning, setCustomDltWarning] = useState({ bool: false, index: null })
    const [prevSelectedLaydown, setPrevSelectedLaydown] = useState(null)
    const [dropdownModal, setDropdownModal] = useState({ boolean: false, index: null, values: { values: [] } })
    const [laydownSearch, setLaydownSearch] = useState({ laydown: false, laydownId: null, part: false, partId: null })
    const [docsOpen, setDocsOpen] = useState({ boolean: false, data: null })
    const [images, setImages] = useState([])
    const [onSiteData, setOnSiteData] = useState([])
    const [filterLaydown, setFilterLaydown] = useState([])
    const [mileStoneData, setMileStoneData] = useState([])
    const [deliveryDocs, setdeliveryDocs] = useState([])
    const [deliveryNameEdit, setDeliveryNameEdit] = useState({ bool: false, data: "Delivery Reference" })
    const [docRenameBox, setDocRenameBox] = useState(null)
    const [docNameEdit, setDocNameEdit] = useState([])
    const [docNameChange, setDocNameChange] = useState()
    const [docCount, setDocCount] = useState([])
    const [addEntry, setAddEntry] = useState({ boolean: false, data: [] })
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [deliveryStep, setDeliveryStep] = useState(0)
    const [customFieldsData, setCustomFieldsData] = useState([])
    const [deliveryDatas, setDeliveryDatas] = useState([])
    const [laydownTable, setLaydownTable] = useState([])
    const [deliveryCustFieData, setDeliveryCustFieData] = useState([])
    const [deliveryId, setDeliveryId] = useState()
    const [dltDelivery, setDltDelivery] = useState({ boolean: false, data: null });
    const [deliveryTime, setDeliveryTime] = useState(`${new Date().getHours()}:${new Date().getMinutes()}`)
    const [deliveryDate, setDeliveryDate] = useState(new Date())
    const [deliverySearch, setDeliverySearch] = useState({ boolean: false, data: null, dateBoolean: false, dateData: null })
    const [editDelivery, setEditDelivery] = useState({ boolean: false, data: {}, isEdit: false })

    const [listLayout] = useAtom(layoutAtom)
    const [listMileStone] = useAtom(mileStone)
    const [, setFetchMileStone] = useAtom(fetchMileStone)
    const [, setFetchLayout] = useAtom(fetchGetLayout)
    const [, setCreateDelivery] = useAtom(createDelivery)
    const [, setDeleteDelivery] = useAtom(deleteDelivery)
    const [, setDeliveryLaydownIds] = useAtom(fetchDeliveryLaydownId)
    const [, setFetchDelivery] = useAtom(fetchDeliveryData)
    const [, setFetchAllInventory] = useAtom(fetchAllInventoryDatas)
    const [, setCreateCustomFields] = useAtom(createCustomFields)
    const [, setCreateBomDelivery] = useAtom(createDeliveryLaydownId)
    const [, setFetchCustomFields] = useAtom(fetchCustomFields)
    const [, setDownloadDeliveryData] = useAtom(downloadDeliveryData)
    const [, updateDeliveryReferenceAliasName] = useAtom(updateDeliveryReferenceAliasNameAtom)
    const [, getDeliveryReferenceAliasName] = useAtom(getDeliveryReferenceAliasNameAtom)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (listLayout.deliveryData) {
            setDeliveryDatas(listLayout.deliveryData)
        }
    }, [listLayout.deliveryData])

    useEffect(() => {
        if (listLayout?.deliveryCustomFields) {
            setDeliveryCustFieData(listLayout?.deliveryCustomFields)
        }
    }, [listLayout?.deliveryCustomFields])

    useEffect(() => {
        if (listMileStone?.qtyData?.data) {
            setOnSiteData(listMileStone?.qtyData?.data)
        }
    }, [listMileStone?.qtyData?.data])

    useEffect(() => {
        let name = getDeliveryReferenceName()
        if (name !== "") {
            setDeliveryNameEdit({ bool: false, data: name })
        }

    }, [listMileStone?.deliveryReferenceAliasName])

    useEffect(() => {
        if (listMileStone?.data) {
            setMileStoneData(listMileStone?.data)
        }
    }, [listMileStone.data])

    useEffect(() => {
        if (listLayout?.deliveryCustomData) {
            setCustomFieldsData(listLayout?.deliveryCustomData)
        }
    }, [listLayout?.deliveryCustomData])

    useEffect(() => {
        Object.entries(acceptedFiles).map((file) => {
            let temp = file[1].name.split(".")[1]
            if (temp === "png" || temp === "jpg" || temp === "pdf") {
                setdeliveryDocs(produce((draft) => { draft.push(file[1]) }))
                setDocNameEdit(produce((draft) => { draft.push(file[1].name) }))
                setDocCount(produce((draft) => { draft.push(docCount.length + 1) }))
            }
            else {
                toast.error("File type must be png or jpg or pdf")
            }
        })
        setEditDelivery(produce((draft) => { draft.isEdit = true }))
    }, [acceptedFiles])

    useEffect(() => {
        if (laydownTable) {
            setFilterLaydown(laydownTable)
        }
    }, [laydownTable])

    useEffect(() => {
        if (listLayout.allInventoryDatas) {
            let tempData = []
            for (let data of listLayout.allInventoryDatas) {
                let temp = {}
                temp.laydown = data.laydown_id
                temp.part_number = data.bom_id
                temp.quantity = data.count
                temp.bom_delivery_laydown_id = data.bom_delivery_laydown_id
                temp.bom_laydown_id = data.bom_laydown_id
                temp.damaged = data?.damaged ? data?.damaged : 0
                temp.remediated_quantity = data?.remediated_quantity ? data?.remediated_quantity : null
                tempData.push(temp)
            }
            setLaydownTable(tempData)
        }
    }, [listLayout.allInventoryDatas])

    const init = async () => {
        try {
            setLoading(true)
            setFetchLayout()
            setFetchMileStone()
            await setFetchDelivery()
            setFetchCustomFields()
            getDeliveryReferenceAliasName()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    function dateTimeEpoch(dateValue, timeValue) {
        var time = new Date(dateValue);
        var originalFormat = `${timeValue}:00`
        // set the correct time
        var timeValues = originalFormat.split(':').map((element) => Number(element));
        time.setHours(timeValues[0], timeValues[1], timeValues[2]);

        // get epoch value
        var epoch = time.valueOf();
        return epoch
    }

    // finding functions

    function formatDate(value) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let date = new Date(value);
        let day = date.getDate();
        let month = months[date.getMonth()];
        let year = date.getFullYear().toString().slice(-4);
        if (day < 10) {
            day = `0${day}`
        }

        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    const handleFilterLaydown = (e) => {
        let quaryFilter = e
        var updateList = [...laydownTable]
        updateList = updateList.filter((item) =>
            listLayout.data.filter(ele => ele.id === parseInt(item.laydown))[0]?.name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        setFilterLaydown(updateList)
    }

    const handleTimeSplit = (data) => {
        let timeData = new Date(data)
        let hours = timeData.getHours()
        let mins = timeData.getMinutes()
        if (mins - 10 < -1) {
            mins = `0${mins}`
        }
        // let sec = timeData.getSeconds()
        let meridian
        if (hours > 12) {
            meridian = "PM"
            hours = hours - 12
        }
        else {
            meridian = "AM"
        }
        return `${hours}:${mins} ${meridian}`
    }

    const handleFindDeliveryId = () => {
        let temp
        temp = listLayout.deliveryData.find(o => o.id === dltDelivery.data)
        return temp?.name
    }

    const handleFindPartName = (data) => {
        let temp
        temp = mileStoneData?.find(o => o.bom_id === data)
        return temp?.part_name
    }

    const handleCheckCountCustomFields = () => {
        let temp = []
        customFieldsData.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        return temp
    }

    const datePickerStyle = ({ className, children }) => {
        return (
            <div>
                <calendarContainer className={`${className}`}>
                    <div style={{ position: "relative" }}>
                        {children}
                    </div>
                </calendarContainer>
            </div>
        )
    }

    const getDeliveryReferenceName = () => {
        let name = ""
        if (listMileStone?.deliveryReferenceAliasName !== null && Object.keys(listMileStone?.deliveryReferenceAliasName).length !== 0 && listMileStone?.deliveryReferenceAliasName?.message !== 'Field alias not found') {
            name = listMileStone?.deliveryReferenceAliasName?.field_alias_name
        }
        return name
    }

    //button functions

    const handleClickOutside = (e) => {
        if (searchRef.current && !searchRef.current.contains(e.target)) {
            setLaydownSearch({ laydown: false, laydownId: null, part: false, partId: null })
            setFilterLaydown(laydownTable)
            setDeliverySearch({ boolean: false, data: null, dateBoolean: false, dateData: null })
        }
        if (deliveryNameRef.current && !deliveryNameRef.current.contains(e.target)) {
            setDeliveryNameEdit(produce((draft) => { draft.bool = false }))
        }
    }

    const handleAddDeliveryClick = () => {
        setDeliveryDate(new Date())
        setDeliveryTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
        setDeliveryStep(2)
        setDocCount([1])
        setLaydownTable([])
        setdeliveryDocs([])
        setDeliveryCustFieData(customFieldsData)
    }

    const openImageViewer = async (data) => {
        try {
            setImageViewerLoad(true)
            if (!editMode) {
                let temp
                if (data.upload_id) {
                    let res = await axios.get(`/file_upload/download?id=${data.upload_id}`)
                    temp = res.data
                }
                if (data.name.split(".")[1] === "png" || data.name.split(".")[1] === "jpg") {
                    window.open(`/imageViewer?url=${temp}`)
                }
                if (data.name.split(".")[1] === "pdf") {
                    window.open(`/pdfViewer?url=${temp}`)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setImageViewerLoad(false)
        }
    }

    const handleEditDeliveyOnClick = async (id) => {
        handleDeliveryLaydownPreFill(id)
        let data = listLayout.deliveryData.find(o => o.id === id)
        setDeliveryStep(1)
        setDeliveryId(id)
        setDeliveryDate(new Date(data?.date_time))
        setDeliveryTime(`${new Date(data?.date_time).getHours()}:${new Date(data?.date_time).getMinutes()}`)
        setdeliveryDocs(data.documents)
        let tempDocNames = []
        for (let key of data.documents) {
            tempDocNames.push(key?.name)
        }
        setDocNameEdit(tempDocNames)
        setEditDelivery(produce((draft) => { draft.boolean = true; draft.data = data; draft.isEdit = false }))
        let temp = []
        for (let i = 1; i <= data.documents.length;) {
            temp.push(i + 1)
            i++
        }
        setDocCount(temp)
    }

    const handleAddCustomFields = () => {
        setCustomFieldsData(produce((draft) => {
            draft.push({
                project_id: parseInt(localStorage.getItem("project_id")),
                type: 0,
                values: {},
                name: `custom field`
            })
        }))
    }

    const handleAddDoc = () => {
        let temp = []
        for (let i = 0; i <= docCount.length;) {
            temp.push(i + 2)
            i++
        }
        setDocCount(temp)
    }

    const handleAddEntry = () => {
        setAddEntry(produce((draft) => { draft.boolean = true }))
        setLaydownTable(produce((draft) => { draft.push({ laydown: prevSelectedLaydown, part_number: null, quantity: "", addNew: true }) }))
    }

    const handleCloseImageViewer = () => {
        setImages([])
        setDocsOpen({ boolean: false, data: null })
    }

    const handleDeliveryDataDownload = async () => {
        try {
            await setDownloadDeliveryData({ project_name: project_name, formattedDate: formattedDate, customFieldsData: customFieldsData })
        }
        catch (err) {
            toast.error("Unable to Download")
        }
    }

    function closeDeliveryModal() {
        setEditDelivery(produce((draft) => { draft.boolean = false; draft.data = {}; draft.isEdit = false }))
        setCustomFieldsData(listLayout?.deliveryCustomData)
        setDeliveryDatas(listLayout.deliveryData)
        if (deliveryStep === 0) {
            setDeliveryModal(false)
        }
        if (deliveryStep === 2) {
            setExitModal(true)
            return
        }
        if (deliveryStep === 1) {
            if (editMode) {
                setExitModal(true)
            }
            else {
                setDeliveryStep(0)
                reset()
                setdeliveryDocs([])
                setEditDelivery(produce((draft) => { draft.boolean = false; draft.data = {}; draft.isEdit = false }))
                setDocCount([])
                setDocRenameBox(null)
                setDocNameEdit([])
                setDisableSubmit(false)
                setDocNameChange()
                setEditMode(false)
                setAddEntry({ boolean: false, data: [] })
            }
        }
        else {
            setEditMode(false)
            setDeliveryStep(0)
            setdeliveryDocs([])
            setLaydownTable([])
            setDocCount([])
            setDocNameEdit([])
            setDisableSubmit(false)
            setDocNameChange()
            reset()
            setDeliveryId()
            setDeliveryTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
            setDeliveryDate(new Date())
        }
    }

    const handleEditModeClick = () => {
        setEditMode(!editMode)
        if (editMode) {
            let data = listLayout.deliveryData.find(o => o.id === editDelivery.data.id)
            setEditDelivery(produce((draft) => { draft.boolean = false; draft.data = data; draft.isEdit = false }))
            setdeliveryDocs(data.documents)
            let temp = []
            for (let i = 1; i <= data.documents.length;) {
                temp.push(i + 1)
                i++
            }
            setDocCount(temp)
        }
    }

    const handleDeleteCustomFields = () => {
        setCustomFieldsData(produce((draft) => {
            draft[customDltWarning.index]["delete"] = true
        }))
        setCustomDltWarning({ bool: false, index: null })
    }

    const handleRemoveCustomFields = (index) => {
        console.log(customFieldsData[index]?.id)
        if (customFieldsData[index]?.id) {
            setCustomDltWarning({ bool: true, index: index })
        }
        else {
            setCustomFieldsData(produce((draft) => {
                draft.splice(index, 1)
            }))
        }
    }

    //onchange functions

    const handleDeliveryLaydownPreFill = async (id) => {
        try {
            if (id) {
                setLaydownLoader(true)
                setDeliveryId(parseInt(id))
                await setFetchAllInventory({ deliveryId: parseInt(id) })
                await setDeliveryLaydownIds({ deliveryId: parseInt(id) })
                await setFetchCustomFields({ delivery_id: parseInt(id) })
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something Went Wrong")
        }
        finally {
            setLaydownLoader(false)
        }
    }

    const handleKeyDown = (e) => {
        if (e?.key === "Enter") {
            handleSubmitDeliveryReferenceAliasName();
            setDeliveryNameEdit(produce((draft) => { draft.bool = false }))
        }
    };

    const handleBlur = () => {
        handleSubmitDeliveryReferenceAliasName();
        setDeliveryNameEdit(produce((draft) => { draft.bool = false }))
    };

    const handleUpdateChangeLaydown = (e, index) => {
        setLaydownTable(produce((draft) => {
            draft[index].laydown = parseInt(e.id)
            draft[index]["isEdit"] = true
        }
        ))
        setPrevSelectedLaydown(parseInt(e.id))
    }

    const handleCreateChangeLaydown = (e, index) => {
        setLaydownTable(produce((draft) => { draft[index].laydown = parseInt(e.id) }))
        setPrevSelectedLaydown(parseInt(e.id))
    }

    const handleFilterDateRef = (e) => {
        setDeliverySearch(produce((draft) => { draft.dateData = e }))
        let quaryFilter = formatDate(e)
        var updateList = [...listLayout.deliveryData]
        updateList = updateList.filter((item) =>
            formatDate(item.date_time).indexOf(quaryFilter) !== -1
        )
        if (deliverySearch.data) {
            updateList = updateList.filter((item) =>
                item.name.toLowerCase().indexOf(deliverySearch.data.toLowerCase()) !== -1
            )
        }
        setDeliveryDatas(updateList)
    }

    const handleFilterPartName = (e) => {
        let quaryFilter = e
        var updateList = [...laydownTable]
        updateList = updateList.filter((item) =>
            handleFindPartName(item.part_number).toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        setFilterLaydown(updateList)
    }

    const handleFilterDeliveryRef = (e) => {
        setDeliverySearch(produce((draft) => { draft.data = e }))
        let quaryFilter = e
        var updateList = [...listLayout.deliveryData]
        updateList = updateList.filter((item) =>
            item.name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        if (deliverySearch.dateData) {
            updateList = updateList.filter((item) =>
                formatDate(item.date_time).toLowerCase().indexOf(formatDate(deliverySearch.dateData).toLowerCase()) !== -1
            )
        }
        setDeliveryDatas(updateList)
    }

    const handleDocNameChange = (index) => {
        setDocRenameBox(null)
        let temp = [...docNameEdit]
        if (docNameChange) {
            temp[index] = docNameChange
        }
        else {
            temp[index] = docNameEdit[index]
        }
        setDocNameEdit(temp)
        setDisableSubmit(false)
        setEditDelivery(produce((draft) => { draft.isEdit = true }))
        setDocNameChange()
    }

    const handleCreateChangePartNumber = (e, index) => {
        setLaydownTable(produce((draft) => { draft[index].part_number = parseInt(e.bom_id) }))
    }

    const handleRemoveDoc = (index) => {
        let tempDate = deliveryDocs.filter((_, subIndex) => subIndex !== index)
        setdeliveryDocs(tempDate)
        let tempName = docNameEdit.filter((_, subIndex) => subIndex !== index)
        setDocNameEdit(tempName)
        let tempCount = []
        for (let key = 0; key < tempDate.length; key++) {
            tempCount.push(tempCount.length + 1)
        }
        setDocCount(tempCount)
        setDisableSubmit(false)
        setEditDelivery(produce((draft) => { draft.isEdit = true }))
    }

    const handleCreateChangePartDescription = (e, index) => {
        setLaydownTable(produce((draft) => { draft[index].part_number = parseInt(e.bom_id) }))
    }

    // onsubmit functions

    const handleSubmitDeliveryReferenceAliasName = async () => {
        setLoader(true)
        await updateDeliveryReferenceAliasName(deliveryNameEdit?.data)
        setLoader(false)
    }

    const handleEditDeliveryReferenc = (e) => {
        setDeliveryNameEdit(produce((draft) => { draft.data = e.target.value }))
    }

    const handleUpdateChangePartNumber = (e, index) => {
        setLaydownTable(produce((draft) => {
            draft[index].part_number = parseInt(e.bom_id)
            draft[index]["isEdit"] = true
        }
        ))
    }

    const handleMultiInputSubmit = () => {
        setCustomFieldsData(produce((draft) => { draft[dropdownModal?.index].values = dropdownModal.values }))
        setDropdownModal({ boolean: false, index: null, values: { values: [] } })
    }

    const handleEditInventory = async () => {
        try {
            let laydownArr = []
            for (let key of laydownTable) {
                if (key.isEdit && key.part_number && key.quantity) {
                    laydownArr.push(key.laydown)
                }
            }
            let tempData = []
            laydownArr = [...new Set(laydownArr)]
            for (let arr of laydownArr) {
                for (let key of laydownTable) {
                    if (key.isEdit) {
                        let temp = {
                            bom_id: key.part_number,
                            count: key.quantity,
                            purpose_code: 0,
                            comment: "",
                            damaged: key?.damaged ? key?.damaged : 0,
                            time: Math.round(new Date().getTime() / 1000)
                        }
                        if (key.bom_delivery_laydown_id) {
                            temp.bom_delivery_laydown_id = key.bom_delivery_laydown_id
                            temp.bom_laydown_id = key.bom_laydown_id
                        }
                        if (key.delete && key.bom_delivery_laydown_id) {
                            temp.delete = true
                        }
                        if (key.laydown === arr) {
                            tempData.push(temp)
                        }
                    }
                }
                await setCreateBomDelivery(
                    {
                        deliveryId: parseInt(deliveryId),
                        laydownId: arr,
                        deliveryLaydownId: listLayout.deliveryLaydownIds.find(o => o.laydown_id === arr)?.id,
                        data: tempData
                    }
                )
                tempData = []
            }
            await setFetchAllInventory({ deliveryId: parseInt(deliveryId) })
            await setDeliveryLaydownIds({ deliveryId: parseInt(deliveryId) })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleUpdateDelivery = async (data) => {
        try {
            if (editMode) {
                setDeliveryLoader(true)
                let docTemp = []
                if (deliveryDocs.length > 0 && editDelivery.isEdit) {
                    for (let key in deliveryDocs) {
                        if (deliveryDocs[key]?.upload_id) {
                            docTemp.push({
                                upload_id: deliveryDocs[key]?.upload_id,
                                name: deliveryDocs[key]?.name
                            })
                        }
                        else {
                            let docFormData = new FormData();
                            let docFile = new File([deliveryDocs[key]], `${docNameEdit[key]}_${uuid()}.${docNameEdit[key].split('.').at(-1)}`);
                            docFormData.append("file", docFile)
                            let docRes = await axios.post(`/file_upload/upload/image`, docFormData, { headers: { "Content-Type": "multipart/form-data" } })
                            docTemp.push(
                                {
                                    upload_id: docRes.data,
                                    name: docNameEdit[key]
                                }
                            )
                        }
                    }
                    setdeliveryDocs(docTemp)
                }
                let docCountTemp = []
                for (let key in deliveryDocs) {
                    docCountTemp.push(parseInt(key))
                }
                setDocCount(docCountTemp)
                await handleEditInventory()
                if (editDelivery.isEdit) {
                    await setCreateDelivery({
                        deliveryData: {
                            id: editDelivery.data.id,
                            name: data.delivery_reference,
                            comments: data.comments,
                            date_time: dateTimeEpoch(deliveryDate, deliveryTime) ? dateTimeEpoch(deliveryDate, deliveryTime) : new Date().getTime(),
                            document: docTemp
                        }, inventoryData: []
                    })
                }
                toast.success("Edited Successfully")
            }
            let customDataArr = []
            deliveryCustFieData.map((data) => {
                let temp = {}
                temp.delivery_id = editDelivery.data.id
                if (data[1]?.custom_input_delivery_id) {
                    temp.custom_response_delivery_id = data[1].id
                }
                temp.custom_input_delivery_id = data[0].id
                if (data[1]?.responseData) {
                    temp.response = data[1]?.responseData
                }
                else {
                    temp.response = data[1]?.response
                }
                temp.delete = false
                customDataArr.push(temp)
            })
            await setCreateCustomFields({ delivery_id: editDelivery.data.id, data: customDataArr })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setDeliveryLoader(false)
            setEditMode(false)
            setPrevSelectedLaydown(null)
        }
    }

    const handleCreateDelivery = async (formData) => {
        try {
            if (formData.delivery_reference) {
                let duplicateBom = false
                setDeliveryLoader(true)
                let docTemp = []
                if (deliveryDocs.length > 0) {
                    for (let key in deliveryDocs) {
                        if (deliveryDocs[key]?.upload_id) {
                            docTemp.push({
                                upload_id: deliveryDocs[key]?.upload_id,
                                name: deliveryDocs[key]?.name
                            })
                        }
                        else {
                            let docFormData = new FormData();
                            let docFile = new File([deliveryDocs[key]], `${docNameEdit[key]}_${uuid()}.${docNameEdit[key].split('.').at(-1)}`);
                            docFormData.append("file", docFile)
                            let docRes = await axios.post(`/file_upload/upload/image`, docFormData, { headers: { "Content-Type": "multipart/form-data" } })
                            docTemp.push(
                                {
                                    upload_id: docRes.data,
                                    name: docNameEdit[key]
                                }
                            )
                        }

                    }
                }
                let deliveryData = {
                    name: formData.delivery_reference,
                    comments: formData.comments,
                    date_time: dateTimeEpoch(deliveryDate, deliveryTime) ? dateTimeEpoch(deliveryDate, deliveryTime) : new Date().getTime(),
                    document: docTemp
                }
                let tempData = []
                let laydownIds = []
                for (let data of laydownTable) {
                    if (data.addNew && !data.delete) {
                        if (data.laydown && data.part_number && data.quantity) {
                            laydownIds.push(data.laydown)
                            let prevValue = laydownTable.filter(o => o.laydown === data.laydown && o.part_number === data.part_number)
                            console.log(prevValue)
                            let temp = {}
                            if (prevValue.length === 1) {
                                temp = {
                                    laydown: data.laydown,
                                    bom_id: data.part_number,
                                    count: data.quantity,
                                    damaged: data?.damaged ? data?.damaged : 0,
                                    time: Math.round(new Date().getTime() / 1000)
                                }
                            }
                            if (prevValue?.length > 1) {
                                duplicateBom = true
                            }
                            tempData.push(temp)
                        }
                    }
                }
                laydownIds = [...new Set(laydownIds)]
                let ttlInventryDatas = []
                for (let key of laydownIds) {
                    let tempDataLy = []
                    for (let data of tempData) {
                        if (data.laydown === key) {
                            let temp = {
                                laydown: key,
                                bom_id: data.bom_id,
                                count: data.count,
                                damaged: data?.damaged ? data?.damaged : 0,
                                time: Math.round(new Date().getTime() / 1000)
                            }
                            tempDataLy.push(temp)
                        }
                    }
                    ttlInventryDatas.push(tempDataLy)
                }

                if (duplicateBom) {
                    toast.error("Cannot make multiple entries for the same part number stored in same laydown")
                }
                else {
                    await setCreateDelivery({ deliveryData: deliveryData, inventoryData: ttlInventryDatas, customFieldsData: deliveryCustFieData })
                    setAddEntry(produce((draft) => { draft.boolean = false }))
                    setLaydownTable([])
                    setDeliveryDate(new Date())
                    setDeliveryTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
                    toast.success("Created Successfully")
                    setDeliveryStep(0)
                    reset()
                }
            }
            else {
                toast.error("Please enter delivery reference")
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setDeliveryLoader(false)
            setPrevSelectedLaydown(null)
        }
    }

    const handleSetupDeliveryForm = async (data) => {
        setDeliveryLoader(true)
        handleSubmitDeliveryReferenceAliasName()
        let tempArr = []
        customFieldsData?.map((data) => {
            let temp = {}
            temp.name = data?.name
            temp.type = data?.type
            temp.values = data?.values
            if (data?.id) {
                temp.id = data?.id
            }
            tempArr.push(temp)
        })
        try {
            await setCreateCustomFields({ data: customFieldsData })
            toast.success("Successfully Created")
            setDeliveryLoader(false)
            setDeliveryStep(0)
            setEditMode(false)
        }
        catch (err) {
            toast.err("Something went wrong")
            console.log(err)
        }
        finally {
            setDeliveryLoader(false)
        }
    }

    const handleDeleteDelivery = async (id) => {
        try {
            setDeleteDeliveryLoader(true)
            await setDeleteDelivery({ delivery_id: dltDelivery.data })
            toast.success("Deleted Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setDeleteDeliveryLoader(false)
            setDltDelivery({ boolean: false, data: null })
        }
    }

    const handleDeleteDoc = (count, index) => {
        if (editMode) {
            let temp = docCount.filter(item => item !== count)
            // if (deliveryDocs.length > 0) {
            //     let tempDocs = deliveryDocs.filter((_, subIndex) => subIndex !== index)
            //     console.log(tempDocs)
            //     // deliveryDocs.splice(index, 1)
            //     // docNameEdit.splice(index, 1)
            // }
            setDocCount(temp)
        }
    }

    const handleSavePdf = async (data) => {
        if (data?.upload_id) {
            let res = await axios.get(`/file_upload/download?id=${data?.upload_id}`)
            saveAs(res.data, data?.name);
        }
    }

    function handleCheckCreateBomTableEnable() {
        let result = false
        if (laydownTable.length) {
            laydownTable.map((bomData) => {
                if (!bomData?.delete) {
                    if (bomData?.laydown && bomData?.part_number && bomData?.quantity > 0) {
                        result = false
                    }
                    else {
                        result = true
                    }
                }
            })
        }
        if (disableSubmit) {
            return disableSubmit
        }
        else {
            return result
        }
    }

    function handleCheckUpdateBomTableEnable() {
        let result = false
        if (laydownTable.length) {
            laydownTable.map((bomData) => {
                if (!bomData?.delete) {
                    if (bomData?.laydown && bomData?.part_number && bomData?.quantity > 0) {
                        result = false
                    }
                    else {
                        result = true
                    }
                }

            })
        }
        if (disableSubmit || !editMode) {
            return disableSubmit || !editMode
        }
        else {
            return result
        }
    }

    // modal styles
    const customDeliveryModal = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // width: '1400px',
            // maxHeight: "80%",
            padding: "0px 30px 30px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    return (
        <div>
            <Modal
                isOpen={deliveryModal}
                onAfterClose={closeDeliveryModal}
                style={customDeliveryModal}
                contentLabel='Delivery Modal'
            >
                <form ref={tableRef} onSubmit={handleSubmit(deliveryStep === 1 ? handleUpdateDelivery : deliveryStep === 2 ? handleCreateDelivery : deliveryStep === 3 ? handleSetupDeliveryForm : null)}>
                    <div className='flex justify-between items-center font-bold text-xl py-3 bg-white sticky top-0 border-none'>
                        <div className='flex space-x-3 items-center'>
                            {deliveryStep !== 0 && <BiArrowBack className='cursor-pointer mt-2' onClick={closeDeliveryModal} />}
                            <p className='mt-2'>
                                {deliveryStep === 0 ?
                                    "Deliveries List"
                                    :
                                    deliveryStep === 1 ?
                                        "View / Edit Delivery"
                                        :
                                        deliveryStep === 3 ?
                                            "Setup Delivery Form"
                                            :
                                            "New Delivery"
                                }
                            </p>
                        </div>
                        <div className='flex items-center'>
                            {deliveryStep === 0 ?
                                <div className='mx-3 flex space-x-4 mt-2 items-center'>
                                    <p className='cursor-pointer text-xs font-semibold mt-1 underline underline-offset-2 hover:text-red-550' onClick={() => { setDeliveryStep(3); setEditMode(true); setDocCount([1]) }}>Setup Delivery Form</p>
                                    <div>
                                        <PrimaryButton text={"Add new delivery"} onClick={handleAddDeliveryClick} />
                                    </div>
                                    <div className='cursor-pointer flex space-x-2 items-center text-sm font-normal' onClick={handleDeliveryDataDownload}>
                                        <CgSoftwareDownload />
                                        <p>Download</p>
                                    </div>
                                </div>
                                :
                                <div className='mx-3 flex space-x-4'>
                                    {deliveryStep === 1 && <p className='btn bg-red-550 text-white border-none rounded-full px-6 py-2 btn border-none' onClick={handleEditModeClick}>{editMode ? "Cancel" : "Edit"}</p>}
                                    {deliveryStep === 1 && <input type="submit" disabled={handleCheckUpdateBomTableEnable()} value={"Save"} className='bg-red-550 text-white px-4 py-2 rounded-3xl cursor-pointer btn border-none' />}
                                    {deliveryStep === 2 && <input type="submit" disabled={handleCheckCreateBomTableEnable()} value={"Save"} className='bg-red-550 text-white px-4 py-2 rounded-3xl cursor-pointer btn border-none' />}
                                    {deliveryStep === 3 && <input type='submit' value={"Save"} className='bg-red-550 text-white px-4 py-2 rounded-full cursor-pointer btn border-none' />}
                                </div>
                            }
                            {deliveryStep === 0 && <VscClose onClick={closeDeliveryModal} className="cursor-pointer active:bg-red-550 active:text-white mt-3 hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />}
                        </div>
                    </div>
                    <hr />
                    {deliveryStep === 0 ?
                        <div className='w-[90vw] max-w-[1200px] h-[70vh]'>
                            {loading ?
                                <div className='absolute opacity-100 left-[45%] top-[40%] z-50'>
                                    <p className='loader ml-3 mb-1'></p>
                                    <p className='font-bold text-xl'>Fetching...</p>
                                </div>
                                :
                                <div className='overflow-auto h-full'>
                                    <div>
                                        <table className='table relative table-compact w-full overflow-auto z-0 h-full'>
                                            <thead ref={deliverySearch.data || deliverySearch.dateData ? null : searchRef}>
                                                <tr className='sticky top-0 z-20'>
                                                    <th className='normal-case w-[180px]'>
                                                        {deliverySearch.dateBoolean ?
                                                            <div className='flex space-x-5'>
                                                                <div>
                                                                    <DatePicker popperPlacement='bottom-end' selected={deliverySearch.dateData} onChange={e => handleFilterDateRef(e)} calendarContainer={datePickerStyle} placeholderText='Choose Date' className='text-gray-500 w-[100%] pl-2 py-1 rounded-xl' dateFormat={"dd-MMM-YYYY"} />
                                                                </div>
                                                                <VscClose className='text-3xl cursor-pointer' onClick={() => {
                                                                    setDeliverySearch(produce((draft) => { draft.dateBoolean = false; draft.dateData = null }))
                                                                    if (!deliverySearch.boolean && deliverySearch.dateBoolean) {
                                                                        setDeliveryDatas(listLayout.deliveryData)
                                                                    }
                                                                }} />
                                                            </div>
                                                            :
                                                            <div className='flex space-x-5'>
                                                                <p>Date</p>
                                                                <IoSearchSharp className='mt-1 cursor-pointer' onClick={() => setDeliverySearch(produce((draft) => { draft.dateBoolean = true }))} />
                                                            </div>
                                                        }
                                                    </th>
                                                    <th className='normal-case w-[150px]'>Time</th>
                                                    <th className='normal-case'>
                                                        {deliverySearch.boolean ?
                                                            <div className='flex space-x-5'>
                                                                <input type='search' className='text-gray-500 border px-2 py-1 w-[60%] font-normal rounded-lg' placeholder='Search Delivery Reference' onChange={e => handleFilterDeliveryRef(e.target.value)} />
                                                                <VscClose className='mt-2 cursor-pointer' onClick={() => {
                                                                    setDeliverySearch(produce((draft) => { draft.boolean = false; draft.data = null }))
                                                                    if (deliverySearch.boolean && !deliverySearch.dateBoolean) {
                                                                        setDeliveryDatas(listLayout.deliveryData)
                                                                    }
                                                                }} />
                                                            </div>
                                                            :
                                                            <div className='flex space-x-5'>
                                                                <p>{getDeliveryReferenceName() === "" ? "Delivery Reference" : getDeliveryReferenceName()}</p>
                                                                <IoSearchSharp className='mt-1 cursor-pointer' onClick={() => setDeliverySearch(produce((draft) => { draft.boolean = true }))} />
                                                            </div>
                                                        }
                                                    </th>
                                                    <th className='normal-case w-[30%]'>Comments</th>
                                                    <th className='normal-case text-center w-28 pr-4'></th>
                                                </tr>
                                            </thead>
                                            {laydownLoader ?
                                                <div className='absolute left-[45%] top-[45%]'>
                                                    <p className='loader ml-7 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                    <p className='font-bold text-xl'>Fetching...</p>
                                                </div>
                                                :
                                                null
                                            }
                                            {deliveryDatas.length === 0 ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={5} className='py-16'>
                                                            <div className='m-auto'>
                                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                    {deliveryLoader ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <div style={{ marginTop: "15%", marginLeft: "48%" }}>
                                                                    <p className='loader ml-3 mb-1'></p>
                                                                    <p className='font-bold text-xl'>Fetching...</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        deliveryDatas.map((i) => {
                                                            return (
                                                                <tr className={`${laydownLoader ? "opacity-20" : null} hover`}>
                                                                    <td className='py-3 cursor-pointer' onClick={() => handleEditDeliveyOnClick(i?.id)}><DateFormat date={i?.date_time} /></td>
                                                                    <td className='py-3 cursor-pointer' onClick={() => handleEditDeliveyOnClick(i?.id)}>{handleTimeSplit(i?.date_time)}</td>
                                                                    <td className='py-3 cursor-pointer' onClick={() => handleEditDeliveyOnClick(i?.id)}>{i?.name}</td>
                                                                    <td className='cursor-pointer' onClick={() => handleEditDeliveyOnClick(i?.id)}>
                                                                        <textarea rows={2} cols={50} className='bg-transparent cursor-pointer focus:outline-none' value={i?.comments ? i?.comments : "-"} />
                                                                    </td>
                                                                    <td className='py-3 pl-3'>
                                                                        <MdDelete className='text-xl cursor-pointer' onClick={() => setDltDelivery({ boolean: true, data: i?.id })} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        deliveryStep === 1 ?
                            <div>
                                {laydownLoader || deliveryLoader ?
                                    <div className='absolute opacity-100 left-[45%] top-[40%] z-50'>
                                        <p className='loader ml-3 mb-1'></p>
                                        <p className='font-bold text-xl'>{laydownLoader && "Fetching..."}{deliveryLoader && "Updating..."}</p>
                                    </div>
                                    :
                                    null
                                }
                                <form className={`${laydownLoader || deliveryLoader ? "opacity-50 relative" : "opacity-100"} w-[90vw] max-w-[1200px]`} onSubmit={handleSubmit(handleUpdateDelivery)}>
                                    <div className='w-full overflow-auto h-[70vh]'>
                                        <div className='flex items-center space-x-5'>
                                            <p className='mt-7 font-semibold w-[35%]'>Date & Time :</p>
                                            <div className='flex space-x-2 mt-4'>
                                                <DatePicker disabled={!editMode} showIcon toggleCalendarOnIconClick popperPlacement='bottom' icon={<SlCalender className='cursor-pointer mt-0.5' />} dateFormat="dd-MMM-yyyy" onChange={e => { setDeliveryDate(e); setEditDelivery(produce((draft) => { draft.isEdit = true })) }} selected={deliveryDate} className="py-1 border-gray-400 cursor-pointer border w-[140px]" />
                                                <TimePicker disabled={!editMode} disableClock onChange={e => { setDeliveryTime(e); setEditDelivery(produce((draft) => { draft.isEdit = true })) }} value={deliveryTime} className={"w-[120px]"} />
                                            </div>
                                        </div>
                                        <div className='flex items-center space-x-5'>
                                            <p className='mt-7 font-semibold w-[35%]'>{getDeliveryReferenceName() === "" ? "Delivery Reference :" : getDeliveryReferenceName()}</p>
                                            <textarea rows="2" cols="50" disabled={!editMode} defaultValue={editDelivery.boolean ? editDelivery.data.name : ""} className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" required {...register("delivery_reference")} onChange={() => setEditDelivery(produce((draft) => { draft.isEdit = true }))} />
                                        </div>
                                        <div className='flex items-center space-x-5'>
                                            <p className='mt-7 font-semibold w-[35%]'>Comments :</p>
                                            <textarea rows="2" cols="50" placeholder='Enter Comments' disabled={!editMode} defaultValue={editDelivery.boolean ? editDelivery.data.comments : ""} className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" {...register("comments")} onChange={() => setEditDelivery(produce((draft) => { draft.isEdit = true }))} />
                                        </div>
                                        <div className='flex items-center space-x-5 my-4'>
                                            <p className='mt-7 font-semibold w-[35%]'>Documents :</p>
                                            <div className='w-[50%]'>
                                                {docCount.map((count, index) => {
                                                    return (
                                                        <div>
                                                            {!deliveryDocs[index] ?
                                                                <div className={`${!editMode ? "bg-gray-50" : ""} border-2 border-dashed border-gray rounded-2xl cursor-pointer mt-4 p-2 w-full h-[140px]`}>
                                                                    <div className='flex justify-end'>
                                                                        {docCount.length > 1 ?
                                                                            <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleDeleteDoc(count, index)} />
                                                                            :
                                                                            <p className="m-4" />
                                                                        }
                                                                    </div>
                                                                    {editMode &&
                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="font-normal text-center mt-[-15px]">
                                                                                <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                                <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                                <p className="text-xs mt-4">
                                                                                    Supported files : JPG, PNG, PDF
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="my-2">
                                                                    <div className="flex justify-between w-full">
                                                                        <p className={`text-sm font-semibold ${imageViewerLoad ? "cursor-wait" : "cursor-pointer"} ${!editMode ? "cursor-pointer underline underline-offset-2 text-blue-400 my-0.5" : null} `}
                                                                            onClick={() => {
                                                                                openImageViewer(deliveryDocs[index])
                                                                            }}
                                                                        >
                                                                            {docNameEdit[index]}
                                                                        </p>
                                                                        {editMode &&
                                                                            <div className='flex space-x-4 pr-4'>
                                                                                <RiEdit2Fill className="ml-4 cursor-pointer" onClick={() => { setDocRenameBox(index); setDisableSubmit(true) }} style={{ width: "20px", height: "20px" }} />
                                                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => handleRemoveDoc(index)} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {docRenameBox === index ?
                                                                        <div className="flex">
                                                                            <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={docNameEdit[index]?.split(".")[0]} onChange={e => setDocNameChange(e.target.value + "." + docNameEdit[index]?.split(".")[1])} />
                                                                            <button className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleDocNameChange(index)}>Save</button>
                                                                            <button className="text-sm mt-7 cursor-pointer ml-5" onClick={() => { setDocRenameBox(null); setDisableSubmit(false) }}>Cancel</button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                {docCount.length === 0 && <p className='text-gray-500 mt-7 font-semibold'>No Document Uploaded</p>}
                                                <p className='cursor-pointer text-right my-2' style={{ visibility: editMode ? "" : "hidden" }} onClick={handleAddDoc}>+ Add More</p>
                                            </div>
                                        </div>
                                        {deliveryCustFieData.map((customData, customIndex) => {
                                            return (
                                                <div className='flex items-center space-x-5'>
                                                    <p className='font-semibold w-[35%] mt-7'>{customData[0]?.name} :</p>
                                                    {customData[0]?.type === 0 ?
                                                        <div className='w-[25%]'>
                                                            <input type='number'
                                                                step="1"
                                                                min="0"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === '.' || e.key === 'e' || e.key === '-') {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                defaultValue={customData[1]?.response} placeholder={`Enter ${customData[0]?.name}`} disabled={!editMode} className='appearance-none block text-gray-700 w-full border border-gray-300 rounded-xl py-3 px-4 mt-4' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex][1]["responseData"] = parseInt(e.target.value) }))} />
                                                            {editMode && <p className='text-red-500 text-xs'>Integer Only</p>}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {customData[0]?.type === 1 ?
                                                        <div className='w-[25%]'>
                                                            <input type='number' defaultValue={customData[1]?.response} placeholder={`Enter ${customData[0]?.name}`} disabled={!editMode} className='appearance-none block text-gray-700 w-full border border-gray-300 rounded-xl py-3 px-4 mt-4' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex][1]["responseData"] = parseInt(e.target.value) }))} />
                                                            {editMode && <p className='text-red-500 text-xs'>Float Only</p>}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {customData[0]?.type === 2 ?
                                                        <div className='w-[25%]'>
                                                            <input type='text' defaultValue={customData[1]?.response} placeholder={`Enter ${customData[0]?.name}`} disabled={!editMode} className='appearance-none block text-gray-700 w-full border border-gray-300 rounded-xl py-3 px-4 mt-4' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex][1]["responseData"] = e.target.value }))} />
                                                            {editMode && <p className='text-red-500 text-xs'>Free Text Only</p>}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {customData[0]?.type === 3 ?
                                                        editMode ?
                                                            <select className='border rounded-xl px-4 py-4 mt-4 mr-7 w-[25%]' disabled={!editMode} onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex][1]["responseData"] = e.target.value }))}>
                                                                <option selected hidden className='text-gray-400'>Select {customData[0]?.name}</option>
                                                                {customData[0]?.values?.values && customData[0]?.values?.values.map((dropData) => {
                                                                    return (
                                                                        <option selected={dropData === customData[1]?.response} value={dropData}>{dropData}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            :
                                                            <p className='border bg-gray-50 rounded-xl p-4 mt-4 mr-7 w-[25%]'>
                                                                {customData[1]?.response}
                                                            </p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            )
                                        })}
                                        <table className='table table-compact z-0 w-full mt-5 mt-5'>
                                            <thead ref={laydownSearch.laydownId || laydownSearch.partId ? null : searchRef}>
                                                <tr>
                                                    <th className='normal-case w-[20%] px-4'>
                                                        {!laydownSearch.laydown ?
                                                            laydownSearch.laydownId ?
                                                                <div className='flex justify-between space-x-4'>
                                                                    <p style={{ textTransform: "capitalize" }}>{listLayout.data.filter(ele => ele.id === laydownSearch.laydownId)[0]?.name}</p>
                                                                    <VscClose className='mt-1 cursor-pointer' onClick={() => { setLaydownSearch({ laydown: false, laydownId: null, part: laydownSearch.part, partId: laydownSearch.partId }); setFilterLaydown(laydownTable) }} />
                                                                </div>
                                                                :
                                                                <div className='flex justify-between space-x-4'>
                                                                    Laydown
                                                                    {laydownTable.length !== 0 && <IoSearchSharp className='mt-1 cursor-pointer' onClick={() => setLaydownSearch({ laydown: true, laydownId: null, part: false, partId: laydownSearch.partId })} />}
                                                                </div>
                                                            :
                                                            <div className='relative w-full'>
                                                                <input type='search' className='border rounded-lg px-2 py-1 font-normal text-black' placeholder='Search Laydown' onChange={e => handleFilterLaydown(e.target.value)} />
                                                                <div className='border px-2 py-1 rounded-xl bg-white absolute left-0 w-[80%]'>
                                                                    {filterLaydown.filter((obj, index) => {
                                                                        return index === filterLaydown.findIndex(o => obj.laydown === o.laydown);
                                                                    }).map((i) => {
                                                                        return (
                                                                            <p className='cursor-pointer m-1 p-1.5 hover:bg-gray-100 text-sm font-normal text-black' style={{ textTransform: "capitalize" }} onClick={() => { setLaydownSearch({ laydown: false, laydownId: parseInt(i.laydown), part: false, partId: laydownSearch.partId }) }}>
                                                                                {listLayout.data.filter(ele => ele.id === parseInt(i.laydown))[0]?.name}
                                                                            </p>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </th>
                                                    <th className='normal-case w-[25%] px-4'>
                                                        Part Description
                                                    </th>
                                                    <th className='normal-case w-[20%] px-4'>
                                                        {!laydownSearch.part ?
                                                            laydownSearch.partId ?
                                                                <div className='flex justify-between space-x-4'>
                                                                    <p>{handleFindPartName(laydownSearch.partId)}</p>
                                                                    <VscClose className='mt-1 cursor-pointer' onClick={() => { setLaydownSearch({ laydown: laydownSearch.laydown, laydownId: laydownSearch.laydownId, part: false, partId: null }); setFilterLaydown(laydownTable) }} />
                                                                </div>
                                                                :
                                                                <div className='flex justify-between space-x-4'>
                                                                    Part Number
                                                                    {laydownTable.length !== 0 && <IoSearchSharp className='mt-1 cursor-pointer' onClick={() => setLaydownSearch({ laydown: false, laydownId: laydownSearch.laydownId, part: true, partId: null })} />}
                                                                </div>
                                                            :
                                                            <div className='relative w-full'>
                                                                <input type='search' className='border rounded-lg px-2 py-1 font-normal text-black' placeholder='Search Part Name' onChange={e => handleFilterPartName(e.target.value)} />
                                                                <div className='border px-2 py-1 rounded-xl bg-white absolute left-0 w-[66%]'>
                                                                    {filterLaydown.filter((obj, index) => {
                                                                        return index === filterLaydown.findIndex(o => obj.part_number === o.part_number);
                                                                    }).map((i) => {
                                                                        return (
                                                                            <p className='cursor-pointer m-1 p-1.5 hover:bg-gray-100 text-sm font-normal text-black' style={{ textTransform: "capitalize" }} onClick={() => { setLaydownSearch({ laydown: false, laydownId: laydownSearch.laydownId, part: false, partId: parseInt(i.part_number) }) }}>
                                                                                {handleFindPartName(i.part_number)}
                                                                            </p>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </th>
                                                    <th className='normal-case w-[14%] px-4'>Delivered Qty</th>
                                                    <th className='normal-case w-[14%] px-4'>Damaged Qty</th>
                                                    <th className='normal-case w-[14%] px-4'>Remediated Qty</th>
                                                    {editMode &&
                                                        <th className='normal-case text-center w-[7%]'>Actions</th>}
                                                </tr>
                                            </thead>
                                            {laydownTable.length === 0 ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={6} className='py-14'>
                                                            {editMode ?
                                                                <div className='m-auto'>
                                                                    <FaFileMedical className='m-auto text-gray-300 text-6xl cursor-pointer' onClick={handleAddEntry} />
                                                                    <p className='text-gray-400 mt-4 text-center font-medium cursor-pointer' onClick={handleAddEntry}>Click here to start add entry</p>
                                                                </div>
                                                                :
                                                                <div className='m-auto'>
                                                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                                    <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                :
                                                <tbody>
                                                    {laydownTable.map((i, index) => {
                                                        if (!i.delete) {
                                                            if (laydownSearch.laydownId === i.laydown || laydownSearch.laydownId === null) {
                                                                if (laydownSearch.partId === i.part_number || laydownSearch.partId === null) {
                                                                    if (editMode) {
                                                                        return (
                                                                            <tr>
                                                                                <td className='pr-4'>
                                                                                    <Dropdown
                                                                                        tableRef={tableRef}
                                                                                        options={listLayout.data}
                                                                                        defaultValue={listLayout.data.find(o => o.id === i?.laydown)?.name}
                                                                                        className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                        placeholder={"Choose a Laydown"}
                                                                                        keyToDisplay={"name"}
                                                                                        search={true}
                                                                                        option={{ width: "350px", height: "400px" }}
                                                                                        onChange={e => handleUpdateChangeLaydown(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Dropdown
                                                                                        tableRef={tableRef}
                                                                                        options={mileStoneData}
                                                                                        defaultValue={mileStoneData.find(o => o.bom_id === i?.part_number)?.description}
                                                                                        className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                        placeholder={"Choose a Part Description"}
                                                                                        keyToDisplay={"description"}
                                                                                        search={true}
                                                                                        hoverOnTooltip={true}
                                                                                        option={{ width: "350px", height: "400px" }}
                                                                                        onChange={e => handleUpdateChangePartNumber(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Dropdown
                                                                                        tableRef={tableRef}
                                                                                        options={mileStoneData}
                                                                                        defaultValue={mileStoneData.find(o => o.bom_id === i?.part_number)?.part_name}
                                                                                        className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                        placeholder={"Choose a Part Name"}
                                                                                        keyToDisplay={"part_name"}
                                                                                        search={true}
                                                                                        option={{ width: "200px", height: "400px" }}
                                                                                        onChange={e => handleUpdateChangePartNumber(e, index)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input required className='border rounded-xl px-4 py-3 w-[80%]' type='number' placeholder='Quantity' defaultValue={i.quantity} onChange={(e) => setLaydownTable(produce((draft) => { draft[index].quantity = parseInt(e.target.value); draft[index]["isEdit"] = true }))} />
                                                                                </td>
                                                                                <td className='px-4'>
                                                                                    <input required className='border rounded-xl px-4 py-3 w-[80%]' type='number' placeholder='Quantity' defaultValue={i?.damaged} onChange={(e) => setLaydownTable(produce((draft) => { draft[index].damaged = parseInt(e.target.value); draft[index]["isEdit"] = true }))} />
                                                                                </td>
                                                                                <td className='px-4'>
                                                                                    {i?.remediated_quantity === null ? "0" : i?.remediated_quantity}
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <MdDelete className='cursor-pointer m-auto text-xl active:text-red-550' onClick={() => setLaydownTable(produce((draft) => { draft[index]["delete"] = true; draft[index]["isEdit"] = true }))} />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <tr className='w-full'>
                                                                                <td className='px-4' style={{ textTransform: "capitalize" }}>{listLayout.data.filter(ele => ele.id === parseInt(i.laydown))[0]?.name}</td>
                                                                                <td className='px-4 relative' onMouseEnter={() => { setShowDescription(i?.bom_delivery_laydown_id) }} onMouseLeave={() => setShowDescription()}>
                                                                                    {showDescription === i?.bom_delivery_laydown_id ?
                                                                                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                                            {mileStoneData?.find(o => o.bom_id === i.part_number)?.description}
                                                                                        </p>
                                                                                        :
                                                                                        <p className='w-[300px]' style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>
                                                                                            {mileStoneData?.find(o => o.bom_id === i.part_number)?.description}
                                                                                        </p>
                                                                                    }
                                                                                </td>
                                                                                <td className='px-4'>{handleFindPartName(i.part_number)}</td>
                                                                                <td className='px-4'>{i.quantity}</td>
                                                                                <td className='px-4'>{i?.damaged}</td>
                                                                                <td className='px-4'>
                                                                                    {i?.remediated_quantity === null ? "0" : i?.remediated_quantity}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                }
                                                            }
                                                        }
                                                    })}
                                                </tbody>
                                            }
                                        </table>
                                        {laydownTable.length !== 0 && editMode &&
                                            <p className='cursor-pointer flex justify-end my-4 mx-14' onClick={handleAddEntry}>+ Add Entry</p>
                                        }
                                    </div>
                                </form>
                            </div>
                            :
                            deliveryStep === 3 ?
                                <div>
                                    {deliveryLoader ?
                                        <div className='absolute opacity-100 left-[45%] top-[40%] z-50'>
                                            <p className='loader ml-3 mb-1'></p>
                                            <p className='font-bold text-xl'>Updating...</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <form className={`w-[90vw] max-w-[1200px] ${deliveryLoader ? "opacity-50" : "opacity-100"}`} onSubmit={handleSubmit(handleSetupDeliveryForm)}>
                                        <div className='w-full overflow-auto h-[70vh]'>
                                            <div className='flex items-center space-x-5'>
                                                <p className='mt-7 font-semibold w-[35%] text-gray-400'>Date & Time :</p>
                                                <div className='flex space-x-2 mt-4'>
                                                    <p className='border bg-gray-200 text-black w-[140px] border-gray-300 px-2 py-1'>Date</p>
                                                    <p className='border bg-gray-200 text-black w-[120px] border-gray-300 px-2 py-1'>Time</p>
                                                </div>
                                            </div>
                                            <div className='flex items-center space-x-5'>
                                                <div className='mt-7 flex space-x-3 items-center font-semibold w-[35%]'>
                                                    {deliveryNameEdit?.bool ?
                                                        <input
                                                            ref={deliveryNameRef}
                                                            value={deliveryNameEdit?.data}
                                                            className='border px-1 py-0.5 rounded'
                                                            type='text'
                                                            placeholder='Enter Delivery Reference'
                                                            onChange={e => handleEditDeliveryReferenc(e)}
                                                            onKeyDown={handleKeyDown}
                                                            onBlur={handleBlur}
                                                        />
                                                        :
                                                        <p className='border border-white'>{getDeliveryReferenceName() === "" ? deliveryNameEdit?.data : getDeliveryReferenceName()}</p>
                                                    }
                                                    <span>
                                                        {loader ? <p className='loader' style={{ width: "20px", height: "20px" }} />
                                                            : <MdModeEditOutline className='cursor-pointer' onClick={() => setDeliveryNameEdit({ bool: true, data: deliveryNameEdit?.data })} />}
                                                    </span>

                                                </div>
                                                <textarea rows="2" cols="50" placeholder='Enter Delivery Reference' className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" />
                                            </div>
                                            <div className='flex items-center space-x-5'>
                                                <p className='mt-7 font-semibold w-[35%] text-gray-400'>Comments :</p>
                                                <textarea rows="2" cols="50" placeholder='Enter Comments' disabled className="appearance-none block text-gray-700 border border-gray-300 bg-gray-200 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" />
                                            </div>
                                            <div className='flex items-center space-x-5 my-4'>
                                                <p className='mt-7 font-semibold w-[35%] text-gray-400'>Documents :</p>
                                                <div className='w-[50%]'>
                                                    <div>
                                                        <div className={`border-2 border-dashed border-gray bg-gray-200 rounded-2xl mt-4 p-2 w-full h-[140px]`}>
                                                            <div className='flex justify-end'>
                                                                {docCount.length > 1 ?
                                                                    <IoMdRemoveCircleOutline className='m-2' />
                                                                    :
                                                                    <p className="m-4" />
                                                                }
                                                            </div>
                                                            <div className="font-normal text-center mt-[-15px]">
                                                                <RxUpload className='m-auto text-red-550' />
                                                                <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                <p className="text-xs mt-4">
                                                                    Supported files : JPG, PNG, PDF
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex items-center space-x-5 my-4'>
                                                <div className='w-[100%]'>
                                                    {customFieldsData?.map((custFields, custIndex) => {
                                                        if (!custFields?.delete) {
                                                            return (
                                                                <div className='flex space-x-4 items-center w-[100%]'>
                                                                    <div className='w-[36%]'>
                                                                        <input className='border px-4 py-1.5 my-2 rounded-xl' placeholder={`custom field`} value={custFields?.name} onChange={e => setCustomFieldsData(produce((draft) => { draft[custIndex].name = e.target.value }))} />
                                                                    </div>
                                                                    <div className='flex space-x-4 items-center'>
                                                                        <select required className={`px-4 py-2 rounded-xl select-bordered border-2 w-[200px] my-2 cursor-pointer`} onChange={e => setCustomFieldsData(produce((draft) => { draft[custIndex].type = parseInt(e.target.value); draft[custIndex].values = { values: [] } }))}>
                                                                            <option hidden selected>Choose Field Type</option>
                                                                            <option selected={custFields?.type === 0} value={0}>Integer</option>
                                                                            <option selected={custFields?.type === 1} value={1}>Float</option>
                                                                            <option selected={custFields?.type === 2} value={2}>Free Text</option>
                                                                            <option selected={custFields?.type === 3} value={3}>Dropdown</option>
                                                                        </select>
                                                                        <MdDelete className='cursor-pointer' onClick={() => handleRemoveCustomFields(custIndex)} />
                                                                        <div style={{ display: custFields?.type === 3 ? "" : "none" }}>
                                                                            <p className={`cursor-pointer underline underline-offset-2 text-blue-500 text-sm`} onClick={() => setDropdownModal({ boolean: true, index: custIndex, values: customFieldsData[custIndex]?.values ? customFieldsData[custIndex]?.values : [] })} >
                                                                                View & edit dropdown options
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            <p className='mt-7 font-semibold w-[35%]'>
                                                {handleCheckCountCustomFields()?.length < 5 ?
                                                    <p className={`cursor-pointer font-semibold`} onClick={handleAddCustomFields}>+ Add Custom Fields</p>
                                                    :
                                                    <p className={`cursor-auto font-semibold text-gray-500`}>+ Add Custom Fields</p>
                                                }
                                            </p>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div>
                                    {deliveryLoader ?
                                        <div className='absolute opacity-100 left-[45%] top-[40%] z-50'>
                                            <p className='loader ml-3 mb-1'></p>
                                            <p className='font-bold text-xl'>Updating...</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <form onSubmit={handleSubmit(handleCreateDelivery)} className={`w-[90vw] max-w-[1200px] ${deliveryLoader ? "opacity-50" : "opacity-100"}`}>
                                        <div className='w-full overflow-auto h-[70vh]'>
                                            <div className='flex items-center space-x-5'>
                                                <p className='mt-7 font-semibold w-[35%]'>Date & Time :</p>
                                                <div className='flex space-x-2 mt-4'>
                                                    <DatePicker showIcon toggleCalendarOnIconClick popperPlacement='bottom' icon={<SlCalender className='cursor-pointer mt-0.5' />} dateFormat="dd-MMM-yyyy" onChange={setDeliveryDate} selected={deliveryDate} calendarContainer={datePickerStyle} className="py-1 border-gray-400 cursor-pointer border w-[140px]" />
                                                    <TimePicker disableClock onChange={setDeliveryTime} value={deliveryTime} className={"w-[120px] rounded-xl border-none"} />
                                                </div>
                                            </div>
                                            <div className='flex items-center space-x-5'>
                                                <p className='mt-7 font-semibold w-[35%]'>{getDeliveryReferenceName() === "" ? "Delivery Reference :" : getDeliveryReferenceName()}</p>
                                                <textarea rows="2" cols="50" placeholder='Enter Delivery Reference' className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" {...register("delivery_reference")} />
                                            </div>
                                            <div className='flex items-center space-x-5'>
                                                <p className='mt-7 font-semibold w-[35%]'>Comments :</p>
                                                <textarea rows="2" cols="50" placeholder='Enter Comments' className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 w-[50%]" {...register("comments")} />
                                            </div>
                                            <div className='flex items-center space-x-5 my-4'>
                                                <p className='mt-7 font-semibold w-[35%]'>Documents :</p>
                                                <div className='w-[50%]'>
                                                    {docCount.map((count, index) => {
                                                        return (
                                                            <div>
                                                                {!deliveryDocs[index] ?
                                                                    <div className={`border-2 border-dashed border-gray rounded-2xl cursor-pointer mt-4 p-2 w-full h-[140px]`}>
                                                                        <div className='flex justify-end'>
                                                                            {docCount.length > 1 ?
                                                                                <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleDeleteDoc(count, index)} />
                                                                                :
                                                                                <p className="m-4" />
                                                                            }
                                                                        </div>
                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="font-normal text-center mt-[-15px]">
                                                                                <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                                <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                                <p className="text-xs mt-4">
                                                                                    Supported files : JPG, PNG, PDF
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="my-2">
                                                                        <div className="flex justify-between w-full">
                                                                            <p className={`text-sm font-semibold`}>
                                                                                {docNameEdit[index]}
                                                                            </p>
                                                                            {docsOpen.boolean &&
                                                                                docsOpen?.data?.name?.split(".")[1] === "pdf" ?
                                                                                <PDFViewer
                                                                                    isModalShow={isPDFModalShow}
                                                                                    setIsModalShow={setIsPDFModalShow}
                                                                                    pdfUrl={images}
                                                                                />
                                                                                :
                                                                                docsOpen?.data?.name?.split(".")[1] === "png" || docsOpen?.data?.name?.split(".")[1] === "jpg" ?
                                                                                    <div className='flex'>
                                                                                        <MdOutlineFileDownload className='text-gray-400 hover:text-white fixed right-14 top-9 opacity-50 hover:opacity-100 cursor-pointer z-50 text-2xl' onClick={() => handleSavePdf(docsOpen.data)} />
                                                                                        <ImageViewer
                                                                                            src={images}
                                                                                            onClose={handleCloseImageViewer}
                                                                                            disableScroll={false}
                                                                                            backgroundStyle={{
                                                                                                backgroundColor: "rgba(0,0,0,0.9)"
                                                                                            }}
                                                                                            closeOnClickOutside={true}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className='flex space-x-4 pr-4'>
                                                                                <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" onClick={() => { setDocRenameBox(index); setDisableSubmit(true) }} style={{ width: "20px", height: "20px" }} />
                                                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => handleRemoveDoc(index)} />
                                                                            </div>
                                                                        </div>
                                                                        {docRenameBox === index ?
                                                                            <div className="flex">
                                                                                <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={docNameEdit[index]?.split(".")[0]} onChange={e => setDocNameChange(e.target.value + "." + docNameEdit[index]?.split(".")[1])} />
                                                                                <button className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleDocNameChange(index)}>Save</button>
                                                                                <button className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setDocRenameBox(null); setDisableSubmit(false) }}>Cancel</button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                    <p className='cursor-pointer text-right my-2' onClick={handleAddDoc}>+ Add More</p>
                                                </div>
                                            </div>
                                            {deliveryCustFieData.map((customData, customIndex) => {
                                                return (
                                                    <div className='flex items-center space-x-5'>
                                                        <p className='font-semibold w-[35%] mt-7'>{customData?.name} :</p>
                                                        {customData?.type === 0 ?
                                                            <div className='w-[25%]'>
                                                                <input type='number'
                                                                    step="1"
                                                                    min="0"
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === '.' || e.key === 'e' || e.key === '-') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    defaultValue={customData?.response} placeholder={`Enter ${customData?.name}`} className='appearance-none block w-full text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex]["responseData"] = parseInt(e.target.value) }))} />
                                                                <p className='text-red-500 text-xs'>Integer Only</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {customData?.type === 1 ?
                                                            <div className='w-[25%]'>
                                                                <input type='number' defaultValue={customData?.response} placeholder={`Enter ${customData?.name}`} className='appearance-none block w-full text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex]["responseData"] = parseInt(e.target.value) }))} />
                                                                <p className='text-red-500 text-xs'>Float Only</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {customData?.type === 2 ?
                                                            <div className='w-[25%]'>
                                                                <input type='text' defaultValue={customData?.response} placeholder={`Enter ${customData?.name}`} className='appearance-none block w-full text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex]["responseData"] = e.target.value }))} />
                                                                <p className='text-red-500 text-xs'>Free Text Only</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {customData?.type === 3 ?
                                                            <select className='border rounded-xl px-4 py-4 mt-4 mr-7 w-[25%]' onChange={e => setDeliveryCustFieData(produce((draft) => { draft[customIndex]["responseData"] = e.target.value }))}>
                                                                <option selected hidden className='text-gray-400'>Select {customData?.name}</option>
                                                                {customData?.values?.values && customData?.values?.values.map((dropData) => {
                                                                    return (
                                                                        <option selected={dropData === customData?.response} value={dropData}>{dropData}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                )
                                            })}
                                            <table className='table table-compact z-0 w-full mt-5'>
                                                <thead>
                                                    <tr>
                                                        <th className='normal-case w-[20%] px-4'>
                                                            Laydown
                                                        </th>
                                                        <th className='normal-case w-[25%] px-4'>
                                                            Part Description
                                                        </th>
                                                        <th className='normal-case w-[20%] px-4'>
                                                            Part Number
                                                        </th>
                                                        <th className='normal-case w-[14%] px-4'>
                                                            Delivered Qty
                                                        </th>
                                                        <th className='normal-case w-[14%] px-4'>
                                                            Damaged Qty
                                                        </th>
                                                        <th className='normal-case text-center w-[7%]'>
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {laydownTable.length === 0 ?
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={6} className='py-14'>
                                                                <div className='m-auto'>
                                                                    <FaFileMedical className='m-auto text-gray-300 text-6xl cursor-pointer' onClick={handleAddEntry} />
                                                                    <p className='text-gray-400 mt-4 text-center font-medium cursor-pointer' onClick={handleAddEntry}>Click here to start add entry</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        {laydownTable.map((i, index) => {
                                                            if (!i.delete) {
                                                                if (i.addNew) {
                                                                    return (
                                                                        <tr>
                                                                            <td>
                                                                                <Dropdown
                                                                                    tableRef={tableRef}
                                                                                    options={listLayout.data}
                                                                                    defaultValue={listLayout.data.find(o => o.id === i?.laydown)?.name}
                                                                                    className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                    placeholder={"Choose a Laydown"}
                                                                                    keyToDisplay={"name"}
                                                                                    search={true}
                                                                                    option={{ width: "350px", height: "400px" }}
                                                                                    onChange={e => handleCreateChangeLaydown(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Dropdown
                                                                                    tableRef={tableRef}
                                                                                    options={mileStoneData}
                                                                                    defaultValue={mileStoneData.find(o => o.bom_id === i?.part_number)?.description}
                                                                                    className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                    placeholder={"Choose a Part Description"}
                                                                                    keyToDisplay={"description"}
                                                                                    search={true}
                                                                                    hoverOnTooltip={true}
                                                                                    option={{ width: "350px", height: "400px" }}
                                                                                    onChange={e => handleCreateChangePartDescription(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Dropdown
                                                                                    tableRef={tableRef}
                                                                                    options={mileStoneData}
                                                                                    defaultValue={mileStoneData.find(o => o.bom_id === i?.part_number)?.part_name}
                                                                                    className={"w-[95%] px-4 py-3 border rounded-xl"}
                                                                                    placeholder={"Choose a Part Name"}
                                                                                    keyToDisplay={"part_name"}
                                                                                    search={true}
                                                                                    option={{ width: "200px", height: "400px" }}
                                                                                    onChange={e => handleCreateChangePartNumber(e, index)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input required className=' border rounded-xl px-4 py-3 w-[100%]' type='number' placeholder='Quantity' onChange={(e) => setLaydownTable(produce((draft) => { draft[index].quantity = parseInt(e.target.value) }))} />
                                                                            </td>
                                                                            <td className='px-4'>
                                                                                <input required className=' border rounded-xl px-4 py-3 w-[100%]' type='number' placeholder='Damaged' onChange={(e) => setLaydownTable(produce((draft) => { draft[index].damaged = parseInt(e.target.value) }))} />
                                                                            </td>
                                                                            <td className='text-center'>
                                                                                <MdDelete className='cursor-pointer m-auto text-xl active:text-red-550' onClick={() => setLaydownTable(produce((draft) => { draft[index]["delete"] = true }))} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <tr>
                                                                            <td className='px-4' style={{ textTransform: "capitalize" }}>{listLayout.data.filter(ele => ele.id === parseInt(i.laydown))[0]?.name}</td>
                                                                            <td className='px-4'>{mileStoneData?.find(o => o.bom_id === i.part_number)?.description}</td>
                                                                            <td className='px-4'>{handleFindPartName(i.part_number)}</td>
                                                                            <td className='px-4'>{i.quantity}</td>
                                                                            <td className='px-4'>{i?.damaged ? i?.damaged : 0}</td>
                                                                            <td className='text-center'></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            }
                                                        })}
                                                    </tbody>
                                                }
                                            </table>
                                            {laydownTable.length !== 0 &&
                                                <p className='cursor-pointer flex justify-end my-4 mx-14' onClick={handleAddEntry}>+ Add Entry</p>
                                            }
                                        </div>
                                    </form>
                                </div>
                    }
                </form>
            </Modal>
            <Modal
                isOpen={dropdownModal.boolean}
                style={customConfirmStyle}
                contentLabel='MultipleValueTextInput'
            >
                <div className='p-3 w-[500px]'>
                    <p className='font-medium text-base mb-2'>{customFieldsData[dropdownModal.index]?.name}</p>
                    <div className='flex space-x-3 items-center text-xs mb-3'>
                        <AiOutlineInfoCircle className='text-gray-400' />
                        <p className='text-gray-400'>Add required drop down options and press Enter</p>
                    </div>
                    <MultipleValueTextInput
                        onItemAdded={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                        onItemDeleted={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                        name="item-input"
                        className={"w-[40%] p-1 mt-5 border-b-2"}
                        placeholder=""
                        values={customFieldsData[dropdownModal.index]?.values?.values ? customFieldsData[dropdownModal.index]?.values?.values : []}
                    />
                    <div className='flex space-x-5 justify-end my-3'>
                        <p className='cursor-pointer font-medium text-gray-500' onClick={() => setDropdownModal({ boolean: false, index: null, values: { values: [] } })}>Cancel</p>
                        <button className='cursor-pointer font-medium text-red-550' onClick={handleMultiInputSubmit}>Save</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={exitModal}
                onAfterClose={() => setExitModal(false)}
                style={customDeliveryModal}
                contentLabel='Exit change Modal'>
                <div className='w-[20vw] flex flex-col space-y-3 mt-10'>
                    <p className='text-gray-500 text-base'>Exit without saving changes</p>
                    <hr />
                    <div className='flex space-x-5 justify-end'>
                        <button className='bg-white text-black hover:text-white btn rounded-full px-4 py-2 border-2 cursor-pointer' onClick={() => setExitModal(false)}>Cancel</button>
                        <button className='bg-red-550 text-white px-6 py-2 btn rounded-full border-none' onClick={() => {
                            setExitModal(false)
                            setDeliveryStep(0)
                            reset()
                            setdeliveryDocs([])
                            setEditDelivery(produce((draft) => { draft.boolean = false; draft.data = {}; draft.isEdit = false }))
                            setDocCount([])
                            setDocRenameBox(null)
                            setDocNameEdit([])
                            setDisableSubmit(false)
                            setDocNameChange()
                            setEditMode(false)
                            setLaydownTable([])
                            setAddEntry({ boolean: false, data: [] })
                        }}
                        >OK</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={customDltWarning.bool}
                onAfterClose={() => setCustomDltWarning({ bool: false, index: null })}
                style={customDeliveryModal}
                contentLabel='Custom fields delete Modal'
            >
                <div className='w-[500px]'>
                    <p className='mt-5 mb-2'>Deleting this field would delete all it's associated data which may have been captured in old deliveries.</p>
                    <div className='flex space-x-4 justify-end'>
                        <button className='btn bg-white border-2 rounded-full text-gray-500 hover:text-white' onClick={() => setCustomDltWarning({ bool: false, index: null })}>Cancel</button>
                        <button className='btn bg-red-550 text-white border-none rounded-full' onClick={handleDeleteCustomFields}>Proceed</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={dltDelivery.boolean}
                onAfterClose={() => setDltDelivery(false)}
                style={customDeliveryModal}
                contentLabel='delete delivery Modal'
            >
                <div className='w-[30vw] flex flex-col space-y-3 mt-8'>
                    <p className='text-base text-gray-500'>Please confirm if you want to delete the delivery reference <span className='font-medium text-black'>"{handleFindDeliveryId()}"</span> and all its associated documents</p>
                    <hr />
                    <div className='flex space-x-5 justify-end'>
                        <button className='bg-white text-black hover:text-white btn rounded-full px-4 py-2 border-2 cursor-pointer' onClick={() => setDltDelivery({ boolean: false, data: null })}>Cancel</button>
                        {deleteDeliveryLoader ?
                            <button className='bg-red-550 flex space-x-3 items-center text-white px-6 py-2 btn rounded-full border-none'>
                                <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                <p>OK</p>
                            </button>
                            :
                            <button className='bg-red-550 text-white px-6 py-2 btn rounded-full border-none' onClick={handleDeleteDelivery}>OK</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ManageDelivery