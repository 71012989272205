import React from 'react'

function PrimaryButton({
    text,
    onClick,
    className
}) {
    return (
        <button
            className={`cursor-pointer border-none px-3 py-2 hover:bg-gray-700 text-xs font-semibold bg-red-550 text-white rounded-xl min-w-[80px] ${className}`}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default PrimaryButton
