import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from '../../../components/container'
import Drawer from 'react-modern-drawer'
import { BiArrowBack } from "react-icons/bi"
import { RiFolderDownloadFill } from "react-icons/ri"
import StaffTable from './staffTable'
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import Modal from "react-modal"
import { VscClose } from 'react-icons/vsc'
import { useAtom } from 'jotai'
import staffAtom, { fetchImportStaffAtom, fetchStaffAtom, updateImportStaffsAtom } from '../../../atoms/staffAtom'
import { toast } from 'react-hot-toast'
import { AiOutlineSearch } from 'react-icons/ai'
import AddStaffMulti from './addStaffMulti'
import { FaUser, FaUsers } from "react-icons/fa";


function StaffList() {

  const _ = require("lodash")

  const [importLoader, setImportLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [importStaff, setImportStaff] = useState([])
  const [importFilter, setImportFilter] = useState([])
  const [searchBoolean, setSearchBoolean] = useState(false)
  const [listFilter, setListFilter] = useState([])
  const [orgView, setOrgView] = useState(false)

  const [staffDetails] = useAtom(staffAtom)
  const [, fetchStaff] = useAtom(fetchStaffAtom)
  const [, setImportStaffs] = useAtom(fetchImportStaffAtom)
  const [, setUpdateImportStaffs] = useAtom(updateImportStaffsAtom)

  const dropdownRef = useRef(null);
  const orgViewRef = useRef(false)
  const [isActive, setIsActive] = useState('Org Staff')

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setSearchBoolean(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setLoading(true)
      await fetchStaff()
      await setImportStaffs()
    }
    catch (err) {
      toast.error("Something went wrong")
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let sortedStaff = _.sortBy(staffDetails?.importStaffs,
      [function (o) { return o.user.name }])
    setImportFilter(sortedStaff)
  }, [staffDetails.importStaffs])

  useEffect(() => {
    handleOrgView()
  }, [staffDetails?.users, staffDetails.importStaffs, orgView])

  const handleOrgView = () => {
    if (orgView) {
      setListFilter(staffDetails?.importStaffs)
    }
    else {
      setListFilter(staffDetails?.users)
    }
  }

  console.log(listFilter)

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [modalActivityOpen, setModalActivityOpen] = useState(false)

  function closeModal() {
    setIsOpen(false);
    setModalActivityOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '540px'
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleSelectStaffs = (data) => {
    importStaff.push(data)
  }

  const serachImportStaff = (e) => {
    let quaryFilter = e.target.value
    var updateList = [...staffDetails?.importStaffs]
    updateList = updateList.filter((item) => item.user.name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1)
    setImportFilter(updateList)
  }

  function handleCheckStaffExists(userId) {
    let temp = listFilter.find(o => o?.user?.id === userId)
    if (temp?.user?.id) {
      return true
    }
    else {
      return false
    }
  }

  const searchFilterView = (e) => {
    let quaryFilter = e.target.value
    var updateList
    if (orgView) {
      updateList = staffDetails?.importStaffs
    }
    else {
      updateList = staffDetails?.users
    }
    updateList = updateList.filter((item) =>
      item.user.name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
    )
    if (updateList.length === 0) {
      updateList = [...staffDetails.users].filter((item) =>
        item?.user.profile_data?.last_name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
      )
    }
    setListFilter(updateList)
  }

  const handleSubmitImportStaff = async (e) => {
    e.preventDefault()
    setImportLoader(true)
    try {
      await setUpdateImportStaffs(importStaff)
      await fetchStaff()
      await setImportStaffs()
      handleOrgView()
      toast.success("Staff Imported")
      setImportStaff([])
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setImportLoader(false)
      setModalActivityOpen(false)
    }
  }

  const handleClick = (value) => {
    setIsActive(value)
    if (value === 'Org Staff') {
      setOrgView(false)
      orgViewRef.current = false
    } else {
      setOrgView(true)
      orgViewRef.current = true
    }
  }

  return (
    <Container>
      <div className='flex justify-between mb-5'>
        <div>
          <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/staff_and_plant")} />
          <div className='ml-6 mt-5 text-xl font-bold'>
            Staff List
          </div>
        </div>
        <div className='flex' ref={dropdownRef}>
          <div>
            {!searchBoolean ?
              <AiOutlineSearch className='text-2xl mr-5 mt-10 cursor-pointer' onClick={() => setSearchBoolean(true)} />
              :
              <input className='appearance-none text-gray-700 border border-gray-300 leading-tight py-3 px-5 rounded-3xl mr-8 mt-7' type='search' placeholder='Search Staff' style={{ width: "280px", height: "48px" }} onChange={e => searchFilterView(e)} />
            }
          </div>
          <div className='flex text-sm cursor-pointer border rounded-full font-medium mt-7 mb-4 p-1 bg-red-50 border-red-100 mr-4'>
            <div className={`flex ${isActive === 'Org Staff' ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => { handleClick("Org Staff") }}>
              <FaUsers className={`mt-3 ml-3 ${isActive === 'Org Staff' ? "text-white" : "text-gray-400"}`} />
              <p className={`${isActive === 'Org Staff' ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>Project Staff</p>
            </div>
            <div className={`flex ${isActive === 'Project Staff' ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => { handleClick("Project Staff") }}>
              <FaUser className={`mt-3 ml-3 ${isActive === 'Project Staff' ? "text-white" : "text-gray-400"}`} />
              <p className={`${isActive === 'Project Staff' ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>Org Staff</p>
            </div>
          </div>
          {isActive === "Project Staff" ?
            <div className='flex mt-10 mr-4'>
              <RiFolderDownloadFill className='text-gray-300 mr-1' style={{ width: '25px', height: '25px' }} />
              <p className='font-semibold text-gray-300'>Import</p>
            </div>
            :
            <div className='flex mt-10 mr-4 cursor-pointer' onClick={() => setModalActivityOpen(true)}>
              <RiFolderDownloadFill className='text-gray-500 mr-1' style={{ width: '25px', height: '25px' }} />
              <p className='font-semibold text-gray-500'>Import</p>
            </div>
          }
          <Modal
            isOpen={modalActivityOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Import Staff">
            <div>
              <div className='flex justify-between'>
                <p className='font-bold text-xl' >Select Staff</p>
                <VscClose onClick={closeModal} className="cursor-pointer mt-1" style={{ width: '25px', height: '25px' }} />
              </div>
              <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Staff' onChange={e => { serachImportStaff(e) }} />
              <p className='m-2 pt-1 text-gray-400 font-medium'>{importFilter?.length} staff available in the database</p>
              <form>
                {loading ?
                  <div className='' style={{ marginTop: "20%", marginLeft: "44%" }}>
                    <p className='loader ml-3 mb-1'></p>
                    <p className='font-bold text-xl'>Fetching...</p></div>
                  :
                  <div className='overflow-auto' style={{ height: '290px' }}>
                    {importFilter.length > 0 ?
                      importFilter.map((i) => {
                        if (handleCheckStaffExists(i.user?.id)) {
                          return <>
                            <label>
                              <div className='flex mt-1 cursor-auto bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' value={i.user?.id} disabled />
                                {i?.user?.profile_data?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.user?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.user?.profile_data?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2 flex flex-col justify-center'>
                                  <span className='text-sm font-semibold text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.user?.name} {i?.user?.profile_data?.last_name}</span>
                                  <p className='text-xs font-normal text-gray-400' style={{ textTransform: 'capitalize' }}>{i?.user?.profile_data?.role_auth}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                        else {
                          return <>
                            <label>
                              <div className='flex mt-1 cursor-pointer hover:bg-gray-100 py-1 rounded-lg' style={{ marginBottom: "-14px" }}>
                                <input type="checkbox" className='mr-2 mt-1' value={i.user?.id} onChange={e => handleSelectStaffs(e.target.value)} />
                                {i?.user?.profile_data?.profile_url === null ?
                                  <div className='avatar'>
                                    <div className='mt-1.5 px-2 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                      <span className='text-base' style={{ textTransform: "capitalize" }}>{i?.user?.name?.charAt(0)}</span>
                                    </div>
                                  </div>
                                  :
                                  <div className='avatar w-8 h-8 mt-1.5'>
                                    <img src={i?.user?.profile_data?.profile_url} alt='' className='rounded-full ' />
                                  </div>
                                }
                                <div className='ml-2 flex flex-col justify-center'>
                                  <span className='text-sm font-semibold' style={{ textTransform: 'capitalize' }}>{i?.user?.name} {i?.user?.profile_data?.last_name}</span>
                                  <p className='text-xs font-normal text-gray-500' style={{ textTransform: 'capitalize' }}>{i?.user?.profile_data?.role_auth}</p>
                                </div>
                              </div>
                            </label><br />
                          </>
                        }
                      })
                      :
                      <div className='flex justify-center' style={{ margin: "90px 50px" }}>
                        <div>
                          <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                          <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div className={`flex justify-end ${loading ? "mt-32" : null}`}>
                  <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setModalActivityOpen(false); setImportStaff([]) }}>Cancel</button>
                  {importLoader ?
                    <button className='btn flex space-x-2 items-center border-none rounded-3xl bg-red-550 text-white px-5 mr-4'>
                      <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                      <p>Import</p>
                    </button>
                    :
                    <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={e => handleSubmitImportStaff(e)}>
                      Import
                    </button>
                  }
                </div>
              </form>
            </div>
          </Modal>

          <div className="drawer-content">
            <button htmlFor="my-drawer" onClick={toggleDrawer} className="mt-7 mr-10 bg-red-550 text-white border-none rounded-3xl btn drawer-button ">
              Add Staff <span className='text-xl ml-1 mb-0.5'>+</span>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction='right'
          size='600px'
          className='absolute'
        >
          <AddStaffMulti onClose={toggleDrawer} orgView={orgView} />
        </Drawer>
      </div>

      {loading ?
        <div className='' style={{ marginTop: "20%", marginLeft: "44%" }}>
          <p className='loader ml-3 mb-1'></p>
          <p className='font-bold text-xl'>Fetching...</p></div>
        :
        <StaffTable value={listFilter} orgView={orgView} />
      }
    </Container>
  )
}

export default StaffList