import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BiArrowBack } from "react-icons/bi"
import mileStone, { editMileStone, fetchMileStone, getDeliveryReferenceAliasNameAtom } from '../../../atoms/mileStoneAtom'
import Container from '../../../components/container'
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom'
import produce from "immer";
import { toast } from 'react-hot-toast'
import ActivitySelect from '../../../utils/activitySelect'
import ReactTooltip from 'react-tooltip'

function ActivityMileStones() {

    const [loading, setLoading] = useState(false)
    const [editModeBOM, setEditModeBOM] = useState(false)
    const [mileStoneData, setMileStoneData] = useState([])

    const navigate = useNavigate()
    const [listMileStone] = useAtom(mileStone)
    const [listActivityID] = useAtom(activitiesAtom)
    const [, setFetchMileStone] = useAtom(fetchMileStone)
    const [, setFetchActivity] = useAtom(fetchActivitiesAtom)
    const [, setFetchUpdateMileStone] = useAtom(editMileStone)
    const [, getDeliveryReferenceAliasName] = useAtom(getDeliveryReferenceAliasNameAtom)

    const [updateArr, setUpdateArr] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            setFetchActivity()
            await setFetchMileStone()
            getDeliveryReferenceAliasName()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (listMileStone?.data) {
            setMileStoneData(listMileStone?.data)
        }
    }, [listMileStone.data])

    const save = async () => {
        try {
            setLoading(true)
            await setFetchUpdateMileStone(updateArr)
            toast.success("Bill of materials - updated")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }
        finally {
            setLoading(false)
            setUpdateArr([])
            setEditModeBOM(false)
        }
    }

    const handleCancel = () => {
        setEditModeBOM(false)
        setMileStoneData(listMileStone?.data)
        setUpdateArr([])
    }

    const handleChangeDistribution = (e, i) => {
        let temp = {}
        if (i?.activity_milestone_id) {
            temp.id = parseInt(i?.activity_milestone_id)
        }
        temp.bom_id = i?.bom_id
        temp.distribution = parseInt(e)

        let uidex = updateArr.findIndex(elm => elm.bom_id === i.bom_id);

        if (uidex >= 0) {
            temp.installed = updateArr[uidex].installed;
            temp.on_site_Qty = parseInt(updateArr[uidex].on_site_Qty);
            setUpdateArr(produce((draft) => { draft[uidex] = temp }));
        } else {
            temp.installed = i.installed;
            temp.on_site_Qty = i.on_site_Qty;
            setUpdateArr(produce((draft) => { draft.push(temp) }));
        }
    }

    const handleChangeInstalled = (e, i) => {
        let temp = {}
        if (i?.activity_milestone_id) {
            temp.id = parseInt(i?.activity_milestone_id)
        }
        temp.bom_id = i?.bom_id
        temp.installed = parseInt(e)

        let uidex = updateArr.findIndex(elm => elm.bom_id === i.bom_id);
        if (uidex >= 0) {
            temp.distribution = updateArr[uidex].distribution;
            temp.on_site_Qty = parseInt(updateArr[uidex].on_site_Qty);
            setUpdateArr(produce((draft) => { draft[uidex] = temp }));
        } else {
            temp.distribution = i.distribution;
            temp.on_site_Qty = i.on_site_Qty;
            setUpdateArr(produce((draft) => { draft.push(temp) }));
        }
    }

    return (
        <Container>
            <div className='overflow-auto w-full' style={{ height: "calc(100vh - 80px)" }}>
                <div className='flex justify-between items-center py-3'>
                    <div>
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/setup")} />
                        <p className='ml-6 mt-5 text-xl font-bold'>Bill of Materials</p>
                    </div>
                    <div className='flex mr-4 space-x-7'>
                        {editModeBOM ?
                            <div className='flex space-x-4'>
                                <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button' style={{ width: '90px' }} onClick={save}>Save</button>
                                <button className='bg-white text-gray-500 border-2 rounded-3xl btn drawer-button' style={{ width: '90px' }} onClick={handleCancel}>Cancel</button>
                            </div>
                            :
                            <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button' style={{ width: '90px' }} onClick={() => setEditModeBOM(true)}>Edit</button>
                        }
                    </div>
                </div>
                <hr />
                <div>
                    {loading ?
                        <div style={{ marginTop: "15%", marginLeft: "48%" }}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p>
                        </div>
                        :
                        <div className='overflow-auto w-full' style={{ height: "calc(100vh - 200px)" }}>
                            <table className='table table-compact w-full overflow-auto z-0'>
                                <thead>
                                    <tr className='sticky top-0 z-20'>
                                        <th className='w-[16%]' rowSpan='2'></th>
                                        <th className='normal-case pl-6' style={{ width: "8%" }} rowSpan='2'>Part Number</th>
                                        <th className='normal-case' style={{ width: "30%" }} rowSpan='2'>Part Description</th>
                                        {/* <th className='normal-case' style={{ width: "12%" }} rowSpan='2'>On-site Qty</th> */}
                                        <th className='normal-case' style={{ width: "15%" }} rowSpan='1'>
                                            <p>Distribution</p>
                                        </th>
                                        <th className='normal-case' style={{ width: "15%" }} rowSpan='1'>
                                            <p>Installation</p>
                                        </th>
                                        <th className='w-[16%]' rowSpan='2'></th>
                                    </tr>
                                    <tr className='sticky z-20 border-none w-full' style={{ top: '35px' }}>
                                        <th className='normal-case'>Marked by completion of</th>
                                        <th className='normal-case'>Marked by completion of</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mileStoneData?.map((i, index) => {
                                        return (
                                            <tr key={index}>
                                                <td></td>
                                                <td className='pl-6' style={{ textTransform: 'uppercase' }}>{i.part_name}</td>
                                                <td data-tip data-for={`${i.bom_id}`} className='pr-8' style={{ width: "40vw" }}>
                                                    <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "40vw" }}>{i.description}</p>
                                                    <ReactTooltip effect='solid' place='top' id={`${i.bom_id}`}>
                                                        <div style={{ textTransform: 'capitalize' }}>{i.description}</div>
                                                    </ReactTooltip>
                                                </td>
                                                {/* <td>
                                                    <p className='cursor-pointer underline underline-offset-2 text-blue-400' onClick={() => handleOpenQtyModal(i)}>{i.onsite_quantity ? i.onsite_quantity : null}</p>
                                                </td> */}
                                                <td>
                                                    {editModeBOM ?
                                                        <ActivitySelect
                                                            defaultValue={i?.distribution}
                                                            placeholder="Select Activity"
                                                            className="w-52 focus:outline-none"
                                                            NoSelection={true}
                                                            activities={listActivityID.construction}
                                                            activities_disabled={true}
                                                            sub_activities_disabled={false}
                                                            multiselect={false}
                                                            onChange={e => handleChangeDistribution(e, i)}
                                                        />
                                                        :
                                                        <p>{listActivityID?.construction[i?.distribution]?.name}</p>
                                                    }
                                                </td>
                                                <td>
                                                    {editModeBOM ?
                                                        <ActivitySelect
                                                            defaultValue={i?.installed}
                                                            placeholder="Select Activity"
                                                            className="w-52 focus:outline-none"
                                                            NoSelection={true}
                                                            activities={listActivityID.construction}
                                                            activities_disabled={true}
                                                            sub_activities_disabled={false}
                                                            multiselect={false}
                                                            onChange={e => handleChangeInstalled(e, i)}
                                                        />
                                                        :
                                                        <p>{listActivityID?.construction[i?.installed]?.name}</p>
                                                    }
                                                </td>
                                                <td></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </Container>
    )
}

export default ActivityMileStones