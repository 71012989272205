import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import workHourAtom, {
    fetchWorkHourActivitiesAtom,
    fetchWorkHourUsersAtom
} from "../../../atoms/workHoursAtom";
import ActivitySelectBox from "./activityBox";

export default function EditStaffList({ workhour, editFormData, handleEditFormChange, handleEditCancel, handleEditSave, handleEditActChange, handleEditUserChange, handleChangePlant }) {

    const [listWorkHour] = useAtom(workHourAtom)

    const [, fetchWorkHourActivity] = useAtom(fetchWorkHourActivitiesAtom)
    const [, fetchWorkHourUsers] = useAtom(fetchWorkHourUsersAtom);
    const [plantId, setPlantId] = useState();
    const [plantIdType, setPlantIdType] = useState('')
    const [selectedValue, setSelectedValue] = useState(workhour?.plant_project_id);


    const init = async () => {
        try {
            await fetchWorkHourActivity();
            await fetchWorkHourUsers();
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        init();
    }, [])
    //To find user's full name
    const findUserName = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);
        if (user) {
            return user.user.name + " " + user.user?.profile_data?.last_name
        }
        return user_id
    }

    //To find user's first letter
    const findUserFirstLetter = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);
        if (user) {
            return user.user?.name?.charAt(0).toUpperCase()
        }
        return user_id
    }

    const handlePlant = (e) => {
        setSelectedValue(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.plant_project_id)
        handleChangePlant(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.plant_project_id)
        setPlantId(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.id)
        setPlantIdType(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.plant_type)
    }

    return (
        <tr>
            <td className='flex items-center pl-5 bg-gray-100'>
                {workhour.profile_url === null ?
                    <div className="avatar">
                        <div className="text-neutral-content rounded-full w-7 h-7 text-center p-0.5 bg-yellow-400 text-white text-base">
                            <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(workhour?.user_app_id)}</span>
                        </div>
                    </div>
                    :
                    <div className="avatar" >
                        <div className="w-8 h-8 rounded-full">
                            <img src={workhour.profile_url} />
                        </div>
                    </div>
                }
                <select className='ml-2 p-1.5 border border-gray-300 rounded-xl w-[80%]' name='work_staff' onChange={handleEditUserChange}>
                    <option hidden style={{ textTransform: 'capitalize' }}>{findUserName(editFormData.work_staff)}</option>
                    {listWorkHour.users.map((u, i) => {
                        return <option key={`user-${i}`} value={u?.staff_project_id}>{u?.user?.name + " " + u?.user?.profile_data?.last_name}</option>
                    })}
                </select>
            </td>
            <td className="bg-gray-100">{plantIdType ? plantIdType : workhour?.plant_type ? workhour?.plant_type : '-'}</td>
            <td className="bg-gray-100">
                <select
                    className='p-1.5 border border-gray-300 rounded-xl cursor-pointer w-[80%]'
                    onChange={handlePlant}
                    value={selectedValue}
                >
                    {!workhour?.plant_id && <option hidden>Select a plant</option>}
                    {listWorkHour.plantId.map((data) => {
                        return (
                            <option className="cursor-pointer" style={{ cursor: 'pointer' }} value={data?.plant_project_id}>
                                {`${data.name}  ${data.plant_id}`}
                            </option>
                        )
                    })}
                </select>
            </td>
            <td className="bg-gray-100">
                <ActivitySelectBox activities={listWorkHour.activities} className={"p-1.5 h-[33px] text-sm"} placeholder="Select Activity" onChange={handleEditActChange} edit={true} defaultValue={listWorkHour.activities.find((act) => act.id === editFormData.work_activity && act.type === editFormData.act_type)?.name} clickOnSelect={true} />
                {/* <select className='p-1.5 border border-gray-300 rounded-xl' name='work_activity' style={{ width: '260px' }} onChange={handleEditActChange}>
                    <option hidden>{listWorkHour.activities.find((act) => act.id === editFormData.work_activity && act.type === editFormData.act_type)?.name}</option>
                    {listWorkHour.activities.map((i, index) => {
                        return <option key={`act-${index}`} value={`${i.id}//${i.type}`}>{i.name}</option>
                    })}
                </select> */}
            </td>
            <td className="bg-gray-100">
                <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl p-1.5 leading-tight' name='work_hours' value={editFormData.work_hours} style={{ width: '130px' }} onChange={handleEditFormChange} />
            </td>
            <td className="bg-gray-100">
                <button className='mr-2 text-red-550 font-bold' onClick={handleEditSave}> Save</button>
                <button className='font-bold' onClick={handleEditCancel}>Cancel </button>
            </td>
        </tr>
    )
}
