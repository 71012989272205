function reArrangeCheckpoints(checkpoints, disableIds) {
    let result = []
    checkpoints?.map((data) => {
        let tempPoint = {}
        if (data?.id) {
            if (!disableIds) {
                tempPoint["id"] = data?.id
                if (data?.delete) {
                    tempPoint["delete"] = true
                }
            }
        }
        tempPoint["name"] = data?.name
        tempPoint["acceptance_criteria"] = data?.acceptance_criteria
        tempPoint["inspection_type"] = data?.inspection_type
        if (data?.id || (!data?.id && !data?.delete)) {
            result.push(tempPoint)
        }
    })
    return result
}

function reArrangeQcCustomFields(customFields, disableIds) {
    let result = []
    customFields?.map((data) => {
        let tempFields = {}
        if (data?.id) {
            if (!disableIds) {
                tempFields["id"] = data?.id
                if (data?.delete) {
                    tempFields["delete"] = true
                }
            }
        }
        tempFields["name"] = data?.name
        tempFields["type"] = data?.type
        tempFields["values"] = data?.values
        if (data?.id || (!data?.id && !data?.delete)) {
            result.push(tempFields)
        }
    })
    return result
}

export function create_update_checklist(checklistData, disableIds) {
    let finalData = {}
    if (checklistData?.id) {
        if (!disableIds) {
            finalData["id"] = checklistData?.id
        }
    }
    finalData["name"] = ""
    if (reArrangeCheckpoints(checklistData?.checkpoints, disableIds)?.length > 0) {
        finalData["checkpoints"] = reArrangeCheckpoints(checklistData?.checkpoints, disableIds)
    }
    if (reArrangeQcCustomFields(checklistData?.custom_inputs, disableIds)?.length > 0) {
        finalData["custom_inputs"] = reArrangeQcCustomFields(checklistData?.custom_inputs, disableIds)
    }
    return finalData
}