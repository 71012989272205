import axios from "axios";
import { atom } from "jotai";
import produce from "immer"

function sortedBomData(resData) {
    let stringData = [
        "PRS : 72618",
        "PRS : 72684",
        "PRS : 74902",
        "PRS : 75271",
        "PRS : 75287",
        "PRS : 75330",
        "BHA : 25460",
        "BHA : 25468",
        "SGM : 2054",
        "SGM : 21149",
        "DMP : 21185",
        "DMP : 21186",
        "FAS : 50858",
        "FAS : 50857",
        "SGM : 21010",
        "SGM : 21094",
        "TTU : 402332",
        "TTU : 49200",
        "TTU : 410228",
        "TTU : 49196",
        "TTU : 404359",
        "TTU : 404357",
        "TTU : 49201",
        "TTU : 404192",
        "TTU : 49197",
        "TTU : 48211",
        "TTU : 402333",
        "TTU : 49198",
        "TTU : 404355",
        "TTU : 402334",
        "TTU : 49199",
        "FAS : 5275",
        "FAS : 5070",
        "URA : 23843",
        "URA : 23039",
        "DMP : 21656",
        "CTL : 22712",
        "CTL : 31011",
        "CTL : 25056",
        "MOD : 61414",
        "CTL : 51574",
        "FAS : 5066",
        "FAS : 51680",
        "FAS : 51760",
        "TTU : 46423",
        "MSC : 20791",
        "MOD : 60557",
        "CTL : 50554",
        "CTL : 40582",
        "CTL : 20828",
        "CTL : 20687",
        "CTL : 20844",
        "CTL : 20860",
        "CTL : 41855"
    ]
    let temp = []
    let objData = {}
    for (let key in resData) {
        objData[resData[key].part_name] = resData[key]
    }
    for (let key in stringData) {
        if (objData[stringData[key]]) {
            temp.push(objData[stringData[key]])
        }
    }
    if (parseInt(localStorage.getItem("project_id")) === 153 || parseInt(localStorage.getItem("project_id")) === 162) {
        if (parseInt(localStorage.getItem("project_id")) === 162) {
            let arr = []
            temp.map((i) => {
                arr.push(i?.part_name)
            })
            let missingData = {}
            resData.map((i) => {
                if (!arr.includes(i?.part_name)) {
                    missingData = i
                }
            })
            temp.push(missingData)
        }
        return temp
    }
    else {
        return resData
    }
}

const mileStone = atom({
    data: [],
    qtyData: [],
    qtyDataArr: [],
    remediateQty: [],
    deliveryReferenceAliasName: {}
})

const _ = require("lodash")

export const fetchMileStone = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/activitymilestone`)
        let sortedBlockBOM = _.sortBy(res.data,
            [function (o) { return o.part_name }])
        let sortedArr = sortedBomData(sortedBlockBOM)
        let filteredData = sortedArr?.filter((data) => data?.project_quantity_calc !== 0)
        set(mileStone, produce((draft) => { draft.data = filteredData }))
    }
)

export const editMileStone = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/activitymilestone`, args)
        let res = await axios.get(`/projects/activitymilestone`)
        let sortedBlockBOM = _.sortBy(res.data,
            [function (o) { return o.part_name }])
        let sortedArr = sortedBomData(sortedBlockBOM)
        set(mileStone, produce((draft) => { draft.data = sortedArr }))
    }
)

export const fetchOnsiteQty = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/inventory/laydown-count?bom_id=${args.bomId}`)
        let data = {
            data: res.data,
            bom_id: args.bomId
        }
        set(mileStone, produce((draft) => { draft.qtyData = data }))
    }
)

export const fetchRemediateQty = atom(
    null,
    async(get, set, args) => {
    let res = await axios.get(`/projects/inventory/laydown-count?bom_id=${args?.bom_id}`)
    set(mileStone, produce((draft) => { draft.remediateQty = res.data }))
}
)

export const fetchOnsiteQtyAgg = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/inventory/laydown-count/agg?bom_id=${args.bomId}`)
        set(mileStone, produce((draft) => { draft.qtyDataArr = res.data }))
    }
)

export const updateDeliveryReferenceAliasNameAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/save_field_alias?form_name=delivery&field_name=delivery_reference&field_alias_name=${args}`)
        set(mileStone, produce((draft) => { draft.deliveryReferenceAliasName = res.data }))
    }

)
export const getDeliveryReferenceAliasNameAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/get_field_alias?form_name=delivery&field_name=delivery_reference`)
        set(mileStone, produce((draft) => { draft.deliveryReferenceAliasName = res.data }))
    }
)


export default mileStone