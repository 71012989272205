import { FaKey, FaRegUser, FaUser } from "react-icons/fa";
import { HiDocumentText, HiOutlineDocumentText } from "react-icons/hi2";
import { RiBillFill, RiBillLine, RiDatabase2Fill, RiDatabase2Line } from "react-icons/ri";
import { TiChartLine, TiChartLineOutline } from "react-icons/ti";

export const mainNavBar = [
    {
        key: 1,
        title: "Dashboard",
        path: "/dashboard",
        default_icon: <TiChartLineOutline />,
        icon: <TiChartLine />
    },
    {
        key: 2,
        title: "Setup",
        path: "/setup",
        default_icon: <RiBillLine />,
        icon: <RiBillFill />
    },
    {
        key: 3,
        title: "Quality",
        path: "/quality",
        default_icon: <RiDatabase2Line />,
        icon: <RiDatabase2Fill />
    },
    {
        key: 4,
        title: "Reports",
        path: "/reports",
        default_icon: <HiOutlineDocumentText />,
        icon: <HiDocumentText />
    },
    {
        key: 5,
        title: "Staff And Plant",
        path: "/staff_and_plant",
        default_icon: <FaRegUser />,
        icon: <FaUser />
    },
    {
        key: 7,
        title: "Access Rights",
        path: "/access_rights",
        default_icon: <FaKey />,
        icon: <FaKey />
    }
]

export const qualityTabs = [
    {
        title: "Logs & Checklists",
        path: "qc_lockandunlock",
        image: "/QCPunchlist&Checksheets.png"
    },
    {
        title: "QC Punchlist",
        path: "/quality/qc_punchlist",
        image: "/QCPunchlist&Checksheets.png"
    }
]

export const reportsTab = [
    {
        title: "Summary",
        path: "/reports/summary/constructionProgress",
        image: "/summary.png"
    },
    {
        title: "Forecast Vs Actuals",
        path: "/reports/forecast",
        image: "/installStats.png"
    },
]

export const CpTabs = [
    {
        title: "CP Checklist",
        path: "/setup/ConstructionProgress",
        image: "/ConstructionProgressTemplate.png"
    },
    {
        title: "QC Checklist",
        path: "/setup/QCChecklist-revamp",
        image: "/QCChecklistTemplate.png"
    },
    {
        title: "Bill of materials",
        path: "/setup/bill_of_material",
        image: "/billOfMaterials.png"
    }
]

export const stafflistTab = [
    {
        title: "Staff List",
        path: "/staff_and_plant/staffList",
        image: "/staffList.png"
    },
    {
        title: "Plant List",
        path: "/staff_and_plant/plantList",
        image: "/plantList.png"
    },
    {
        title: "Work Hours",
        path: "/staff_and_plant/workHour",
        image: "/workHour.png"
    }
]

export const settingsMenu = [
    {
        key: 1,
        title: 'profile',
        icon: <FaUser />
    },
    {
        key: 2,
        title: 'access rights',
        icon: <FaKey />
    }
]

