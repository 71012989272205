import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { HiOutlineBars3BottomLeft } from "react-icons/hi2"
import AddFormButton from '../../../components/addForm';
import InputBox from '../../../components/inputBox';
import Modal from 'react-modal';
import { FaRegHandPointRight } from 'react-icons/fa';
import { AiTwotoneDelete } from "react-icons/ai"
import { HiSaveAs } from "react-icons/hi"
import { MdModeEditOutline } from 'react-icons/md';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import partsAtom, { fetchPartsAtom } from '../../../atoms/partsAtom';
import setupAtom, { editConstructionSetupAtom, fetchSetupAtom } from '../../../atoms/setupAtom';
import { toast } from 'react-hot-toast';
import { VscClose } from 'react-icons/vsc';
import projectAtom, { fetchProjectAtom } from '../../../atoms/projectAtom';
import { unstable_usePrompt } from 'react-router-dom/dist';
import produce from 'immer';

function ConstructionActivity() {

    const [loading, setLoading] = useState(false)
    const [btnDisable, setBtnDisable] = useState(false)

    const [chkName, setChkName] = useState()
    const [isNameOpen, setNameOpen] = useState(false)
    const [editBoolean, setEditBoolean] = useState(false)
    const [modal, setModal] = useState(false)
    const [initialForm, setInitialForm] = useState([])
    const [weightCount, setWeightCount] = useState(0)

    const [projectsName] = useAtom(projectAtom)
    const [, setFetchProjects] = useAtom(fetchProjectAtom)
    const [listPartsAtom] = useAtom(partsAtom)
    const [, fetchPartsInit] = useAtom(fetchPartsAtom)
    const [listSetupAtom] = useAtom(setupAtom)
    const [, fetchSetupInit] = useAtom(fetchSetupAtom)
    const [, fetchEditConstructionSetupInit] = useAtom(editConstructionSetupAtom)

    useEffect(() => {
        const handleHistoryChange = () => {
            setModal(true);
        };
        window.onpopstate = handleHistoryChange;
        return () => {
            window.onpopstate = null;
        };
    }, [modal]);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            await fetchPartsInit()
            await fetchSetupInit({ type: "Construction" })
            await setFetchProjects()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    unstable_usePrompt({
        when: editBoolean,
        message: "You are leaving the page without completing the edits, the Checklist will revert to last saved version"
    })

    useEffect(() => {
        setInitialForm(listSetupAtom.data)
    }, [listSetupAtom.data])

    useEffect(() => {
        if (btnDisable) {
            localStorage.setItem("cp-checklist", JSON.stringify(initialForm))
        }
    }, [initialForm])

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleFormChange = (e, index) => {
        setBtnDisable(false)
        setInitialForm(produce((draft) => { draft[index][e.target.name] = e.target.value }))
        // let data = [...initialForm];
        // data[index][e.target.name] = e.target.value
        // setInitialForm(data)
    }

    const handleSubFormChange = (e, index, subIndex) => {
        setBtnDisable(false)
        setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex][e.target.name] = e.target.value }))
        // let data = [...initialForm];
        // data[index].sub_activity[subIndex][e.target.name] = e.target.value
        // setInitialForm(data)
    }

    useEffect(() => {
        findWeightCount()
    }, [initialForm])

    function findWeightCount() {
        let weight = 0
        initialForm.map((i) => {
            if (!i?.delete) {
                if (i.sub_activity.length > 0) {
                    i.sub_activity.map((j) => {
                        console.log(j)
                        if (!j.delete) {
                            console.log(j)
                            let temp = Math.round(parseFloat(j.weight_decimal) * 100) / 100
                            if (isNaN(temp)) {
                                weight += 0
                            }
                            else {
                                weight += temp
                            }
                        }
                    })
                }
            }
        })
        setWeightCount(weight)
        return weight
    }

    function prtWeight(index, subIndex, value) {
        let temp = 0
        let weight = 0
        if (!initialForm[index].delete && initialForm[index].sub_activity.length > 0) {
            initialForm[index].sub_activity.map((i, sub_index) => {
                if (!i.delete && subIndex !== sub_index && i?.weight_decimal) {
                    console.log(i.weight_decimal)
                    temp += Math.round(parseFloat(i?.weight_decimal) * 100) / 100
                    weight += Math.round(parseFloat(i?.weight_decimal) * 100) / 100
                }
            })
        }
        if (value) {
            weight += Math.round(parseFloat(value) * 100) / 100
        }
        else {
            weight = temp
        }
        setInitialForm(produce((draft) => { draft[index]["weight_decimal"] = Math.round(parseFloat(weight) * 100) / 100 ? Math.round(parseFloat(weight) * 100) / 100 : 0 }))
        setInitialForm(produce((draft) => { draft[index]["weight"] = parseInt(weight) ? parseInt(weight) : 0 }))
    }

    const handleSubmit = async (e) => {
        try {
            setLoading(true)
            setEditBoolean(false)
            e.preventDefault()
            setIsOpen(false);
            await fetchEditConstructionSetupInit({ value: initialForm, name: { name: chkName ? chkName : listSetupAtom?.name } })
            toast.success("Updated the checklist")
            setBtnDisable(true)
        }
        catch (err) {
            console.log(err)
            toast.error("Failed to update checklist")
        }
        finally {
            setLoading(false)
        }
    }

    // const handleSubmitDraft = async (e) => {
    //     try {
    //             console.log('initialForm', initialForm)
    //             e.preventDefault()
    //             setIsOpen(false);
    //             await fetchEditConstructionSetupInit({value: initialForm, draft: true})
    //             toast.success("Updated the template")
    //             setBtnDisable(true)
    //         }

    //     catch (err) {
    //         toast.error("Unable to update template")
    //     }
    // }

    const addActivity = () => {
        setBtnDisable(false)
        setEditBoolean(true)
        let newSubForm = {
            activity: '', part_id: '', weight: "", weight_decimal: ''
        }
        let newForm = {
            activity: '', part_id: '', weight: "", weight_decimal: '', sub_activity: [newSubForm], showSub: true
        }
        setInitialForm(produce((draft) => { draft.push(newForm) }))
    }

    const addSub = (index, bool, from = "addmore", data) => {
        setBtnDisable(false)
        if (from === "buttonbool") {
            if (!handleShowAddMore(data).length > 0) {
                setInitialForm(produce((draft) => { draft[index].showSub = true }))
            }
            else {
                setInitialForm(produce((draft) => { draft[index].showSub = !bool }))
            }
            let temp = [...initialForm]
            if (editBoolean) {
                if (temp[index].sub_activity.length === 0 || !handleShowAddMore(data).length > 0) {
                    let newSubForm = {
                        activity: '', part_id: '', weight: "", weight_decimal: ''
                    }
                    setInitialForm(produce((draft) => { draft[index].sub_activity.push(newSubForm) }))
                }
                return
            }
        }
        else {
            if (editBoolean) {
                let newSubForm = {
                    activity: '', part_id: '', weight: "", weight_decimal: ''
                }
                // temp[index].sub_activity.push(newSubForm)
                setInitialForm(produce((draft) => { draft[index].sub_activity.push(newSubForm) }))
                return
            }
        }
    }

    const deleteActivity = (index) => {
        if (editBoolean) {
            setBtnDisable(false)
            if (initialForm[index].activity_id) {
                setInitialForm(produce((draft) => { draft[index]["delete"] = true }))
            }
            else {
                setInitialForm(produce((draft) => { draft[index]["delete"] = true }))
            }
        }

    }

    const removeSub = (index, subIndex) => {
        prtWeight(index, subIndex)
        if (editBoolean) {
            setBtnDisable(false)
            if (initialForm[index].sub_activity[subIndex].activity_id) {
                setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex]["delete"] = true }))
            }
            else {
                setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex]["delete"] = true; draft[index].sub_activity.splice(subIndex, 1) }))
            }
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
    };

    const handleShowAddMore = (data) => {
        let temp = false
        let tempArr = []
        data.map((form) => {
            if (form?.delete === true) {
                temp = false
            }
            else {
                temp = true
                tempArr.push(form)
            }
        })
        return tempArr
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    // const handleChecklistName = async() => {
    //     setNameOpen(false)
    //     try{
    //         await fetchEditConstructionSetupInit({value: initialForm, name: {name: chkName ? chkName : listSetupAtom?.name}})
    //         toast.success("Updated Checklist Name")
    //     }
    //     catch(err){
    //         console.log(err)
    //         toast.error("Something went wrong")
    //     }
    // }

    const handleCheckSubLength = (data) => {
        let result = false
        let temp = []
        data.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        if (temp.length > 1) {
            result = true
        }
        return result
    }

    return (
        <div className="w-full">
            <div className="flex justify-between border-b-px border-gray-200">
                <div className='flex'>
                    <p className="font-bold mt-8 ml-5 mb-8">{project_name?.name} - CP Checklist</p>
                    {/* {!isNameOpen ?
                        <div className='flex'>
                            <p className="font-bold mt-8 ml-5 mb-8">{project_name?.name} - {listSetupAtom?.name} - Rev {listSetupAtom.revision}</p>
                            <MdModeEditOutline className='cursor-pointer mt-8 text-xl ml-4' onClick={() => setNameOpen(true)} />
                        </div>
                    :
                        <div className='flex font-bold'>
                            <p className="mt-8 ml-5 mb-8">{project_name?.name} - </p>
                            <input type='text' className='border px-3 py-2 my-6 mx-2' defaultValue={listSetupAtom?.name} onChange={(e) => {setBtnDisable(false); setChkName(e.target.value)}} />
                            <p className='mt-8 mb-8'> - Rev {listSetupAtom.revision}</p>
                            <HiSaveAs className='cursor-pointer mt-8 text-xl ml-4 text-red-550' onClick={handleChecklistName}/>
                        </div>
                    } */}
                </div>
                <div className="flex pr-3">
                    <div className='flex items-center space-x-4 mx-3'>
                        <p className='text-base font-medium'>Weight Percent : </p>
                        <p className={`${weightCount === 100 ? "text-green-500" : "text-red-500"} text-base font-medium`}>{weightCount} %</p>
                    </div>
                    <AddFormButton onClick={addActivity} />
                    {editBoolean ?
                        <button className="h-6 mt-6 mr-6 border-none rounded-3xl bg-red-550 text-white btn" disabled={btnDisable || weightCount !== 100} style={{ width: '96px' }} onClick={openModal}>Save</button>
                        :
                        <button className="h-6 mt-6 mr-6 border-none rounded-3xl bg-red-550 text-white btn" style={{ width: "96px" }} onClick={() => setEditBoolean(true)}>Edit</button>
                    }
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Complete CP"
            >
                <div className='flex justify-between'>
                    <h3 className="text-lg font-bold mt-2">Do you want to save template?</h3>
                    <VscClose className='cursor-pointer ' onClick={closeModal} style={{ width: '40px', height: '40px' }} />
                </div>
                <p className="py-4">The template will be saved and applied to all tracker types.<br />if there are changes to this checklist after data is captured <br />through SURYA Tab, it might impact data validation<br /> reporting and might require additional work to re-enter<br />new data</p>
                <hr />
                <div className='text-right mt-6 mb-2'>
                    <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                    <button className='btn bg-red-550 border-none rounded-3xl' onClick={handleSubmit} >Yes, Save</button>
                </div>
            </Modal>
            <div className='overflow-x-none overflow-y-auto w-full' style={{ height: 'calc(100vh - 169px)' }}>
                {loading ?
                    <div style={{ marginTop: "20%", marginLeft: "47%" }}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                    :
                    initialForm?.length > 0 ?
                        <form onSubmit={handleSubmit}>
                            {initialForm?.map((form, index) => {
                                if (!form.delete) {
                                    return (
                                        <div key={index} className={`${!editBoolean ? "bg-gray-100" : "white"}`}>
                                            <div className='flex' style={{ width: "calc(100vw - 450px)" }}>
                                                <p className='mt-6 ml-4'>{index + 1}</p>
                                                <div className='ml-4 mt-6 mr-4' style={{ width: "60%" }}>
                                                    <label>
                                                        Activity
                                                        <div>
                                                            <InputBox disabled={!editBoolean} rounded='rounded-xl' mb='3' mr='0' width='100%' name='activity' placeholder='Enter Activity' value={form.activity} onChange={e => handleFormChange(e, index)} />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='mt-6 mr-4' style={{ width: "60%" }}>
                                                    <label>
                                                        <div className='flex'>
                                                            <p>
                                                                Unit of count
                                                            </p>
                                                            <span data-tip data-for="Linked" className='ml-2 mt-1' >
                                                                <AiOutlineInfoCircle />
                                                            </span>
                                                        </div>

                                                        <ReactTooltip backgroundColor='black' place="bottom" clickable effect="solid" id="Linked">
                                                            <div className='m-3'>
                                                                <p>Choose a part category that best matches the activity <br />chosen. This will be used for counting quantities of <br />install.</p>
                                                                <p className='mt-2 mb-2'>E.g,</p>
                                                                <div className='flex'>
                                                                    <FaRegHandPointRight />
                                                                    <p className='ml-2'>Surveying added as Sub-activity can be linked to "Piles".<br />This way progress can be measured by number of<br />piles for which surveying is completed</p>
                                                                </div>
                                                                <div className='flex mt-1'>
                                                                    <FaRegHandPointRight />
                                                                    <p className='ml-2'>Tracker BOMming added as Sub-activity can be<br />linked to "BOM Fasteners". This way progress can be<br />measured by number of BOM Fasteners swaged in<br />a tracker</p>
                                                                </div>
                                                                <div className='flex mt-1'>
                                                                    <FaRegHandPointRight />
                                                                    <p className='ml-2'>Tracker Distribution added as Activity can be<br />linked to "Tracker" in general. This way progress<br />can be measured by number of Trackers for which<br />materials are distributed</p>
                                                                </div>
                                                                <div className='text-right'>
                                                                    <button type="button" className='text-red-550 font-bold text-sm mt-3'>GOT IT</button>
                                                                </div>
                                                            </div>
                                                        </ReactTooltip>

                                                        <select disabled={!editBoolean} className={`appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-3 leading-tight`} style={{ width: '100%', backgroundColor: !editBoolean ? "#f0f0f0" : "white" }} name="part_id" placeholder='Enter Linked Type' value={form?.part_id?.toString()} onChange={e => {
                                                            setBtnDisable(false)
                                                            setInitialForm(produce((draft) => { draft[index][e.target.name] = parseInt(e.target.value) }))
                                                            // let data = [...initialForm]
                                                            // data[index][e.target.name] = parseInt(e.target.value)
                                                            // setInitialForm(data)
                                                        }}>
                                                            <option hidden>Choose unit of count</option>
                                                            {listPartsAtom.map((i) => {
                                                                return <>
                                                                    <option value={i.id}>{i.part_name}</option>
                                                                </>
                                                            })}
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className='mt-6 w-1/3'>
                                                    <label>
                                                        <div className='flex'>
                                                            <p>Weight</p>
                                                            <span data-tip data-for="weight" className='ml-2 mt-1' >
                                                                <AiOutlineInfoCircle />
                                                            </span>
                                                        </div>
                                                        <ReactTooltip clickable place='top' backgroundColor='black' id="weight" effect='solid'>
                                                            <div className='m-3'>
                                                                <p>Assign weights according to the quantum of work that<br />would take to complete the respective activity / sub-<br />activity in a tracker</p>
                                                                <p className='mt-2 mb-2'>E.g,</p>
                                                                <div className='flex'>
                                                                    <FaRegHandPointRight />
                                                                    <p className='ml-2'>Piling added as Activity can be given a weight of<br />20% of total work to be done on a tracker.<br />Surveying added as Sub-activity to piling could<br />probably account to 5% of that 20%</p>

                                                                </div>
                                                                <div className='text-right'>
                                                                    <button type="button" className='text-red-550 font-bold text-sm mt-3'>GOT IT</button>
                                                                </div>
                                                            </div>

                                                        </ReactTooltip>
                                                        <div className='flex'>
                                                            <InputBox disabled type="number" rounded='rounded-l-xl' mb='3' mr='0' width='70%' name='weight_decimal' placeholder='wt' value={form?.weight_decimal} onChange={e => {
                                                                // setInitialForm(produce((draft) => { draft[index][e.target.name] = Math.round(parseFloat(e.target.value) * 100) / 100 ? Math.round(parseFloat(e.target.value) * 100) / 100 : null }))
                                                                // setInitialForm(produce((draft) => { draft[index]["weight"] = parseInt(e.target.value) ? parseInt(e.target.value) : 0 }))
                                                            }} />
                                                            <InputBox disabled={true} rounded='rounded-r-xl' mb='3' mr='0' width='75px' value={"%"} />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div>
                                                    {handleCheckSubLength(initialForm) ?
                                                        // <p className={`${editBoolean ? "cursor-pointer" : "cursor-default"} ml-6`} style={{ marginTop: "75px", width: '20px', height: '25px' }} onClick={() => deleteActivity(index)} >D</p>
                                                        <AiTwotoneDelete className={`${editBoolean ? "cursor-pointer" : "cursor-default"} ml-6`} style={{ marginTop: "75px", width: '20px', height: '25px' }} onClick={() => deleteActivity(index)} />
                                                        :
                                                        <p className={`ml-6`} style={{ marginTop: "75px", width: '20px', height: '25px' }} ></p>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <div className='flex'>
                                                    <div>
                                                        {form.showSub && form.sub_activity.map((subForm, subIndex) => {
                                                            if (!subForm.delete) {
                                                                return (
                                                                    <div key={subIndex} className="ml-4 flex">
                                                                        <p className='w-[55px] pt-7 pl-2'>{index + 1} . {subIndex + 1}</p>
                                                                        <div className='flex' style={{ width: "calc(100vw - 505px)" }}>
                                                                            <div className='mt-6' style={{ width: "75px" }}>
                                                                                <i style={{ width: '66px', border: 'solid gray', borderWidth: '0 0px 2px 0', display: 'inline-block', transform: 'rotate(90deg)', margin: '0px 14px 14px 0px' }}></i>
                                                                                <i style={{ width: '12px', border: 'solid gray', borderWidth: '0 0px 2px 0', display: 'inline-block', margin: '0px 0 0px -48px' }}></i>
                                                                            </div>
                                                                            <div className='mr-4' style={{ width: "calc(54% - 75px)" }}>
                                                                                <label >
                                                                                    <InputBox disabled={!editBoolean} rounded='rounded-xl' width='100%' name="activity" placeholder='Enter Sub Activity' value={subForm.activity} onChange={e => handleSubFormChange(e, index, subIndex)} />
                                                                                </label>
                                                                            </div>
                                                                            <div className='mr-4' style={{ width: "54%" }}>
                                                                                <label>
                                                                                    <select disabled={!editBoolean} className={`mt-4 mr-4 py-3 px-4 border border-gray-300 rounded-xl`} style={{ width: '100%', backgroundColor: !editBoolean ? "#f0f0f0" : "white" }} name="part_id" placeholder='Choose unit of count' value={subForm.part_id} onChange={e => {
                                                                                        setBtnDisable(false)
                                                                                        setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex][e.target.name] = parseInt(e.target.value) }))
                                                                                        // let data = [...initialForm]
                                                                                        // data[index].sub_activity[subIndex][e.target.name] = parseInt(e.target.value)
                                                                                        // setInitialForm(data)
                                                                                    }}>
                                                                                        <option hidden>Choose unit of count</option>
                                                                                        {listPartsAtom.map((i) => {
                                                                                            return <>
                                                                                                <option value={i?.id}>{i?.part_name}</option>
                                                                                            </>
                                                                                        })}
                                                                                    </select>
                                                                                </label>
                                                                            </div>
                                                                            <div className='flex' style={{ width: "30%" }}>
                                                                                <InputBox disabled={!editBoolean} type="number" rounded='rounded-l-xl' mb='3' mr='0' width='70%' name='weight_decimal' placeholder='wt' value={subForm?.weight_decimal} onChange={e => {
                                                                                    prtWeight(index, subIndex, e.target.value)
                                                                                    setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex][e.target.name] = Math.round(parseFloat(e.target.value) * 100) / 100 ? Math.round(parseFloat(e.target.value) * 100) / 100 : null }))
                                                                                    // setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex][e.target.name] = parseInt(e.target.value) ? parseInt(e.target.value) : 0 }))
                                                                                    setInitialForm(produce((draft) => { draft[index].sub_activity[subIndex]["weight"] = parseInt(e.target.value) ? parseInt(e.target.value) : 0 }))
                                                                                }} />
                                                                                <InputBox disabled={true} rounded='rounded-r-xl' mb='3' mr='0' width='75px' value={"%"} />
                                                                            </div>

                                                                            <div>
                                                                                {handleCheckSubLength(form?.sub_activity) ?
                                                                                    // <p className={`mt-7 ml-6`} style={{ width: '20px', height: '25px' }} onClick={() => removeSub(index, subIndex)}>D</p>
                                                                                    <AiTwotoneDelete className={`mt-7 ml-6`} style={{ width: '20px', height: '25px' }} onClick={() => removeSub(index, subIndex)} />
                                                                                    :
                                                                                    <p className={`mt-7 ml-6`} style={{ width: '20px', height: '25px' }} ></p>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                        {initialForm[index].activity ?
                                                            <div className='flex justify-between' style={{ width: "95%" }}>
                                                                <div className="ml-6 mt-1 cursor-pointer text-blue-400 flex" onClick={() => addSub(index, form.showSub, "buttonbool", form.sub_activity)}>
                                                                    <HiOutlineBars3BottomLeft className='mt-1' style={{ width: '15px', height: '15px' }} />
                                                                    <p className='ml-1' style={{ width: "200px" }}>Sub-activity</p>
                                                                </div>
                                                                {form.sub_activity.length > 0 ?
                                                                    form.showSub && handleShowAddMore(form.sub_activity).length > 0 ?
                                                                        <div className={`my-2 ${editBoolean ? "cursor-pointer" : null} text-right`} onClick={() => addSub(index)}>
                                                                            + Add More
                                                                        </div>
                                                                        :
                                                                        null
                                                                    : null}
                                                            </div>
                                                            :
                                                            <div className='flex justify-between' style={{ width: "250px" }}>
                                                                <div className="ml-6 mt-1 text-gray-400 flex">
                                                                    <HiOutlineBars3BottomLeft className='mt-1' style={{ width: '15px', height: '15px' }} />
                                                                    Sub-activity
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </form>
                        :
                        <div className='flex justify-center' style={{ marginTop: "20%" }}>
                            <div>
                                <AddFormButton disabled={true} className="ml-16" />
                                <p className='text-gray-400 font-semibold mt-4'>Click + icon to add an Activity</p>
                            </div>
                        </div>
                }
            </div>

        </div>
    )
}

export default ConstructionActivity