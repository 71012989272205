import produce from 'immer'
import React, { useEffect, useRef, useState } from 'react'
import Datetime from 'react-datetime'
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import "react-datetime/css/react-datetime.css"
import TimePicker from 'react-time-picker';
import { SlCalender } from "react-icons/sl"
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import DropdownMenu, { SubDropdownMenu } from './multiDropdown'
import { BsThreeDotsVertical } from "react-icons/bs"
import { TiTick } from "react-icons/ti"
import { DateFormat } from '../../components/dateFormat';
import { MdKeyboardArrowRight } from "react-icons/md"
import { blockInvalidChar } from '../../utils/blockIndividualChar';
import TextareaAutosize from 'react-textarea-autosize'
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import ActionDropDown from '../../components/actionDropDown'
import Dropdown from '../../components/dropdown'

function HandleDateTimeSplit({ data }) {
    let hour = new Date(data * 1000).getHours()
    let mins = new Date(data * 1000).getMinutes()
    let meridian
    if (hour > 12) {
        meridian = "PM"
        hour = hour - 12
    }
    else {
        meridian = "AM"
    }
    if (mins - 9 > 0) {
        mins = mins
    }
    else {
        mins = `0${mins}`
    }
    return (
        <div>
            <DateFormat date={data * 1000} />
            <p>{`${hour}:${mins} ${meridian}`}</p>
        </div>
    )
}

function titleName() {
    return (
        <div className='flex'>
            <p>Inter-site movement</p>
            <MdKeyboardArrowRight className='text-xl' />
        </div>
    )
}

function NestedTable({
    className,
    autoScope,
    laydownId,
    bom_id,
    nestedData,
    addEntry,
    destinationLaydown,
    onIssuedChange,
    onActionChange,
    onDesitantionChange,
    onCommentsChange,
    onDateChange,
    onAdjustmentChange,
    handleSaveEdit,
    handleDeleteInventory,
    bomLayoutData }) {

    const [data, setData] = useState({ issued: null, received_quantity: null, purpose_code: autoScope ? 4 : 1, destination: null, comments: "" })
    const [nestedDataArr, setNestedDataArr] = useState(nestedData)
    const [logDate, setLogDate] = useState(new Date())
    const [logTime, setLogTime] = useState(`${new Date().getHours()}:${new Date().getMinutes()}`)
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [editArr, setEditArr] = useState({ index: null, data: {}, destination: null })
    const tableRef = useRef(null)

    useEffect(() => {
        console.log(logDate, logTime)
        var time = new Date(logDate);
        var originalFormat = `${logTime}:00`
        // set the correct time
        var timeValues = originalFormat?.split(':').map((element) => Number(element));
        time.setHours(timeValues[0], timeValues[1], timeValues[2]);
        console.log('correct time values:', time.toString());

        // get epoch value
        var epoch = time.valueOf();
        console.log('epoch:', epoch)
        onDateChange(epoch)
    }, [logDate, logTime])

    useEffect(() => {
        setNestedDataArr(nestedData)
    }, [nestedData, bomLayoutData])

    const handleCheckAction = (data) => {
        let temp
        if (data === 0) {
            temp = "Delivery"
        }
        // if (data === 1) {
        //     temp = "Issue to site"
        // }
        if (data === 2) {
            temp = "Intersite movement issued"
        }
        if (data === 3) {
            temp = "Intersite movement received"
        }
        if (data === 4) {
            temp = "Stock adjustment"
        }
        return temp
    }

    const datePickerStyle = ({ className, children }) => {
        return (
            <div>
                <calendarContainer className={`${className}`}>
                    <div style={{ position: "relative" }}>
                        {children}
                    </div>
                </calendarContainer>
            </div>
        )
    }

    const handleNanCheck = (data) => {
        let temp = data
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const handleFindDestinationName = (data) => {
        let temp
        temp = destinationLaydown.find(o => o.id === data)
        return temp?.name
    }

    const handleDelete = async (j, index) => {
        handleDeleteInventory({ laydown_id: laydownId, data: [{ bom_id: bom_id, delete: true, inventory_log_id: j.inventory_log_id }] })
        setNestedDataArr(produce((draft) => { draft[index]["delete"] = true }))
    }

    const handleEditOnClick = (j, index) => {
        setLogTime(`${new Date(j.time_epoch * 1000).getHours()}:${new Date(j.time_epoch * 1000).getMinutes()}`)
        setLogDate(new Date(j.time_epoch * 1000))
        setEditArr(produce((draft) => { draft.index = j?.inventory_log_id; draft.data = j }))
    }

    const handleSave = () => {
        var time = new Date(logDate);
        var originalFormat = `${logTime}:00`
        // set the correct time
        var timeValues = originalFormat?.split(':').map((element) => Number(element));
        time.setHours(timeValues[0], timeValues[1], timeValues[2]);

        // get epoch value
        var epoch = time.valueOf();
        onDateChange(epoch)
        if (epoch) {
            console.log(editArr)
            handleSaveEdit(editArr, epoch)
        }
        else {
            handleSaveEdit(editArr, new Date().getTime())
        }
    }

    const handleChangeAutoMovedQty = (e) => {
        let value = parseInt(e.target.value)
        if (parseInt(e.target.value) > 0) {
            setEditArr(produce((draft) => { draft.data.received_quantity = value }))
            setEditArr(produce((draft) => { draft.data.issued_quantity = null }))
        }
        if (parseInt(e.target.value) < 0) {
            setEditArr(produce((draft) => { draft.data.issued_quantity = (value * (-1)) }))
            setEditArr(produce((draft) => { draft.data.received_quantity = null }))
        }
    }

    const handleChangeAutoManageStock = (e) => {
        if (parseInt(e.target.value) < 0) {
            let value = parseInt(e.target.value) * (-1)
            setData(produce((draft) => { draft.issued = value }))
            onIssuedChange(value)
            setData(produce((draft) => { draft.received_quantity = null }))
            onAdjustmentChange(null)
        }
        if (parseInt(e.target.value) > 0) {
            let value = parseInt(e.target.value)
            setData(produce((draft) => { draft.received_quantity = value }))
            onAdjustmentChange(value)
            setData(produce((draft) => { draft.issued = null }))
            onIssuedChange(null)
        }
    }

    return (
        <div className={`w-[95%] max-h-[400px] ${className} overflow-auto m-auto`} ref={tableRef}>
            <table className='table table-compact relative z-0 w-full'>
                <thead>
                    {autoScope ?
                        <tr className='sticky z-40 top-0'>
                            <td className='font-bold normal-case bg-red-200 w-[14%]'>Date & Time</td>
                            <td className='font-bold normal-case bg-red-200 w-[14%]'>Delivery Reference</td>
                            <td className='font-bold normal-case bg-red-200 w-[14%]'>Delivered Qty</td>
                            <td className='font-bold normal-case bg-red-200 w-[14%]'>
                                <div className='flex space-x-2 items-center'>
                                    <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_bom_moved_qty`} />
                                    <p>Quantity</p>
                                </div>
                            </td>
                            <td className='font-bold normal-case bg-red-200 w-[30%]'>Action</td>
                            <td className='font-bold normal-case bg-red-200 w-[40%]'>Comment</td>
                            <td className='font-bold normal-case bg-red-200 w-[2%]'></td>
                        </tr>
                        :
                        <tr className='sticky z-40 top-0'>
                            <td className='font-bold normal-case bg-red-200 w-[6%]'>Date & Time</td>
                            <td className='font-bold normal-case bg-red-200 w-[12%]'>Delivery<br /> Reference</td>
                            <td className='font-bold normal-case bg-red-200 w-[11%]'>
                                <div className='flex space-x-2 items-center w-[13%]'>
                                    <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_bom_delivered_qty`} />
                                    <p>Delivered<br /> Quantity</p>
                                </div>
                            </td>
                            <td className='font-bold normal-case bg-red-200 w-[10%]'>
                                <div className='flex space-x-2 items-center'>
                                    <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_bom_issued_qty`} />
                                    <p>Issued<br /> Quantity</p>
                                </div>
                            </td>
                            <td className='font-bold normal-case bg-red-200 w-[17%]'>Action</td>
                            <td className='font-bold normal-case bg-red-200 w-[29%]'>Comment</td>
                            <td className='font-bold normal-case bg-red-200 w-[2%]'></td>
                        </tr>
                    }
                </thead>
                <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_bom_delivered_qty`}>
                    <div className=''>
                        <p>Delivered quantity is the total quantity of the part reported as </p>
                        <p>delivered while adding delivery records in Bill of materials page</p>
                    </div>
                </ReactTooltip>
                <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_bom_issued_qty`}>
                    <p>
                        {`Issued quantity are the entries made in this inventory log`}
                    </p>
                </ReactTooltip>
                <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_bom_moved_qty`}>
                    <p>
                        {`Enter positive or negative values for stock adjustments and enter quantities that is moved inter-site`}
                    </p>
                </ReactTooltip>
                {nestedDataArr.length === 0 && !addEntry ?
                    <tbody>
                        <tr>
                            <td colSpan={7} className='py-14'>
                                <div className='m-auto'>
                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                    <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    :
                    <tbody>
                        {nestedDataArr.map((j, index) => {
                            if (!j.delete) {
                                if (editArr.index === j.inventory_log_id) {
                                    return (
                                        <tr className='border border-2'>
                                            <td className='w-[2%]'>
                                                <div className='relative flex flex-col space-y-2'>
                                                    <DatePicker toggleCalendarOnIconClick popperPlacement='left-bottom' icon={<SlCalender className='cursor-pointer' />} dateFormat="dd-MMM-yyyy" onChange={setLogDate} selected={logDate} calendarContainer={datePickerStyle} className="py-1 w-[100px] cursor-pointer border border-gray-600" />
                                                    <TimePicker disableClock clearIcon onChange={setLogTime} value={logTime} className={"w-[90px]"} />
                                                </div>
                                            </td>
                                            <td className='py-4'>{j.delivery_reference ? j.delivery_reference : "-"}</td>
                                            {autoScope && <td className='py-4'>
                                                {j?.purpose_code === 0 ?
                                                    j?.received_quantity === 0 ? "-" : j?.received_quantity
                                                    :
                                                    j?.purpose_code === 3 ?
                                                        <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.received_quantity} disabled={editArr.data.issued_quantity} className='border w-[100%] px-1 py-1.5' onChange={e => { setEditArr(produce((draft) => { draft.data.received_quantity = parseInt(e.target.value) })) }} />
                                                        :
                                                        "-"
                                                }
                                            </td>
                                            }
                                            {!autoScope && (
                                                <td className='py-4 w-[12%]'>
                                                    {editArr.data.purpose_code === 4 || editArr.data.purpose_code === 3 ?
                                                        <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.received_quantity} disabled={editArr.data.issued_quantity} className='border w-[100%] px-1 py-1.5' onChange={e => { setEditArr(produce((draft) => { draft.data.received_quantity = parseInt(e.target.value) })) }} />
                                                        :
                                                        handleNanCheck(editArr.data?.received_quantity) === 0 && handleNanCheck(editArr.data?.issued_quantity) !== 0 ?
                                                            <p className='pl-2'>-</p>
                                                            :
                                                            <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.received_quantity} disabled={editArr.data.issued_quantity} className='border w-[100%] px-1 py-3' onChange={e => { setEditArr(produce((draft) => { draft.data.received_quantity = parseInt(e.target.value) })) }} />
                                                    }
                                                </td>
                                            )
                                            }
                                            {autoScope ?
                                                <td className='py-4'>
                                                    {editArr.data.purpose_code === 4 ?
                                                        <input type='number' defaultValue={j?.received_quantity ? j?.received_quantity : (j?.issued_quantity * (-1))} required className='border w-[100%] px-1 py-1.5' onChange={e => handleChangeAutoMovedQty(e)} />
                                                        :
                                                        editArr.data.purpose_code === 3 ?
                                                            "-"
                                                            :
                                                            editArr.data.purpose_code === 2 ?
                                                                <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.issued_quantity} required className='border w-[100%] px-1 py-1.5' onChange={e => setEditArr(produce((draft) => { draft.data.issued_quantity = parseInt(e.target.value) }))} />
                                                                :
                                                                "-"
                                                    }
                                                </td>
                                                :
                                                <td className='py-4'>
                                                    {editArr.data.purpose_code === 4 ?
                                                        handleNanCheck(editArr.data?.issued_quantity) === 0 && handleNanCheck(editArr.data?.received_quantity) !== 0 ? "-" : <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.issued_quantity} disabled={editArr.data.received_quantity} required className='border w-[100%] px-1 py-1.5' onChange={e => { setEditArr(produce((draft) => { draft.data.issued_quantity = parseInt(e.target.value) })) }} />
                                                        :
                                                        <input type='number' onKeyDown={blockInvalidChar} defaultValue={j?.issued_quantity} disabled={editArr.data.received_quantity} required className='border w-[100%] px-1 py-1.5' onChange={e => { setEditArr(produce((draft) => { draft.data.issued_quantity = parseInt(e.target.value) })) }} />
                                                    }
                                                </td>
                                            }
                                            {autoScope ?
                                                <td className='py-4 max-w-[17%]'>
                                                    <DropdownMenu tableRef={tableRef} title={editArr.data.purpose_code === 1 ? "Issue to site" : editArr.data.purpose_code === 4 ? "Stock Adjustments" : `Inter-site movement`}>
                                                        <ul tabIndex={0}>
                                                            {/* <li value={1} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setData(produce((draft) => { draft.purpose_code = 1; draft.destination = null; draft.received_quantity = null })); onActionChange(1); onDesitantionChange(null); onAdjustmentChange(null) }}>Issue to site</li> */}
                                                            <li value={4} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setEditArr(produce((draft) => { draft.purpose_code = 4; draft.destination = null; draft.received_quantity = null; draft.issued = null })); onActionChange(4); onDesitantionChange(null); onAdjustmentChange(null) }}>Stock Adjustments</li>
                                                            <li onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => { setShowSubMenu(false) }}>
                                                                <SubDropdownMenu tableRef={tableRef} title={titleName()} show={showSubMenu}>
                                                                    <ul>
                                                                        {destinationLaydown.map((i) => {
                                                                            return (
                                                                                <li className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl my-1' onClick={() => { setData(produce((draft) => { draft.purpose_code = 0; draft.destination = parseInt(i.id); draft.received_quantity = null; draft.issued = null })); onActionChange(0); onDesitantionChange(parseInt(i.id)); onAdjustmentChange(null) }}>
                                                                                    <div className='flex space-x-2'>
                                                                                        <TiTick className='text-green-500 mt-1' style={{ visibility: data.destination === i.id ? "" : "hidden" }} />
                                                                                        <p>{i.name}</p>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </SubDropdownMenu>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    {data.purpose_code === 0 &&
                                                        <p className='m-2'>To {handleFindDestinationName(data.destination)}</p>
                                                    }
                                                </td>
                                                :
                                                <td className='py-4 max-w-[17%]'>
                                                    <DropdownMenu tableRef={tableRef} title={editArr.data.purpose_code === 1 ? "Issue to site" : editArr.data.purpose_code === 4 ? "Stock adjustment" : "Intersite movement"}>
                                                        <ul tabIndex={0}>
                                                            {/* <li value={1} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setEditArr(produce((draft) => { draft.data.purpose_code = 1; draft.data.received_quantity = null })) }}>Issue to site</li> */}
                                                            <li value={4} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setEditArr(produce((draft) => { draft.data.purpose_code = 4 })) }}>Stock Adjustments</li>
                                                            <li onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => { setShowSubMenu(false) }}>
                                                                <SubDropdownMenu tableRef={tableRef} title={titleName()} show={showSubMenu}>
                                                                    <ul>
                                                                        {destinationLaydown.map((i) => {
                                                                            return (
                                                                                <li className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl my-1' onClick={() => { setEditArr(produce((draft) => { draft.data.purpose_code = 0; draft.destination = parseInt(i.id); draft.data.received_quantity = null })) }}>
                                                                                    <div className='flex space-x-2'>
                                                                                        <TiTick className='text-green-500 mt-1' style={{ visibility: editArr?.destination === i.id ? "" : "hidden" }} />
                                                                                        <p>{i.name}</p>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </SubDropdownMenu>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    {editArr.data.purpose_code === 0 && <p className='m-2'>To {handleFindDestinationName(editArr.destination)}</p>}
                                                    {editArr.data.purpose_code === 2 && <p className='m-2'>To {handleFindDestinationName(editArr.data.destination_laydown_id)}</p>}
                                                </td>
                                            }
                                            <td className='py-4'>
                                                <TextareaAutosize
                                                    minRows={1}
                                                    maxRows={5}
                                                    defaultValue={j?.comments}
                                                    className='w-full p-2 border focus:outline-none'
                                                    placeholder=''
                                                    onChange={e => setEditArr(produce((draft) => { draft.data.comments = e.target.value }))}
                                                />
                                            </td>
                                            <td className='py-4'>
                                                <p className='text-red-550 cursor-pointer' onClick={handleSave}>Save</p>
                                                <p className='cursor-pointer' onClick={() => setEditArr(produce((draft) => { draft.index = null }))}>Cancel</p>
                                            </td>
                                        </tr>
                                    )
                                }
                                else {
                                    return (
                                        <tr className='border border-2'>
                                            <td className='w-[12%]'>
                                                <HandleDateTimeSplit data={j?.time_epoch} />
                                            </td>
                                            <td>{j.delivery_reference ? j.delivery_reference : "-"}</td>
                                            {autoScope && (
                                                <td className='pl-3'>
                                                    {[0, 3].includes(j?.purpose_code) ?
                                                        j?.received_quantity === 0 ? "-" : j?.received_quantity
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                            )
                                            }
                                            {!autoScope && <td className='pl-3'>{j?.received_quantity === 0 ? "-" : j?.received_quantity}</td>}
                                            {autoScope ?
                                                <td className='pl-3'>
                                                    {j?.purpose_code === 4 ?
                                                        j?.received_quantity ? j?.received_quantity : (j?.issued_quantity * (-1))
                                                        :
                                                        j?.purpose_code === 3 ?
                                                            "-"
                                                            :
                                                            j?.purpose_code === 2 ?
                                                                j?.issued_quantity === 0 ? "-" : j?.issued_quantity
                                                                :
                                                                "-"
                                                    }
                                                </td>
                                                :
                                                <td className='pl-3'>
                                                    {j?.issued_quantity === 0 ? "-" : j?.issued_quantity}
                                                </td>
                                            }
                                            <td className='pl-2' style={{ textTransform: "capitalize" }}>
                                                <p>{handleCheckAction(j?.purpose_code)}</p>
                                                {j.purpose_code === 3 ? `From ${handleFindDestinationName(j.destination_laydown_id)}` : null}
                                                {j.purpose_code === 2 ? `To ${handleFindDestinationName(j.destination_laydown_id)}` : null}
                                            </td>
                                            <td>
                                                <TextareaAutosize
                                                    minRows={1}
                                                    maxRows={5}
                                                    value={j?.comments}
                                                    className='w-full '
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                {j?.purpose_code !== 0 && (
                                                    <div className='mx-3' >
                                                        <ActionDropDown
                                                            icon={<BsThreeDotsVertical />}
                                                            options={[{ name: "Edit" }, { name: "Delete" }]}
                                                            onChangeValue={e => {
                                                                if (e === "Edit") {
                                                                    handleEditOnClick(j, index)
                                                                }
                                                                if (e === "Delete") {
                                                                    handleDelete(j, index)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                        })
                        }
                        {addEntry ?
                            <tr className='border border-2'>
                                <td className='w-[1.5%]'>
                                    <div className='relative flex flex-col space-y-2'>
                                        <DatePicker toggleCalendarOnIconClick popperPlacement='left-bottom' icon={<SlCalender className='cursor-pointer' />} dateFormat="dd-MMM-yyyy" onChange={setLogDate} selected={logDate} calendarContainer={datePickerStyle} className="py-1 w-[98px] cursor-pointer border border-gray-600" />
                                        <TimePicker disableClock clearIcon onChange={setLogTime} value={logTime} className={"w-[90px]"} />
                                    </div>
                                </td>
                                <td className='py-4'>-</td>
                                {autoScope && <td className='py-4'>-</td>}
                                {!autoScope && (
                                    <td className='py-4'>
                                        {data.purpose_code === 4 ?
                                            <input required type='number' onKeyDown={blockInvalidChar} disabled={data.issued} className='border w-[100%] px-1 py-1.5' onChange={e => { setData(produce((draft) => { draft.received_quantity = parseInt(e.target.value) })); onAdjustmentChange(parseInt(e.target.value)) }} />
                                            :
                                            "-"
                                        }
                                    </td>
                                )}
                                {autoScope ?
                                    <td className='py-4'>
                                        {data?.purpose_code === 4 ?
                                            <input required type='number' className='border w-[80%] px-1 py-1.5' onChange={e => handleChangeAutoManageStock(e)} />
                                            :
                                            <input required min="0" type='number' onKeyDown={blockInvalidChar} className='border w-[80%] px-1 py-1.5' onChange={e => { setData(produce((draft) => { draft.issued = parseInt(e.target.value) })); onIssuedChange(parseInt(e.target.value)) }} />
                                        }
                                    </td>
                                    :
                                    <td className='py-4'>
                                        <input required min="0" type='number' onKeyDown={blockInvalidChar} disabled={data.received_quantity} className='border w-[100%] px-1 py-1.5' onChange={e => { setData(produce((draft) => { draft.issued = parseInt(e.target.value) })); onIssuedChange(parseInt(e.target.value)) }} />
                                    </td>
                                }
                                {autoScope ?
                                    <td className='py-4 max-w-[17%]'>
                                        <DropdownMenu tableRef={tableRef} title={data.purpose_code === 1 ? "Issue to site" : data.purpose_code === 4 ? "Stock Adjustments" : `Inter-site movement`}>
                                            <ul tabIndex={0}>
                                                {/* <li value={1} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setData(produce((draft) => { draft.purpose_code = 1; draft.destination = null; draft.received_quantity = null })); onActionChange(1); onDesitantionChange(null); onAdjustmentChange(null) }}>Issue to site</li> */}
                                                <li value={4} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setData(produce((draft) => { draft.purpose_code = 4; draft.destination = null; draft.received_quantity = data.received_quantity ? data.received_quantity : null; draft.issued = data.issued ? data.issued : null })); onActionChange(4); onDesitantionChange(data.destination); onAdjustmentChange(data.received_quantity) }}>Stock Adjustments</li>
                                                <li onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => { setShowSubMenu(false) }}>
                                                    <SubDropdownMenu tableRef={tableRef} title={titleName()} show={showSubMenu}>
                                                        <ul>
                                                            {destinationLaydown.map((i) => {
                                                                return (
                                                                    <li className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl my-1' onClick={() => { setData(produce((draft) => { draft.purpose_code = 0; draft.destination = parseInt(i.id); draft.received_quantity = data.received_quantity ? data.received_quantity : null; draft.issued = data.issued ? data.issued : null })); onActionChange(0); onDesitantionChange(parseInt(i.id)); onAdjustmentChange(null); onIssuedChange(data?.received_quantity ? data?.received_quantity : null) }}>
                                                                        <div className='flex space-x-2'>
                                                                            <TiTick className='text-green-500 mt-1' style={{ visibility: data.destination === i.id ? "" : "hidden" }} />
                                                                            <p>{i.name}</p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </SubDropdownMenu>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                        {data.purpose_code === 0 &&
                                            <p className='m-2'>To {handleFindDestinationName(data.destination)}</p>
                                        }
                                    </td>
                                    :
                                    <td className='py-4 max-w-[17%]'>
                                        <DropdownMenu tableRef={tableRef} title={data.purpose_code === 1 ? "Issue to site" : data.purpose_code === 4 ? "Stock Adjustments" : `Inter-site movement`}>
                                            <ul tabIndex={0}>
                                                <li value={1} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setData(produce((draft) => { draft.purpose_code = 1; draft.destination = null; draft.received_quantity = null })); onActionChange(1); onDesitantionChange(null); onAdjustmentChange(null) }}>Issue to site</li>
                                                <li value={4} className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl' onClick={() => { setData(produce((draft) => { draft.purpose_code = 4; draft.destination = null })); onActionChange(4); onDesitantionChange(null) }}>Stock Adjustments</li>
                                                <li onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => { setShowSubMenu(false) }}>
                                                    <SubDropdownMenu tableRef={tableRef} title={titleName()} show={showSubMenu}>
                                                        <ul>
                                                            {destinationLaydown.map((i) => {
                                                                return (
                                                                    <li className='cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-xl my-1' onClick={() => { setData(produce((draft) => { draft.purpose_code = 0; draft.destination = parseInt(i.id); draft.received_quantity = null })); onActionChange(0); onDesitantionChange(parseInt(i.id)); onAdjustmentChange(null) }}>
                                                                        <div className='flex space-x-2'>
                                                                            <TiTick className='text-green-500 mt-1' style={{ visibility: data.destination === i.id ? "" : "hidden" }} />
                                                                            <p>{i.name}</p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </SubDropdownMenu>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                        {data.purpose_code === 0 &&
                                            <p className='m-2'>To {handleFindDestinationName(data.destination)}</p>
                                        }
                                    </td>
                                }
                                <td className='py-4'>
                                    <TextareaAutosize
                                        minRows={1}
                                        maxRows={5}
                                        className='w-full p-2 border focus:outline-none'
                                        placeholder=''
                                        onChange={e => onCommentsChange(e.target.value)}
                                    />
                                </td>
                                <td className='py-4'></td>
                            </tr>
                            :
                            null
                        }
                    </tbody>
                }
            </table>
        </div >
    )
}

export default NestedTable
