import React from 'react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import axios from 'axios'
import { decodeJWT } from '../../utils/jwt'
import LoginContainer from '../../components/loginContainer'
import { useAtom } from 'jotai'
import userAtom from '../../atoms/userAtom'
import { toast } from 'react-hot-toast'
import { AiOutlineMobile } from "react-icons/ai"
import { VscEye, VscEyeClosed } from "react-icons/vsc"

function Login() {
    const navigate = useNavigate()

    const [, setUser] = useAtom(userAtom)

    const { register, handleSubmit } = useForm()

    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)


    const onSubmit = async (data) => {
        try {
            setBtnLoading(true)
            setLoading(true);
            let tempData = {
                email: data.email.toLowerCase(),
                password: data.password
            }
            let loginResp = await axios.post(`/auth/login`, tempData);
            if (loginResp.status === 200) {
                let decoded = decodeJWT(loginResp.data["access_token"]);
                Cookies.set("access_token", loginResp.data["access_token"], { expires: new Date(decoded.exp * 1000) });
                Cookies.set("refresh_token", loginResp.data["refresh_token"], { expires: new Date(decoded.exp * 1000) });
                localStorage.setItem("Theme", 'light')
                localStorage.setItem("project_permissions", JSON.stringify(loginResp.data.project_permissions))
                if (loginResp?.data?.user?.last_login === null) {
                    setUser({
                        loggedIn: false,
                        data: decoded,
                    })
                    navigate("/auth/reset")
                    // window.location.href = "/auth/reset"
                }
                else {
                    setUser({
                        loggedIn: true,
                        data: decoded,
                    })
                    window.location.href = "/"
                }
            }
            else {
                toast.error("Something went wrong")
            }
        } catch (err) {
            console.log(err);
            toast.error("Invalid-Credentials")
        } finally {
            setLoading(false);
            setBtnLoading(false)
        }
    }

    return (
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            <LoginContainer />
            <div>
                <div>
                    <img src='/suryalogo.svg' alt='' width="230px" height="50px" className="mb-5" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label>
                            <p className='text-gray-500'>Email ID</p>
                            <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-9 ' required style={{ width: '432px', height: '64px' }} type="email" placeholder="jhon@surya123" {...register('email')} />
                        </label>
                        <div className='relative'>
                            <p className='text-gray-500'>Password</p>
                            <p className='absolute' style={{ top: "63px", right: "25px" }}>
                                {showPassword ?
                                    <VscEyeClosed className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)} />
                                    :
                                    <VscEye className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)} />
                                }
                            </p>
                            <input type={`${showPassword ? "text" : "password"}`} className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4' style={{ width: '432px', height: '64px' }} placeholder="Enter Password" {...register('password')} />
                        </div>
                        <p className='text-right cursor-pointer font-semibold' style={{ marginTop: '7px', marginBottom: '29px' }} onClick={() => navigate("/forgetPassword")}>Forgot Password?</p>
                        {!btnLoading ?
                            <input type="submit" value="Log in" className='border-none rounded-full bg-red-550 text-white btn' style={{ width: '432px', height: '59px' }} />
                            :
                            <button className='border-none rounded-full bg-red-550 text-white btn loading' style={{ width: '432px', height: '59px' }}>
                                Logging In
                            </button>
                        }
                    </div>
                </form>
                <div className='flex justify-center m-auto mt-20'>
                    <div className='flex cursor-pointer' onClick={() => window.location.replace("https://tablet.suryaweb.app/install")}>
                        <AiOutlineMobile className='text-gray-500 mt-0.5 mr-1 text-xl' style={{ rotate: "270deg" }} />
                        <p className=' underline underline-offset-2 text-gray-500'>Download Tablet App</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login