import axios from "axios";
import { atom } from "jotai";
import produce from "immer"

const qcAtom = atom({
    typeMode: {},
    trackerDatas: [],
    pierLegends: [],
    trackerDataPierTT: {},
    mappedChecklist: {},
    checklistDeatils: {}
})

export const fetchTypeTracker = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/tracker_type_map_type`)
        set(qcAtom, produce((draft) => { draft.typeMode = res.data }))
    }
)

export const updateMapType = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/update_tracker_type_map_type?map_type=${args?.map_type}&tracker_type_id=${args?.tracker_type_id}&checklist_type=${args?.checklist_type}`)
        let res = await axios.get(`/projects/tracker_type_map_type`)
        set(qcAtom, produce((draft) => { draft.typeMode = res.data }))
    }
)

export const fetchMapchecklist = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/fetch_checklist_mappings`);
        set(qcAtom, produce((draft) => { draft.mappedChecklist = res.data }))
    }
)

export const fetchChecklist = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/fetch_checklist?checklist_id=${args?.checklistId}`)
        set(qcAtom, produce((draft) => { draft.checklistDeatils = res.data }))
    }
)

export const fetchTrackerTypeDataAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/fetch_pier_tt_parts?tracker_type_id=${args?.tracker_type_id}`)
        set(qcAtom, produce((draft) => { draft.trackerDataPierTT = res.data }))
    }
)

export const fetchTrackerTypes = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`tracker/get_tracker_types`)
        set(qcAtom, produce((draft) => { draft.trackerDatas = res.data }))
    }
)

export const fetchPierLegends = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/pierslenged`)
        set(qcAtom, produce((draft) => { draft.pierLegends = res.data }))
    }
)

export const createUpdateDeleteQcChecklist = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/qcchecklist`, args?.data)
        set(qcAtom, produce((draft) => { draft.checklistDeatils = res.data }))
        console.log(args)
        if (args?.projectLevel) {
            if (args?.itr) {
                if (res?.status === 200 && res?.data?.id) {
                    await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&itr=True`)
                }
            }
            else {
                console.log(res)
                if (res?.status === 200 && res?.data?.id) {
                    await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&all=True`)
                    let fetchRes = await axios.get(`/projects/fetch_checklist_mappings`);
                    set(qcAtom, produce((draft) => { draft.mappedChecklist = fetchRes.data }))
                    let mapRes = await axios.get(`/projects/tracker_type_map_type`)
                    set(qcAtom, produce((draft) => { draft.typeMode = mapRes.data }))
                }
            }
        }
        else {
            if (args?.checklist_map_type === 1) {
                //individual checklist type
                if (args?.applyStatus) {
                    await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&tracker_type_id=${args?.tracker_type_id}&all=true`)
                    let fetchRes = await axios.get(`/projects/fetch_checklist_mappings`);
                    set(qcAtom, produce((draft) => { draft.mappedChecklist = fetchRes.data }))
                }
                else {
                    if (args?.tab === "piers") {
                        await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&tracker_type_id=${args?.tracker_type_id}&pier_part_id=${args?.part_id}`)
                    }
                    else {
                        await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&tracker_type_id=${args?.tracker_type_id}&tt_part_id=${args?.part_id}`)
                    }
                }
            }
            else {
                await axios.post(`/projects/mapping_checklist?checklist_id=${res?.data?.id}&checklist_type=${args?.tab}&tracker_type_id=${args?.tracker_type_id}`)
            }
        }
        let mappedRes = await axios.get(`/projects/fetch_checklist_mappings`);
        set(qcAtom, produce((draft) => { draft.mappedChecklist = mappedRes.data }))
    }
)

export default qcAtom