import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { checkHasRevisedProjectId } from './shared/checkHasRevisedProjectId'

const ProjectSelect = ({ onChange, hidden, value, placeholder }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [filterProject, setFilterProject] = useState()
    const dropdownRef = useRef(null)

    useEffect(() => {
        setFilterProject(value)
    }, [])

    const handleFocus = () => {
        setIsOpen(!isOpen)
    }

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
            setFilterProject(value)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleOnClick = async (data) => {
        let projectId = await checkHasRevisedProjectId(data)
        setSelectedItem(projectId)
        onChange(projectId)
        setIsOpen(false)
    }

    const formateValue = () => {
        let temp = "Select a Project"
        if (localStorage.getItem("project_id")) {
            temp = `${value?.find((i) => i?.id === parseInt(localStorage.getItem("project_id")))?.name}`
        }
        value?.map((i) => {
            if (i?.id === selectedItem) {
                temp = `${i?.name}`
            }
        })
        return temp
    }

    const formateValueNumber = () => {
        let temp
        if (localStorage.getItem("project_id")) {
            temp = `${value?.find((i) => i?.id === parseInt(localStorage.getItem("project_id")))?.project_number}`
        }
        value?.map((i) => {
            if (i?.id === selectedItem) {
                temp = `${i?.project_number}`
            }
        })
        return temp
    }

    const renderValue = (i) => {
        const { id, name } = i
        const isActive = selectedItem ? selectedItem : localStorage.getItem("project_id")
        return (
            <li
                key={id}
                className={`flex text-sm bg-white pl-3 font-medium ${parseInt(isActive) === i.id ? "text-white" : ""} hover:text-white hover:bg-red-300 cursor-pointer`}
                onClick={() => { handleOnClick(i) }}
                style={{ textTransform: "capitalize", backgroundColor: parseInt(isActive) === i.id ? "#F16253" : "" }}
            >
                <span className='mx-2 py-2 flex'>
                    <p className=''>{i?.project_number}_{name}</p>
                </span>
            </li>
        )
    }

    const handleSearch = (e) => {
        let quaryFilter = e.target.value
        var updateList = [...value]
        updateList = [...value].filter((item) =>
            `${item.project_number}_${item.name}`.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        setFilterProject(updateList)
    }

    return (
        <div className='relative flex' ref={dropdownRef}>
            <div>
                <label className='cursor-pointer'>
                    <input
                        type='text'
                        className="text-black border-x border-t w-[350px] min-w-[250px] rounded-t-2xl pt-2 pl-5 cursor-pointer font-semibold focus:outline-none transition-all text-ellipsis overflow-hidden pr-7"
                        placeholder={placeholder}
                        onClick={handleFocus}
                        value={formateValue()}
                        readOnly
                    />
                    <p className='text-black border-x border-b rounded-b-2xl text-xs pb-2 pl-5 pt-1'>{formateValueNumber()}</p>
                </label>

                {isOpen && (
                    <div className='absolute z-50 bg-white shadow border border-gray-300 ml-1 rounded-xl w-[335px] min-w-[235px]'>
                        <ul className='py-2 max-h-72 overflow-y-auto'>
                            <input type='search' placeholder={placeholder} className='border rounded-xl m-4 p-2 w-[280px] min-w-[180px]' onChange={handleSearch} />
                            {filterProject.map((i, index) => renderValue(i, index))}
                        </ul>
                    </div>
                )}
            </div>
            {isOpen ?
                <BsChevronUp onClick={handleFocus} className={`cursor-pointer text-black absolute right-3 top-5`} />
                :
                <BsChevronDown onClick={handleFocus} className={`cursor-pointer text-black absolute right-3 top-5`} />
            }
        </div>
    )

}

export default ProjectSelect