import React, { useEffect, useRef, useState } from 'react'
import NestedTable from '../nestedTable'
import { addLayoutInventory, autoManageLaydownDownload, deleteInventory, downloadLaydown, editInventory, fetchIndividualLayoutData, fetchInventoryBomLayout, fetchLaydownScopeDataAtom, fetchUpdateLayout } from '../../../atoms/layoutAtom'
import { useAtom } from 'jotai'
import toast from 'react-hot-toast'
import produce from 'immer'
import { MdDelete, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdModeEditOutline } from 'react-icons/md'
import { CgSoftwareDownload } from 'react-icons/cg'
import { BsChevronDown, BsChevronUp, BsFillFileEarmarkImageFill } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { RiEdit2Fill } from 'react-icons/ri'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiArrowBack } from "react-icons/bi"
import { fetchRemediateQty, getDeliveryReferenceAliasNameAtom } from '../../../atoms/mileStoneAtom'
import { useNavigate } from 'react-router-dom'

function InventoryLogTable(
    {
        manage,
        listLaydown,
        currentLaydown,
        setCurrentLaydown,
        setSelectedBlocks,
        inventoryLoader,
        setInventoryLoader,
        setOpenRemediateQty,
        setViewToggle,
        setInventryBomId,
        setConfirmationModal,
        setRemediateQtyBomDetails,
        showRightPanel,
        currentProject,
        formattedDate
    }
) {

    const navigate = useNavigate()
    const updateLaydownInputRef = useRef(null)

    const [bomLaydownLoader, setBomLaydownLoader] = useState(false)
    const [bomLaydownUpdateLoader, setBomLaydownUpdateLoader] = useState({ bool: false, mode: null })
    const [addEntry, setAddEntry] = useState({ boolean: false, data: [{ bom_id: null, purpose_code: 1, destination: null, comments: "", time: null, issued: null, received_quantity: null }] })
    const [editInventoryNameModal, setEditInventoryNameModal] = useState({ boolean: false, data: {} })
    const [expanded, setExpanded] = useState(-1)
    const [bomLaydownId, setBomLaydownId] = useState(null)
    const [showScopeTableQty, setShowScopeTableQty] = useState(false)
    const [showDescription, setShowDescription] = useState(null)

    const [, setUpdateLayout] = useAtom(fetchUpdateLayout)
    const [, setAddLayoutInventory] = useAtom(addLayoutInventory)
    const [, fetchInventryLogDeliveryQty] = useAtom(fetchRemediateQty)
    const [, setFetchBomInventory] = useAtom(fetchInventoryBomLayout)
    const [, setFetchDeleteInventory] = useAtom(deleteInventory)
    const [, setEditInventory] = useAtom(editInventory)
    const [, getDeliveryReferenceAliasName] = useAtom(getDeliveryReferenceAliasNameAtom)
    const [, setDownloadLaydown] = useAtom(downloadLaydown)
    const [, setFetchSingleLayout] = useAtom(fetchIndividualLayoutData)
    const [, setAutoManageLaydownDownload] = useAtom(autoManageLaydownDownload)
    const [, setFetchAutoInventoryLogTable] = useAtom(fetchLaydownScopeDataAtom)

    const handleClickOutside = (event) => {
        if (updateLaydownInputRef.current && !updateLaydownInputRef.current.contains(event.target)) {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDownloadLaydown = async () => {
        try {
            await setDownloadLaydown({ laydown_id: currentLaydown, laydownName: handleFindCurrentLaydownName(currentLaydown), project_name: currentProject, formattedDate: formattedDate, details: listLaydown.data })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleAutoManageLaydownDownload = async () => {
        try {
            await setAutoManageLaydownDownload({ laydown_id: currentLaydown, laydownName: handleFindCurrentLaydownName(currentLaydown), project_name: currentProject, formattedDate: formattedDate, details: listLaydown.data })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    function handleFindCurrentLaydownName() {
        let temp
        temp = listLaydown?.data?.find(o => o?.id === currentLaydown)?.name
        return temp
    }

    const onExitScope = () => {
        setCurrentLaydown(null)
        setSelectedBlocks({ bool: true, data: [], initSelectedBlocks: {} })
        setViewToggle("Inventory")
    }

    const handleOpenEditRemediateQty = async (data) => {
        try {
            setRemediateQtyBomDetails(data)
            getDeliveryReferenceAliasName()
            setInventryBomId(data?.bom_id)
            await fetchInventryLogDeliveryQty({ bom_id: data?.bom_id })
            setOpenRemediateQty(true)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleFindLaydownDetail = (data) => {
        let temp
        temp = listLaydown.data.find(o => o.id === data)
        return temp
    }

    const handleUpdateLaydownName = async (data) => {
        try {
            if (data && data !== handleFindLaydownDetail(editInventoryNameModal?.data)?.name) {
                await setUpdateLayout({
                    data: {
                        name: data,
                        latitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.latitude,
                        longitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.longitude,
                        id: editInventoryNameModal?.data
                    }
                })
                toast.success("Updated Successfully")
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to update layout")
        }
        finally {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    }

    const handleAddBomInventory = async () => {
        try {
            setBomLaydownUpdateLoader({ bool: true, mode: "save" })
            let temp = {}
            temp.bom_id = addEntry.data[0].bom_id
            temp.issued = addEntry.data[0].issued ? addEntry.data[0].issued : 0
            temp.received_quantity = addEntry.data[0].received_quantity ? addEntry.data[0].received_quantity : 0
            temp.purpose_code = addEntry.data[0].purpose_code
            temp.comments = addEntry.data[0].comments
            if (addEntry.data[0].time) {
                temp.time = Math.round(addEntry.data[0].time / 1000)
            }
            else {
                temp.time = Math.round(new Date().getTime() / 1000)
            }
            if (addEntry.data[0].purpose_code === 0) {
                temp.destination_laydown_id = addEntry.data[0].destination
            }
            // console.log(temp)
            await setAddLayoutInventory({ laydownId: currentLaydown, bomLaydownId: bomLaydownId, data: [temp], action: addEntry.data[0].purpose_code, destination: addEntry.data[0].destination, bom_id: temp.bom_id })
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: addEntry.data[0].bom_id, count: null, purpose_code: 1, destination: null, comments: "", time: "" }] }))
            await setFetchSingleLayout({ laydown_id: currentLaydown })
            await setFetchAutoInventoryLogTable({ laydown_id: currentLaydown })
            toast.success("Successfully created")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }
        finally {
            setBomLaydownUpdateLoader({ bool: false, mode: null })
        }
    }

    const handleDeleteInventory = async (laydown_id, data) => {
        try {
            setInventoryLoader(true)
            setBomLaydownUpdateLoader({ bool: true, mode: "save" })
            await setFetchDeleteInventory({ laydown_id: laydown_id.laydown_id, data: laydown_id.data })
            await setFetchBomInventory({ bom_id: addEntry.data[0].bom_id, laydown_id: laydown_id.laydown_id })
            await setFetchAutoInventoryLogTable({ laydown_id: currentLaydown })
            toast.success("Deleted Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setInventoryLoader(false)
            setBomLaydownUpdateLoader({ bool: false, mode: null })
        }
    }

    const handleOpenBomNestedTable = async (bomId) => {
        try {
            setBomLaydownLoader(true)
            setExpanded(bomId.bom_id)
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: bomId.bom_id, purpose_code: manage === 0 ? 1 : 4, destination: null, comments: "", time: "", issued: null, received_quantity: null }] }))
            setBomLaydownId(bomId.bom_laydown_id)
            await setFetchBomInventory({ bom_id: bomId.bom_id, laydown_id: currentLaydown })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleSaveEdit = async (data, epoch) => {
        try {
            setBomLaydownLoader(true)
            let temp = [
                {
                    bom_id: addEntry.data[0].bom_id,
                    purpose_code: data.data.purpose_code,
                    comments: data.data.comments,
                    time: Math.round(epoch / 1000),
                    issued: data.data.issued_quantity ? data.data.issued_quantity : 0,
                    received_quantity: data.data.received_quantity ? data.data.received_quantity : 0,
                    inventory_log_id: data.data.inventory_log_id
                }
            ]
            if (data.data.purpose_code === 0) {
                temp[0]["destination_laydown_id"] = data.destination
            }
            await setEditInventory({ data: temp, laydown_id: currentLaydown, inventory_log_id: data.data.inventory_log_id, bom_id: addEntry.data[0].bom_id })
            await setFetchSingleLayout({ laydown_id: currentLaydown })
            await setFetchAutoInventoryLogTable({ laydown_id: currentLaydown })
            toast.success("Updated Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleCheckBomInventorySave = () => {
        let temp = true
        let data = addEntry.data[0]
        if (data.purpose_code === 4) {
            if (data.issued || data.received_quantity) {
                temp = false
            }
        }
        else {
            if (data.issued) {
                temp = false
            }
        }
        if (manage === 0) {
            return temp
        }
        else {
            console.log(data)
            if ((data?.destination && data?.issued) || (data.purpose_code === 4 && (data?.issued || data?.received_quantity))) {
                return false
            }
            else {
                return true
            }
        }
    }

    function handleGetInventoryCurrentQty(scopeData, distributed_qty) {
        let received_quantity = 0
        let issued_quantity = 0
        let result = 0

        if (scopeData?.inventory_logs && scopeData?.inventory_logs?.length > 0) {
            scopeData?.inventory_logs.forEach(i => {
                received_quantity += i?.received_quantity ? i?.received_quantity : 0
                issued_quantity += i?.issued_quantity ? i?.issued_quantity : 0
            })
        }
        result = received_quantity - issued_quantity - distributed_qty
        return result
    }

    function handleGetDeliveredQty(scopeData) {
        let delivered_qty = 0
        if (scopeData?.inventory_logs && scopeData?.inventory_logs?.length > 0) {
            scopeData?.inventory_logs.forEach(i => {
                delivered_qty += i?.received_quantity ? i?.received_quantity : 0
            })
        }
        return delivered_qty
    }

    useEffect(() => {
        setExpanded(-1)
        setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] }))

    }, [listLaydown?.bomLayoutData])

    return (
        <div>
            <div className='flex justify-between'>
                <div className='flex space-x-4 items-center pl-5'>
                    <BiArrowBack className='cursor-pointer text-xl' onClick={() => navigate("/dashboard/logistics-management")} />
                    <div className='flex space-x-4 items-center'>
                        {editInventoryNameModal?.boolean ?
                            <input ref={updateLaydownInputRef} className='border rounded-xl px-1.5 py-2 my-2 font-medium text-xl' defaultValue={handleFindCurrentLaydownName()} onBlur={(e) => handleUpdateLaydownName(e.target.value)} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleUpdateLaydownName(e.target.value)
                                }
                            }} />
                            :
                            <p className='font-medium border border-white py-2 px-1.5 my-2 font-bold text-xl'>{handleFindCurrentLaydownName(currentLaydown)}</p>
                        }
                        <MdModeEditOutline className='cursor-pointer' onClick={() => setEditInventoryNameModal({ boolean: true, data: currentLaydown })} />
                    </div>
                </div>
                <div className='flex space-x-4 items-center mr-5'>
                    {manage === 0 ?
                        <button className='cursor-pointer flex font-semibold text-sm' onClick={handleAutoManageLaydownDownload}>
                            <CgSoftwareDownload className='text-xl' />
                        </button>
                        :
                        <button className='cursor-pointer flex font-semibold text-sm' onClick={handleAutoManageLaydownDownload}>
                            <CgSoftwareDownload className='text-xl' />
                        </button>
                    }
                    <button onClick={() => setConfirmationModal(true)}>
                        <MdDelete className='text-xl' />
                    </button>
                </div>
            </div>
            <hr />
            {inventoryLoader?.bool ?
                <div style={{ paddingTop: "20%", paddingLeft: "50%", width: "calc(100vw - 705px)" }}>
                    <p className='loader ml-5 mb-1'></p>
                    <p className='font-bold text-xl'>{inventoryLoader?.text}...</p>
                </div>
                :
                manage === 0 ?
                    <div className='overflow-y-auto max-h-[71vh]'>
                        <table className='table table-compact z-0 relative overflow-auto w-full'>
                            <thead>
                                <tr className='sticky z-20 top-0'>
                                    <th className='normal-case w-[13%]'>Part Number</th>
                                    <th className='normal-case w-[41%]'>Part Description</th>
                                    <th className='normal-case w-[14%]'>
                                        <div className='flex space-x-2 items-center'>
                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_current_qty`} />
                                            <p>Current Qty</p>
                                        </div>
                                    </th>
                                    <th className='normal-case w-[14%]'>
                                        <div className='flex space-x-2 items-center'>
                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                            <p>Damaged Qty</p>
                                        </div>
                                    </th>
                                    <th className='normal-case w-[14%]'>
                                        <div className='flex space-x-2 items-center'>
                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                            <p>Remediated Qty</p>
                                        </div>
                                    </th>
                                    <th className='normal-case w-[4%]'></th>
                                </tr>
                            </thead>
                            <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_current_qty`}>
                                <p className='w-[300px]'>
                                    {`Current quantity is total quantity of the part currently available in the laydown, it includes damaged quantity, calculated by subtracting built (issued) quantities from delivered quantities`}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_damaged_qty`}>
                                <p className='w-[300px]'>
                                    {`Damaged quantity is the total quantity of the part reported as damaged while adding delivery records in Bill of materials page`}
                                </p>
                            </ReactTooltip>
                            {listLaydown.bomLayoutData.length === 0 ?
                                <tbody>
                                    <tr>
                                        <td colSpan={5} className='py-14'>
                                            <div className='m-auto'>
                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                listLaydown.bomLayoutData?.map((i) => {
                                    return (
                                        <tbody>
                                            <tr>
                                                <td>{i?.part_name}</td>
                                                <td className='pr-12 relative max-w-[26vw]' onMouseEnter={() => { setShowDescription(i?.bom_laydown_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                    {showDescription === i?.bom_laydown_id ?
                                                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                            {i?.description}
                                                        </p>
                                                        :
                                                        <p className='max-w-[26vw]' style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>
                                                            {i?.description}
                                                        </p>
                                                    }
                                                </td>
                                                <td>{i?.count}</td>
                                                <td>{i?.damaged ? i?.damaged : 0}</td>
                                                <td>
                                                    <div className='flex items-center' onClick={() => handleOpenEditRemediateQty(i)} >
                                                        <p className='pr-5'>{i?.remediated_quantity ? i?.remediated_quantity : 0}</p>
                                                        <RiEdit2Fill className='cursor-pointer' />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='flex space-x-4'>
                                                        {bomLaydownLoader && expanded === i?.bom_id ?
                                                            <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                                            :
                                                            expanded === i?.bom_id ?
                                                                <BsChevronUp className='cursor-pointer' onClick={() => { setExpanded(-1); setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] })) }} />
                                                                :
                                                                <BsChevronDown className='cursor-pointer' onClick={() => handleOpenBomNestedTable(i)} />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            {expanded === i?.bom_id && !bomLaydownLoader ?
                                                <td colSpan="6">
                                                    {expanded === i?.bom_id && !bomLaydownLoader ?
                                                        <div className='border pb-3 w-full'>
                                                            <NestedTable
                                                                className="min-h-[265px]"
                                                                bom_id={addEntry.data[0].bom_id}
                                                                handleSaveEdit={(e, epoch) => handleSaveEdit(e, epoch)}
                                                                handleDeleteInventory={(laydown_id, data) => handleDeleteInventory(laydown_id, data)}
                                                                laydownId={currentLaydown}
                                                                nestedData={listLaydown.bomInventoryData.Inventory_logs}
                                                                addEntry={addEntry.boolean}
                                                                destinationLaydown={listLaydown.data.filter(o => o.id !== currentLaydown)}
                                                                onAdjustmentChange={e => setAddEntry(produce((draft) => { draft.data[0].received_quantity = e }))}
                                                                onIssuedChange={e => setAddEntry(produce((draft) => { draft.data[0].issued = e }))}
                                                                onActionChange={e => setAddEntry(produce((draft) => { draft.data[0].purpose_code = e }))}
                                                                onDesitantionChange={e => setAddEntry(produce((draft) => { draft.data[0].destination = e }))}
                                                                onCommentsChange={e => setAddEntry(produce((draft) => { draft.data[0].comments = e }))}
                                                                onDateChange={e => setAddEntry(produce((draft) => { draft.data[0].time = e }))}
                                                                bomLayoutData={listLaydown.bomLayoutData}
                                                            />
                                                            <div className='flex justify-between py-3 w-[95%] m-auto'>
                                                                <div className='flex py-3 rounded-2xl space-x-10 items-center'>
                                                                    <p className='text-sm pl-3 font-bold'>Current Quantity</p>
                                                                    <p className='text-sm'>{listLaydown.bomInventoryData.current_quantity}</p>
                                                                </div>
                                                                {addEntry.boolean ?
                                                                    <div className='flex space-x-5 px-2 justify-end'>
                                                                        {bomLaydownUpdateLoader.bool && bomLaydownUpdateLoader.mode === "save" ?
                                                                            <button className='flex space-x-3 items-center btn bg-red-550 text-white rounded-full px-6 border-none'>
                                                                                <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                                                                <p>Save</p>
                                                                            </button>
                                                                            :
                                                                            <button className='btn bg-red-550 text-white rounded-full px-6 border-none' onClick={handleAddBomInventory} disabled={handleCheckBomInventorySave()}>Save</button>
                                                                        }
                                                                        <button className='btn bg-white text-gray-500 rounded-full px-4 border-2 hover:bg-gray-500 hover:text-white border-gray-500' onClick={() => setAddEntry(produce((draft) => { draft.boolean = false; draft.data[0] = { bom_id: addEntry.data[0].bom_id, count: null, purpose_code: 1, destination: null, comments: "" } }))}>Cancel</button>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {!addEntry.boolean && <button className='btn bg-red-550 text-white rounded-full px-4 mx-2 border-none' onClick={() => { setAddEntry(produce((draft) => { draft.boolean = true })) }}>+ Add Entry</button>}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </td>
                                                :
                                                <hr />
                                            }
                                        </tbody>
                                    )
                                })
                            }
                        </table>
                    </div>
                    :
                    <div className='overflow-y-auto' style={{ maxHeight: "calc(100vh - 210px)" }}>
                        <table className='table table-compact z-0 relative overflow-auto w-full'>
                            <thead>
                                <tr className='sticky top-0 z-30'>
                                    <th className={`normal-case w-[13%]`} rowSpan='2'>Part Number</th>
                                    <th className={`normal-case ${showScopeTableQty ? "w-[17%]" : "w-[47%]"}`} rowSpan='2'>Part Description</th>
                                    <th className={`normal-case text-center ${showScopeTableQty ? "w-[70%]" : "w-[40%]"}`} colspan={showScopeTableQty ? "7" : "5"}>Quantities</th>
                                    <th></th>
                                </tr>
                                <tr className='sticky top-9 border-t z-20 border-white'>
                                    <th className={`normal-case`}>Required Qty</th>
                                    <th className={`normal-case flex space-x-3 items-center cursor-pointer`} onClick={() => setShowScopeTableQty(!showScopeTableQty)}>
                                        <p>Delivered Qty</p>
                                        {showScopeTableQty ? <MdKeyboardArrowLeft className='text-base border border-white' /> : <MdKeyboardArrowRight className='text-base border border-white' />}
                                    </th>
                                    {showScopeTableQty && <th className={`normal-case`}>Damaged Qty</th>}
                                    {showScopeTableQty && <th className={`normal-case`}>Remediate Qty</th>}
                                    <th className={`normal-case`}>Distributed Qty</th>
                                    <th className={`normal-case`}>Installed Qty</th>
                                    <th className={`normal-case`}>Current Qty</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {listLaydown?.scopeLaydownData?.bom_laydown_data && listLaydown?.scopeLaydownData?.bom_laydown_data?.length === 0 ?
                                <tbody>
                                    <tr>
                                        <td colSpan={10} className='py-14'>
                                            <div className='m-auto'>
                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody>
                                    {listLaydown?.scopeLaydownData?.bom_laydown_data && listLaydown?.scopeLaydownData?.bom_laydown_data?.map((scopeData) => {
                                        return (
                                            <>
                                                <tr className='hover'>
                                                    <td className='sticky z-20 left-0'>{scopeData?.part_name}</td>
                                                    <td className={`relative py-2 ${showScopeTableQty ? `${showRightPanel ? "max-w-[10vw]" : "max-w-[30vw]"}` : `${showRightPanel ? "max-w-[23vw]" : "max-w-[43vw]"}`}`} onMouseEnter={() => { setShowDescription(scopeData?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                        {showDescription === scopeData?.bom_id &&
                                                            <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                {scopeData?.description}
                                                            </p>
                                                        }
                                                        <p className={`${showScopeTableQty ? `${showRightPanel ? "max-w-[10vw]" : "max-w-[30vw]"}` : `${showRightPanel ? "max-w-[23vw]" : "max-w-[43vw]"}`}`} style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>{scopeData?.description}</p>
                                                    </td>
                                                    <td>{scopeData?.required_qty ? scopeData?.required_qty : 0}</td>
                                                    <td>{handleGetDeliveredQty(scopeData)}</td>
                                                    {showScopeTableQty && <td>{scopeData?.damaged ? scopeData?.damaged : 0}</td>}
                                                    {showScopeTableQty && <td>
                                                        <div className='flex items-center' onClick={() => handleOpenEditRemediateQty(scopeData)} >
                                                            <p className='pr-5'>{scopeData?.remediated_quantity ? scopeData?.remediated_quantity : 0}</p>
                                                            <RiEdit2Fill className='cursor-pointer' />
                                                        </div>
                                                    </td>}
                                                    <td>{scopeData?.distributed_qty ? scopeData?.distributed_qty : 0}</td>
                                                    <td>{scopeData?.installed_qty ? scopeData?.installed_qty : 0}</td>
                                                    <td>{handleGetInventoryCurrentQty(scopeData, scopeData?.distributed_qty ? scopeData?.distributed_qty : 0)}</td>
                                                    <td>
                                                        <div className='flex space-x-4'>
                                                            {bomLaydownLoader && expanded === scopeData?.bom_id ?
                                                                <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                                                :
                                                                expanded === scopeData?.bom_id ?
                                                                    <BsChevronUp className='cursor-pointer' onClick={() => { setExpanded(-1); setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] })) }} />
                                                                    :
                                                                    <BsChevronDown className='cursor-pointer' onClick={() => handleOpenBomNestedTable(scopeData)} />
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {expanded === scopeData?.bom_id && !bomLaydownLoader ?
                                                        <td colSpan="10">
                                                            {expanded === scopeData?.bom_id && !bomLaydownLoader ?
                                                                <div className='border pb-3 w-full'>
                                                                    <NestedTable
                                                                        className="min-h-[265px]"
                                                                        autoScope={true}
                                                                        bom_id={addEntry.data[0].bom_id}
                                                                        handleSaveEdit={(e, epoch) => handleSaveEdit(e, epoch)}
                                                                        handleDeleteInventory={(laydown_id, data) => handleDeleteInventory(laydown_id, data)}
                                                                        laydownId={currentLaydown}
                                                                        nestedData={listLaydown.bomInventoryData.Inventory_logs}
                                                                        addEntry={addEntry.boolean}
                                                                        destinationLaydown={listLaydown.data.filter(o => o.id !== currentLaydown)}
                                                                        onAdjustmentChange={e => setAddEntry(produce((draft) => { draft.data[0].received_quantity = e }))}
                                                                        onIssuedChange={e => setAddEntry(produce((draft) => { draft.data[0].issued = e }))}
                                                                        onActionChange={e => setAddEntry(produce((draft) => { draft.data[0].purpose_code = e }))}
                                                                        onDesitantionChange={e => setAddEntry(produce((draft) => { draft.data[0].destination = e }))}
                                                                        onCommentsChange={e => setAddEntry(produce((draft) => { draft.data[0].comments = e }))}
                                                                        onDateChange={e => setAddEntry(produce((draft) => { draft.data[0].time = e }))}
                                                                        bomLayoutData={listLaydown.bomLayoutData}
                                                                    />
                                                                    <div className='flex justify-between py-3 w-[95%] m-auto'>
                                                                        <div className='flex py-3 rounded-2xl space-x-10 items-center'>
                                                                            <p className='text-sm pl-3 font-bold'>Current Quantity</p>
                                                                            <p className='text-sm'>{handleGetInventoryCurrentQty(scopeData, scopeData?.distributed_qty ? scopeData?.distributed_qty : 0)}</p>
                                                                        </div>
                                                                        {addEntry.boolean ?
                                                                            <div className='flex space-x-5 px-2 justify-end'>
                                                                                {bomLaydownUpdateLoader.bool && bomLaydownUpdateLoader.mode === "save" ?
                                                                                    <button className='flex space-x-3 items-center btn bg-red-550 text-white rounded-full px-6 border-none'>
                                                                                        <p className='loader' style={{ width: "20px", height: "20px" }}></p>
                                                                                        <p>Save</p>
                                                                                    </button>
                                                                                    :
                                                                                    <button className='btn bg-red-550 text-white rounded-full px-6 border-none' onClick={handleAddBomInventory} disabled={handleCheckBomInventorySave()}>Save</button>
                                                                                }
                                                                                <button className='btn bg-red-550 text-white rounded-full px-4 border-none' onClick={() => setAddEntry(produce((draft) => { draft.boolean = false; draft.data[0] = { bom_id: addEntry.data[0].bom_id, count: null, purpose_code: 1, destination: null, comments: "" } }))}>Cancel</button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {!addEntry.boolean && <button className='btn bg-red-550 text-white rounded-full px-4 mx-2 border-none' onClick={() => { setAddEntry(produce((draft) => { draft.boolean = true })) }}>+ Add Entry</button>}
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </td>
                                                        :
                                                        <hr />
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default InventoryLogTable
