import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import InputBox from '../../../components/inputBox';
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { BiArrowBack } from 'react-icons/bi'
import { MdAccountCircle, MdTimer } from "react-icons/md";
import { IoChevronBackCircleOutline } from "react-icons/io5"
import { VscClose } from 'react-icons/vsc'
import { IoIosArrowDropright } from "react-icons/io"
import { MdModeEditOutline, MdDelete } from "react-icons/md"
import { AiOutlineSearch } from "react-icons/ai"
import { FaLongArrowAltRight } from 'react-icons/fa';
import { BsThreeDots } from "react-icons/bs"
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import workHourAtom, {
    createWorkHoursAtom,
    dateAtom,
    deleteWorkHoursAtom,
    editWorkHoursAtom,
    fetchIndividualAct,
    fetchPlantId,
    fetchWorkHourActivitiesAtom,
    fetchWorkHourUsersAtom
} from '../../../atoms/workHoursAtom'
import Container from '../../../components/container'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-hot-toast';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import ActivitySelectBox from './activityBox';
import SelectUser from './selectUser';
import { DateFormat } from '../../../components/dateFormat';
import SelectBox from '../../../components/selectBox';

function IndividualAct() {

    const [submitCount, subSubmitCount] = useState(false)

    const [loading, setloading] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const [editStaffId, setEditStaffId] = useState()

    const [searchBoolean, setSearchBoolean] = useState(false)
    const [newDate, setDate] = useAtom(dateAtom);
    const [listWorkHour] = useAtom(workHourAtom)
    const [, setFetchIndividualAct] = useAtom(fetchIndividualAct)
    const [, setFetchActivities] = useAtom(fetchWorkHourActivitiesAtom)
    const [, setFetchUsers] = useAtom(fetchWorkHourUsersAtom)
    const [, setFetchDelete] = useAtom(deleteWorkHoursAtom)
    const [, createWorkHours] = useAtom(createWorkHoursAtom)
    const [, setFetchEditData] = useAtom(editWorkHoursAtom)
    const [, setFetchPlantId] = useAtom(fetchPlantId)


    const [filterArr, setFilterArr] = useState([])

    useEffect(() => {
        init()
    }, [newDate])

    const init = async () => {
        try {
            setloading(true)
            await setFetchUsers()
            await setFetchActivities()
            await setFetchIndividualAct({ date: newDate.toISOString().split('T')[0], act_id: location?.state?.act_id, type: location?.state?.type })
            await setFetchPlantId()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setloading(false)
        }
    }

    const dateIncrement = () => {
        setDate(new Date(newDate.setDate(newDate.getDate() + 1)))
    }

    const dateDecrement = () => {
        setDate(new Date(newDate.setDate(newDate.getDate() - 1)))
    }

    const datePickerStyle = ({ className, children }) => {
        return (
            <div>
                <calendarContainer className={className}>
                    <div style={{ position: "relative" }}>
                        {children}
                    </div>
                </calendarContainer>
            </div>
        )
    }

    let ttlStaffHours = 0
    filterArr.map((i) => {
        ttlStaffHours += i.hours
    })

    const findActivityTitleHeader = (activity_id, type) => {
        let activity = listWorkHour.activities.find(lw => lw.id.toString() === activity_id.toString() && type === lw.type)
        if (activity) {
            if (activity?.type === "construction_activity" && activity?.parent_activity_id) {
                let parent_activity_name = listWorkHour?.activities.find(act => act?.id === activity?.parent_activity_id)
                return (
                    <div className='flex item-center space-x-2' style={{ textTransform: "capitalize" }}>
                        <p>{parent_activity_name?.name}</p>
                        <p className='mt-1'><FaLongArrowAltRight /></p>
                        <p>{activity?.name}</p>
                    </div>
                )
            }
            else {
                return activity.name
            }
        }
        return activity_id
    }

    // let actname
    // listWorkHour.activities.map((j) => {
    //     if (j.id === location.state.act_id && j.type === location.state.type) {
    //         actname = j.name
    //     }
    // })

    const handleDelete = async (e, i) => {
        try {
            setloading(true)
            e.preventDefault()
            await setFetchDelete(i.id)
            await setFetchIndividualAct({ date: newDate.toISOString().split('T')[0], act_id: location.state.act_id, type: location.state.type })
            toast.success("deleted work hours")
        }
        catch (err) {
            console.log(err)
            toast.error("unable to delete work hours")
        }
        finally {
            setloading(false)
        }
    }

    const [selectAct, setSelectAct] = useState()
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const [selected, setSelected] = useState([])
    const [selectHour, setSelectHour] = useState()

    const [editStaff, setEditStaff] = useState()
    const [editAct, setEditAct] = useState()
    const [editHour, setEditHour] = useState()
    const [editType, setEditType] = useState()
    const [editDate, setEditDate] = useState()
    const [editId, setEditId] = useState()
    const [plantId, setPlantId] = useState();
    const [plantIdType, setPlantIdType] = useState('')
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        setFilterArr(listWorkHour?.individualAct)
    }, [listWorkHour?.individualAct])

    const SearchFilter = (e) => {
        let quaryFilter = e.target.value
        var updatedList = [...listWorkHour?.individualAct]
        updatedList = updatedList.filter((item) =>
            item.firstname.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        setFilterArr(updatedList)
    }


    const [isOpen, setIsOpen] = useState(false)

    const multiSelectOnSelect = (e) => {
        setSelected(e)
    }

    const options = []

    listWorkHour.users.map((stf) => {
        options.push({
            value: stf.id,
            label: stf?.name + stf?.profile_data?.last_name
        })
    })


    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const findActivityTitle = (activity_id, type) => {
        let activity = listWorkHour.activities.find(lw => lw.id.toString() === activity_id.toString() && type === lw.type)
        if (activity) {
            if (activity?.type === "construction_activity" && activity?.parent_activity_id) {
                let parent_activity_name = listWorkHour?.activities.find(act => act?.id === activity?.parent_activity_id)
                return (
                    <div style={{ textTransform: "capitalize" }}>
                        {parent_activity_name?.name}
                        <div className='flex mt-1 ml-[6px] space-x-1'>
                            <MdOutlineSubdirectoryArrowRight />
                            <p>{activity?.name}</p>
                        </div>
                    </div>
                )
            }
            else {
                return activity.name
            }
        }
        return activity_id
    }

    const handleAddFormSubmit = async (event) => {
        subSubmitCount(submitCount + 1)
        event.preventDefault();
        try {
            let dataTemp = []
            for (let get of selected) {
                let temp = {}
                if (plantId) {
                    temp.activity_id = selectAct[0]
                    temp.hours = selectHour
                    temp.user_app_id = get
                    temp.date = Math.round(newDate.getTime() / 1000)
                    temp.type = selectAct[1].toString()
                    temp.plant_pk = parseInt(plantId)
                    // await createWorkHours({
                    //     activity_id: selectAct[0],
                    //     hours: selectHour,
                    //     user_app_id: get,
                    //     date: Math.round(newDate.getTime() / 1000),
                    //     type: selectAct[1].toString(),
                    //     plant_pk: parseInt(plantId)
                    // })
                }
                else {
                    temp.activity_id = selectAct[0]
                    temp.hours = selectHour
                    temp.user_app_id = get
                    temp.date = Math.round(newDate.getTime() / 1000)
                    temp.type = selectAct[1].toString()
                    // temp.plant_pk = parseInt(plantId)
                    // await createWorkHours({
                    //     activity_id: selectAct[0],
                    //     hours: selectHour,
                    //     user_app_id: get,
                    //     date: Math.round(newDate.getTime() / 1000),
                    //     type: selectAct[1].toString(),
                    //     // plant_pk: parseInt(plantId)
                    // })
                }
                dataTemp.push(temp)

            }
            await createWorkHours({ data: dataTemp, date: newDate.toISOString().split('T')[0] })
            await setFetchIndividualAct({ date: newDate.toISOString().split('T')[0], act_id: location.state.act_id, type: location.state.type })
            setIsOpen((prevState) => !prevState)
            toast.success("Work Hours Added")
        } catch (err) {
            console.log(err);
            toast.error("Work Hours could not be added")
        }
    }

    const handleEditClick = (e, i) => {
        e.preventDefault()
        setEditStaffId(i.id)
        setSelectedValue(listWorkHour?.plantId.find(data => data.plant_project_id === i.plant_project_id)?.plant_project_id)
        setPlantIdType(listWorkHour?.plantId.find(data => data.plant_project_id === i.plant_project_id)?.plant_type)
        setEditAct([i.activity_id, i?.type])
        setEditHour(i.hours)
        setEditStaff(i.user_app_id)
        setEditType(i.type)
        setEditDate(i.date)
        setEditId(i.id)
        setPlantId(i.plant_project_id)
    }

    const handleEditCancel = () => {
        setEditStaffId()
    }

    const handleEditSave = async () => {
        try {
            let temp = {}
            temp.id = editId
            temp.hours = parseInt(editHour)
            temp.type = editAct[1]
            temp.activity_id = parseInt(editAct[0])
            temp.date = editDate
            temp.user_app_id = parseInt(editStaff)
            temp.plant_pk = parseInt(plantId)
            console.log(temp)
            if (selectedValue) {
                await setFetchEditData({
                    id: editId,
                    hours: parseInt(editHour),
                    type: editAct[1],
                    activity_id: parseInt(editAct[0]),
                    date: editDate,
                    user_app_id: parseInt(editStaff),
                    plant_pk: parseInt(selectedValue)
                })
            }
            else {
                await setFetchEditData({
                    id: editId,
                    hours: parseInt(editHour),
                    type: editAct[1],
                    activity_id: parseInt(editAct[0]),
                    date: editDate,
                    user_app_id: parseInt(editStaff)
                    // plant_pk: parseInt(plantId)
                })
            }

            await setFetchIndividualAct({ date: newDate.toISOString().split('T')[0], act_id: location.state.act_id, type: location.state.type })
            toast.success("Successfully updated")
            setEditStaffId()
            setEditAct()
            setEditHour()
            setEditStaff()
            setEditType()
            setEditDate()
            setEditId()
        } catch (err) {
            console.log(err)
            toast.error("Unable to update Details")
        }

    }

    const findUserName = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);

        if (user) {
            return user.user.name + " " + user.user?.profile_data?.last_name
        }
        return user_id
    }

    const findUserFirstLetter = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);

        if (user) {
            return user.user?.name?.charAt(0).toUpperCase()
        }
        return user_id
    }

    const handleChangePlant = (e) => {
        setSelectedValue(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.plant_project_id)
        setPlantId(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.id)
        setPlantIdType(listWorkHour?.plantId.find(data => data.plant_project_id === parseInt(e.target.value))?.plant_type)
    }

    const getValue = (value) => listWorkHour?.plantId.find(data => data?.plant_project_id === value)?.name

    return (
        <Container>
            <div className='flex justify-between mb-5'>
                <div>
                    <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/staff_and_plant/workHour")} />
                    <div className='ml-6 mt-5 text-xl font-bold'>
                        {findActivityTitleHeader(location.state.act_id, location.state.type)}
                    </div>
                </div>
                <div className='flex'>
                    {!searchBoolean ?
                        <AiOutlineSearch style={{ width: '25px', height: '25px' }} className="mt-10 cursor-pointer mr-6" onClick={() => setSearchBoolean(true)} /> :
                        <input className='appearance-none text-gray-700 border border-gray-300 leading-tight py-3 px-5 rounded-3xl mr-8 mt-7' type="search" placeholder='Search Staff' style={{ width: '280px', height: '48px' }} onChange={SearchFilter} />
                    }
                    <button className='mt-7 mr-7 bg-red-550 text-white border-none rounded-3xl btn' style={{ width: '180px' }} onClick={toggleDrawer}>
                        Add Work Hours +
                    </button>
                </div>
            </div>
            <hr />
            <div>
                <Drawer open={isOpen} onClose={toggleDrawer} direction='right' size='580px' className='absolute'>
                    <form className='menu p-4 bg-base-100 text-base-content h-full'>
                        <div className='h-[90%]' >
                            <div className='flex justify-between mt-4 mb-6'>
                                <p className='text-xl font-bold ml-4 mt-2'>Work Hours</p>
                                <div onClick={toggleDrawer}>
                                    <VscClose className='cursor-pointer mr-2 ' style={{ width: '40px', height: '40px' }} />
                                </div>
                            </div>
                            <label>
                                <ActivitySelectBox className={"pl-4 py-2"} activities={listWorkHour.activities} placeholder="Select Activity" onChange={e => setSelectAct(e)} bool={submitCount} />
                            </label>
                            <label>
                                <SelectUser StaffList={listWorkHour.users} onChange={e => setSelected(e)} bool={submitCount} />
                            </label>
                            <label>
                                <SelectBox allowclear={true} onChange={e => { setPlantId(e) }} hidden="Add Plant (if applicable)" className="cursor-pointer pl-4 py-2 mt-7 ml-4 rounded-xl text-base border" width="480px" value={listWorkHour?.plantId} bool={submitCount} />
                            </label>
                            <label>
                                <p className='font-semibold text-s ml-4 mt-10'>Work Hours</p>
                                <div className='flex ml-4 mt-1'>
                                    <InputBox rounded='rounded-l-xl' mb='3' mr='0' width='70px' name='work_hours' placeholder='0' onChange={e => setSelectHour(parseInt(e.target.value))} />
                                    <InputBox rounded='rounded-r-xl' mb='3' mr='0' width='58px' value={"Hrs"} />
                                </div>
                            </label>
                        </div>
                        <hr />
                        <div className='flex justify-end mt-5 mr-3'>
                            <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' style={{ width: '100px' }} type='reset' onClick={() => { setIsOpen((prevState) => !prevState); setSelected([]); setSelectedStaffs([]); subSubmitCount(submitCount + 1) }}>
                                Cancel
                            </button>
                            <button className='border-none rounded-3xl mx-5 bg-red-550 text-white btn' style={{ width: '96px' }} type="submit" onClick={handleAddFormSubmit}>
                                Save
                            </button>
                        </div>
                    </form>
                </Drawer>
            </div>

            <div className='flex justify-between'>
                <div className='flex' style={{ height: '80px' }}>
                    <div className='border-r-2 border-gray-300 flex justify-center items-center px-5 gap-4'>
                        <div>
                            <IoChevronBackCircleOutline className='cursor-pointer text-gray-300' alt='' style={{ width: '25px', height: '25px', color: "#696F79" }} onClick={dateDecrement} />
                        </div>
                        <div>
                            <DatePicker dateFormat="dd-MMMM-yyyy" showIcon className='border-2 rounded-xl w-48 py-2 text-center cursor-pointer' calendarContainer={datePickerStyle} onChange={setDate} selected={newDate} maxDate={new Date()} />
                        </div>
                        <div>
                            {newDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] ?
                                <IoIosArrowDropright className='text-gray-200' style={{ width: '25px', height: '25px' }} />
                                :
                                <IoIosArrowDropright className='cursor-pointer' style={{ width: '25px', height: '25px', color: "#696F79" }} onClick={dateIncrement} />
                            }
                        </div>
                    </div>
                    <div className='border-r-2 border-gray-300 ' style={{ width: '100px' }}>
                        <p className='mt-3 ml-3 font-medium text-sm text-gray-600'>Total Staff</p>
                        <div className='flex items-center mt-2 ml-4'>
                            <MdAccountCircle fontSize="25px" />
                            <p className='ml-3 font-semibold'>{filterArr.length}</p>
                        </div>
                    </div>
                    <div className='border-r-2 border-gray-300' style={{ width: '150px' }}>
                        <p className='mt-3 ml-4 font-medium text-sm text-gray-600'>Total Work Hours</p>
                        <div className='flex items-center mt-2 ml-5'>
                            <MdTimer fontSize="25px" />
                            <p className='font-semibold ml-3'>{ttlStaffHours} Hrs</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="overflow-scroll" style={{ height: 'calc(100vh - 275px)' }}>
                {loading ?
                    <div className='' style={{ marginTop: "14%", marginLeft: "44%" }}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p></div>
                    :
                    filterArr.length > 0 ?
                        <table className="table table-compact w-full z-0">
                            <thead>
                                <tr className='text-left font-semibold text-xs text-gray-400 sticky top-0 z-20 w-full'>
                                    <th className='normal-case pl-5' style={{ width: '20%' }}>Staff</th>
                                    <th className='normal-case ' style={{ width: '15%' }}>Plant Type</th>
                                    <th className='normal-case ' style={{ width: '12%' }}>Plant ID</th>
                                    <th className='normal-case ' style={{ width: '20%' }}>Activity</th>
                                    <th className='normal-case ' style={{ width: '15%' }}>Work Hours</th>
                                    <th className='normal-case' style={{ width: '10%' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterArr.map((i) => {
                                    return <>
                                        {editStaffId === i.id ?
                                            <tr>
                                                <td className='flex items-center pl-5 bg-gray-100'>
                                                    {i.profile_url === null ?
                                                        <div className='avatar'>
                                                            <div className="text-neutral-content rounded-full w-7 h-7 text-center p-0.5 bg-yellow-400 text-white text-base">
                                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(i?.user_app_id)}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='avatar'>
                                                            <div className='w-8 h-8 rounded-full'>
                                                                <img src={i.profile_url} alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <select className='ml-2 p-1.5 border border-gray-300 rounded-xl w-[80%]' onChange={e => setEditStaff(e.target.value)}>
                                                        <option hidden style={{ textTransform: 'capitalize' }}>{findUserName(i?.user_app_id)}</option>
                                                        {listWorkHour.users.map((u) => {
                                                            return <option value={u?.staff_project_id}>{u?.user?.name + " " + u?.user?.profile_data?.last_name}</option>
                                                        })}
                                                    </select>
                                                </td>
                                                <td className='bg-gray-100'>{plantIdType ? plantIdType : i?.plant_type ? i?.plant_type : '-'}</td>
                                                <td className='bg-gray-100'>
                                                    <select
                                                        className='p-1.5 border border-gray-300 rounded-xl cursor-pointer w-[80%]'
                                                        onChange={handleChangePlant}
                                                        value={selectedValue}
                                                    >
                                                        {!i?.plant_id && <option hidden>Select a plant</option>}
                                                        {listWorkHour.plantId.map((data) => {
                                                            return (
                                                                <option className='cursor-pointer' value={data?.plant_project_id}>
                                                                    {`${data.name}  ${data.plant_id}`}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </td>
                                                <td className="bg-gray-100">
                                                    <ActivitySelectBox className={"p-1.5 h-[33px] text-sm"} activities={listWorkHour.activities} placeholder="Select Activity" onChange={e => { setEditAct(e); console.log(e) }} edit={true} defaultValue={listWorkHour.activities.find(lw => lw.id === i.activity_id && i.type === lw.type)?.name} clickOnSelect={true} />
                                                    {/* <select className='py-3 px-4 border border-gray-300 rounded-xl' style={{ width: '260px' }} onChange={e => setEditAct(e.target.value)}>
                                                            <option hidden>{listWorkHour.activities.find(lw => lw.id === i.activity_id && i.type === lw.type)?.name}</option>
                                                            {listWorkHour.activities.map((act) => {
                                                                console.log(act)
                                                                return <option value={act.id}>{act.name}</option>
                                                            })}
                                                        </select> */}
                                                </td>
                                                <td className="bg-gray-100">
                                                    <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl p-1.5 leading-tight' defaultValue={i.hours} style={{ width: "130px" }} onChange={e => setEditHour(e.target.value)} />
                                                </td>
                                                <td className="bg-gray-100">
                                                    <button className='mr-2 text-red-550 font-bold' onClick={handleEditSave}> Save</button>
                                                    <button className='font-bold' onClick={handleEditCancel}>Cancel </button>
                                                </td>
                                            </tr>
                                            :
                                            <tr className='hover'>
                                                <td className='flex pl-5'>
                                                    {i.profile_url === null ?
                                                        <div className='avatar'>
                                                            <div className="my-1.5 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base">
                                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(i?.user_app_id)}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='avatar'>
                                                            <div className='w-8 rounded-full'>
                                                                <img src={i.profile_url} alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <p className='mt-2 ml-4' style={{ textTransform: 'capitalize' }}>{findUserName(i?.user_app_id)}</p>
                                                </td>
                                                <td className='pl-4'>{i.plant_type ? i.plant_type : "-"}</td>
                                                <td className='pl-4'>{i.plant_id ? `${getValue(i.plant_project_id)}  ${i.plant_id}` : "-"}</td>
                                                <td className='pl-4' style={{ textTransform: 'capitalize' }}>
                                                    {findActivityTitle(i.activity_id, i?.type)}
                                                </td>
                                                <td className='pl-4'>{i.hours}</td>
                                                <td className='pl-2'>
                                                    <div tabIndex={0} className="dropdown dropdown-left">
                                                        <div className='ml-2 cursor-pointer bg-white border-2 rounded-md border-gray-100 border-solid px-2 text-gray-700'>
                                                            <BsThreeDots size={20} />
                                                        </div>
                                                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-xl">
                                                            <li onClick={(e) => handleEditClick(e, i)}><a className="flex active:bg-red-550 active:text-white">
                                                                <MdModeEditOutline className="mr-2 mt-0.5" style={{ width: "20px", height: '20px' }} />
                                                                <p>Edit</p></a></li>
                                                            <li onClick={(e) => handleDelete(e, i)}><a className="flex active:bg-red-700 active:text-white">
                                                                <MdDelete className="mr-2 mt-0.5" style={{ width: "20px", height: '20px' }} />
                                                                <p>Delete</p></a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='flex justify-center' style={{ width: "calc(100vw - 350px)" }}>
                            <div style={{ marginTop: "15%" }}>
                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                            </div>
                        </div>
                }
            </div>
        </Container>
    )
}

export default IndividualAct