import React, { useEffect, useState } from 'react'
import ViewByDropDown from '../components/viewByDropDown'
import ManageDelivery from './manageDelivery'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import InventoryLogTable from './inventoryLogTable'
import toast from 'react-hot-toast'
import Modal from 'react-modal';
import { autoManageLaydownDownload, deleteLaydown, downloadLaydown, fetchIndividualLayoutData, fetchLaydownScopeDataAtom, updateRemediatedQuantityAtom } from '../../../atoms/layoutAtom'
import { useAtom } from 'jotai'
import { VscClose } from 'react-icons/vsc'
import mileStone, { fetchRemediateQty } from '../../../atoms/mileStoneAtom'
import { useNavigate } from 'react-router-dom'

function InventoryLog({
  listLaydown,
  currentLaydown,
  formattedDate,
  projectsName,
  handleChangeView,
  showRightPanel,
  setShowRightPanel
}) {

  const navigate = useNavigate()

  const [manage, setManage] = useState(0)
  const [inventoryLoader, setInventoryLoader] = useState({ bool: false, text: "" })
  const [remediateLoader, setRemediateLoader] = useState(false)
  const [showManageDelivery, setShowManageDelivery] = useState(false)
  const [openEditRemediateQty, setOpenRemediateQty] = useState(false)
  const [confimationModal, setConfirmationModal] = useState(false)
  const [remediateQtyBomDetails, setRemediateQtyBomDetails] = useState({})
  const [deleteLaydownLoader, setDeleteLaydownLoader] = useState(false)
  const [inventryBomId, setInventryBomId] = useState(0)
  const [remediateQtyData, setRemediateQtyData] = useState([]);

  const [, setDeleteLaydown] = useAtom(deleteLaydown)
  const [, setDownloadLaydown] = useAtom(downloadLaydown)
  const [, setAutoManageLaydownDownload] = useAtom(autoManageLaydownDownload)
  const [, setFetchSingleLayout] = useAtom(fetchIndividualLayoutData)
  const [, setFetchScopeLaydown] = useAtom(fetchLaydownScopeDataAtom)
  const [, fetchInventryLogDeliveryQty] = useAtom(fetchRemediateQty)
  const [, updateRemediatedQuantity] = useAtom(updateRemediatedQuantityAtom)
  const [listMileStone] = useAtom(mileStone)

  let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))

  useEffect(() => {
    let laydown_auto_manage = getLaydownAutoManage()
    if (laydown_auto_manage === true) {
      setManage(1)
    }
  }, [])

  useEffect(() => {
    if (currentLaydown) {
      init()
    }
  }, [currentLaydown])

  const init = async () => {
    try {
      setInventoryLoader({ bool: true, text: "Fetching Logs" })
      await setFetchSingleLayout({ laydown_id: currentLaydown })
      await setFetchScopeLaydown({ laydown_id: currentLaydown })
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      {
        setInventoryLoader({ bool: false, text: "" })
      }
    }
  }

  //find function 
  const getLaydownAutoManage = () => {
    let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
    let laydown_auto_manage = false
    if (currentProject?.project_settings && currentProject?.project_settings?.laydown_auto_manage) {
      laydown_auto_manage = currentProject?.project_settings?.laydown_auto_manage
    }
    return laydown_auto_manage
  }

  const getDeliveryReferenceName = () => {
    let name = ""
    if (listMileStone?.deliveryReferenceAliasName !== null && Object.keys(listMileStone?.deliveryReferenceAliasName).length !== 0 && listMileStone?.deliveryReferenceAliasName?.message !== 'Field alias not found') {
      name = listMileStone?.deliveryReferenceAliasName?.field_alias_name
    }
    return name
  }

  const handleCheckQuantity = () => {
    let temp = []
    for (let data of listLaydown?.bomLayoutData) {
      temp.push(data.count)
    }
    let result
    if (temp.reduce((accumulator, currentValue) => accumulator + currentValue, 0) === 0) {
      result = false
    }
    else {
      result = true
    }
    return result
  }

  function handleFindCurrentLaydownName() {
    let temp
    temp = listLaydown?.data?.find(o => o?.id === currentLaydown)?.name
    return temp
  }

  //onchange function
  const handleChangeRemediatedQty = (e, index) => {
    const { value } = e.target;
    const quantity = value === '' ? null : parseInt(value, 10);
    const bomDeliveryLaydownId = listMileStone.remediateQty[index]?.bom_delivery_laydown_id;

    // Clone the current state
    const updatedData = [...remediateQtyData];

    // Check if an entry for this bom_delivery_laydown_id already exists
    const existingIndex = updatedData.findIndex(item => item?.bom_delivery_laydown_id === bomDeliveryLaydownId);

    // If the user entered a value (including 0)
    if (quantity !== null) {
      const newEntry = {
        bom_delivery_laydown_id: bomDeliveryLaydownId,
        remediated_quantity: quantity
      };

      if (existingIndex !== -1) {
        // If the entry exists, update the existing entry
        updatedData[existingIndex] = newEntry;
      } else {
        // If the entry doesn't exist, add it to the array
        updatedData.push(newEntry);
      }
    } else {
      // If the user clears the field, remove the entry from the state
      if (existingIndex !== -1) {
        updatedData.splice(existingIndex, 1);
      }
    }

    // Update the state without null entries
    setRemediateQtyData(updatedData);
  };

  //onsubmit function
  const handleSaveRemediatedQty = async () => {
    try {
      if (remediateQtyData.length !== 0) {
        setRemediateLoader(true)
        const filteredData = remediateQtyData?.filter(item => item !== undefined && item.remediated_quantity !== null);
        await updateRemediatedQuantity(filteredData)
        await fetchInventryLogDeliveryQty({ bom_id: inventryBomId })
        await setFetchScopeLaydown({ laydown_id: currentLaydown })
        setRemediateQtyData([])
        toast.success("Saved successfully")
        await setFetchSingleLayout({ laydown_id: currentLaydown })
        setOpenRemediateQty(false)
      }

    } catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    }
    finally {
      setRemediateLoader(false)
    }
  }

  const handleDeleteLaydown = async () => {
    try {
      setDeleteLaydownLoader(true)
      await setDeleteLaydown({ laydown_id: currentLaydown })
      let temp = listLaydown.data.filter(o => o.id === currentLaydown)
      let layName = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}__name`)[0]
      let layBeacon = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}`)[0]
      if (layName) {
        layName.remove()
        layBeacon.remove()
      }
      navigate("/dashboard/logistics-management")
      toast.success("Deleted successfully")
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      setDeleteLaydownLoader(false)
      setConfirmationModal(false)
    }
  }

  const handleDownloadLaydown = async () => {
    try {
      await setDownloadLaydown({ laydown_id: currentLaydown, laydownName: handleFindCurrentLaydownName(currentLaydown), project_name: currentProject, formattedDate: formattedDate, details: listLaydown.data })
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
  }

  const handleAutoManageLaydownDownload = async () => {
    try {
      await setAutoManageLaydownDownload({ laydown_id: currentLaydown, laydownName: handleFindCurrentLaydownName(currentLaydown), project_name: currentProject, formattedDate: formattedDate, details: listLaydown.data })
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
  }

  const customStylesEditRemediatyQty = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "0px 20px 20px",
      background: "white"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const customConfirmStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      boxShadow: "3px 3px 10px 1px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex space-x-4 items-center pl-5'>
          <p className='my-2 py-2 font-bold text-xl'>Logistics Management - Inventory Log</p>
        </div>
        <div className='flex items-center space-x-4'>
          {currentLaydown && (
            <div>
              <button className='cursor-pointer border-none px-3 py-2 hover:bg-gray-700 text-xs font-semibold bg-red-550 text-white rounded-xl' onClick={() => setShowManageDelivery(true)}>Manage Delivery</button>
            </div>
          )}
          {showManageDelivery && <ManageDelivery deliveryModal={showManageDelivery} formattedDate={formattedDate} setDeliveryModal={setShowManageDelivery} project_name={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))} />}
          <div className='flex space-x-4 items-center mr-4 w-36'>
            <ViewByDropDown onSelect={handleChangeView} defaultValue="Logistics Management" />
          </div>
          {showRightPanel ?
            <div className='text-xl text-black border bg-gray-200 mx-2'>
              <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowRightPanel(false)} />
            </div>
            :
            <div className='text-xl text-black border bg-gray-200 mx-2'>
              <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowRightPanel(true)} />
            </div>
          }
        </div>
      </div>
      <hr />
      <InventoryLogTable
        manage={manage}
        listLaydown={listLaydown}
        currentLaydown={currentLaydown}
        inventoryLoader={inventoryLoader}
        setInventoryLoader={setInventoryLoader}
        setOpenRemediateQty={setOpenRemediateQty}
        // setViewToggle={e => { setViewToggle(e); viewToggleRef.current = e }}
        setInventryBomId={setInventryBomId}
        setConfirmationModal={setConfirmationModal}
        setRemediateQtyBomDetails={setRemediateQtyBomDetails}
        showRightPanel={showRightPanel}
        currentProject={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
        formattedDate={formattedDate}
      />
      <Modal
        isOpen={openEditRemediateQty}
        onRequestClose={() => setOpenRemediateQty(false)}
        style={customStylesEditRemediatyQty}
        contentLabel="Remediate Qty Modal"
      >
        <div className='p-3 w-[50vw]'>
          <div className='flex justify-between items-center m-2'>
            <div >
              <p className='mt-4 font-bold text-xl'>
                {remediateQtyBomDetails?.part_name}
              </p>
              <p className='mt-2'>{remediateQtyBomDetails?.description}</p>
            </div>
            <div className='flex space-x-4 items-center'>
              <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button px-6' onClick={handleSaveRemediatedQty}>
                {remediateLoader ?
                  <div>
                    <p className='loader mr-4' style={{width: "20px", height: "20px"}}></p>
                  </div>
                  :
                  null
                }
                Save
              </button>
              <VscClose className='cursor-pointer active:text-white border-none text-xl rounded-full hover:bg-gray-100 border active:bg-red-550' onClick={() => setOpenRemediateQty(false)} />
            </div>
          </div>
          <table className='table table-compact w-full overflow-auto z-0 h-full my-3'>
            <thead>
              <tr>
                <th className='normal-case bg-red-200 w-[40%]'>{getDeliveryReferenceName() !== "" ? getDeliveryReferenceName() : "Delivery Reference"}</th>
                <th className='normal-case bg-red-200 w-[20%]'>Delivered Qty</th>
                <th className='normal-case bg-red-200 w-[20%]'>Damaged Qty</th>
                <th className='normal-case bg-red-200 w-[20%]'>Remediated Qty</th>
              </tr>
            </thead>
            <tbody>
              {listMileStone?.remediateQty?.length ? listMileStone?.remediateQty?.map((laydownIdKey, index) => {
                return (
                  <tr>
                    <td>{laydownIdKey?.delivery_name}</td>
                    <td>{laydownIdKey?.count}</td>
                    <td>{laydownIdKey?.damaged}</td>
                    <td>
                      <input className='border w-[98%] p-1'
                        placeholder='Remediated Qty'
                        defaultValue={laydownIdKey?.remediated_quantity ? laydownIdKey?.remediated_quantity === null ? 0 : laydownIdKey?.remediated_quantity : 0}
                        onChange={e => handleChangeRemediatedQty(e, index)} />
                    </td>
                  </tr>
                )
              })
                :
                null
              }
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal
        isOpen={confimationModal}
        style={customConfirmStyle}
        contentLabel='Laydown Confirmation'
      >
        <div className='p-2 w-[40vw]'>
          <div className='flex justify-between mb-4'>
            <p className='text-xl font-semibold'>Delete Laydown</p>
            <VscClose className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} onClick={() => setConfirmationModal(false)} />
          </div>
          <hr />
          {handleCheckQuantity() ?
            <p className='my-4'>You are deleting the laydown with some current quantities and / or damaged quantities reported, this deletion will modify the initial delivery records accordingly. You may download the inventory log for your reference if required</p>
            :
            <p className='my-4'>Deleting the laydown will delete all the associated inventory log as well. Please download the log for your reference if required and proceed with deletion if needed</p>
          }
          <hr />
          <div className='mt-4 flex flex-col space-y-3'>
            <p className='text-right cursor-pointer text-gray-500 font-semibold' onClick={() => setConfirmationModal(false)}>Cancel</p>
            <p className='text-right cursor-pointer font-semibold text-black' onClick={manage === 1 ? handleAutoManageLaydownDownload : handleDownloadLaydown}>Download inventory log</p>
            <div>
              {deleteLaydownLoader ?
                <p className='text-right flex justify-end space-x-3 items-center cursor-pointer text-red-550 font-semibold'>
                  <p className='loader' style={{ width: "25px", height: "25px" }}></p>
                  <p>Understood, proceed with deletion</p>
                </p>
                :
                <p className='text-right flex space-x-3 justify-end items-center cursor-pointer text-red-550 font-semibold' onClick={handleDeleteLaydown}>
                  Understood, proceed with deletion
                </p>
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InventoryLog
