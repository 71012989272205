import React, { useEffect, useRef } from 'react'
import * as d3 from "d3";
import { LiaLocationArrowSolid } from "react-icons/lia"

function QcBlock(props) {

    const containerRef = useRef()

    useEffect(() => {
        let pierDataRevamp = props.data?.pier_parts
        let ttDataRevamp = props.data?.tt_parts
        renderMap([...pierDataRevamp].sort((a, b) => a.order - b.order), [...ttDataRevamp].sort((a, b) => a.order - b.order))
    }, [props.selectedItem, props.selectedTab, props.editMode, props.lockAll])

    const renderMap = (pierData, ttData) => {

        containerRef.current.innerHTML = "";

        if (props.selectedItem && props.lockAll) {
            if (props.selectedTab === "Piers") {
                let prData = pierData.filter(o => o.id === props.selectedItem)[0]
                if (prData) {
                    d3.select(containerRef.current)
                        .append("rect")
                        .attr("x", 38)
                        .attr("y", prData?.order * 40 - 2)
                        .attr("width", 25)
                        .attr("height", 25)
                        .attr("fill", "#F6BE00")
                }
            }
            else {
                let prData = ttData.filter(o => o.id === props.selectedItem)[0]
                if (prData) {
                    d3.select(containerRef.current)
                        .append("rect")
                        .attr("x", 43)
                        .attr("y", prData?.order * 40 + 10)
                        .attr("width", 16)
                        .attr("height", 40)
                        .attr("fill", "#F6BE00")
                }
            }
        }

        if (!props.lockAll) {
            d3.select(containerRef.current)
                .append("rect")
                .attr("x", 43)
                .attr("y", 10)
                .attr("width", 16)
                .attr("height", parseInt(ttData.length) * 40)
                .attr("fill", "#F6BE00")
        }

        ttData?.map((key) => {
            if (key.tracker_row_type === 0) {
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("class", "tracker-row")
                    .style("cursor", `${(props.lockAll && props.selectedTab !== "Piers") ? "pointer" : "default"}`)
                    .attr("x", 48)
                    .attr("y", key?.order * 40 + 10)
                    .attr("width", "5px")
                    .attr("height", 33)
                    .attr("stroke", "gray")
                    .attr("stroke-width", "1px")
                    .attr("fill", "gray")
                    .on("click", () => {
                        if (props.selectedTab !== "Piers" && ((props.lockAll))) {
                            props.onSelectItem(key.id)
                        }
                    })
            }
            if (key.tracker_row_type === 1) {
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("class", "tracker-row")
                    .style("cursor", `${(props.lockAll && props.selectedTab !== "Piers") ? "pointer" : "default"}`)
                    .attr("x", 48)
                    .attr("y", key?.order * 40 + 10)
                    .attr("width", "5px")
                    .attr("height", 33)
                    .attr("stroke", "gray")
                    .attr("stroke-width", "1px")
                    .attr("fill", "white")
                    .on("click", () => {
                        if (props.selectedTab !== "Piers" && ((props.lockAll))) {
                            props.onSelectItem(key.id)
                        }
                    })
            }
            if (key.tracker_row_type === 2) {
                d3.select(containerRef.current)
                    .append("line")
                    .style("cursor", `${(props.lockAll && props.selectedTab !== "Piers") ? "pointer" : "default"}`)
                    .attr("x1", 50)
                    .attr("y1", key?.order * 40 + 15)
                    .attr("x2", 50)
                    .attr("y2", key?.order * 40 + 49)
                    .attr("stroke-width", "5px")
                    .attr("stroke", "gray")
                    .attr("stroke-dasharray", "1 1")
                    .on("click", () => {
                        if (props.selectedTab !== "Piers" && ((props.lockAll))) {
                            props.onSelectItem(key.id)
                        }
                    })
            }
        })

        let pier = d3
            .select(containerRef.current)
            .selectAll("pier")
            .data(pierData)
            .enter()
            .append("g")
            .attr("class", (pr) => `trow-${pr["id"]}-${pr["tracker_type_id"]}`)
            .attr("id", (pr) => `pier-${pr["id"]}-${pr["tracker_type_id"]}`)

        pier.filter((pr) => {
            let prlegend = props.pierLegend[pr.part_number]
            if (!prlegend) {
                return true
            }
        })

        pier.filter((pr) => {
            let prlegend = props.pierLegend[pr.part_number]
            if (prlegend) {
                return prlegend.shape === "Circle";
            }
        })
            .append("circle")
            .attr("class", `pier`)
            .style("cursor", `${(props.lockAll && props.selectedTab === "Piers") ? "pointer" : "default"}`)
            .attr("cx", 50)
            .attr("cy", (pr) => pierData.findIndex(o => o.id === pr.id) * 40 + 10)
            .attr("r", 7)
            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props.selectedTab === "Piers" && ((props.lockAll))) {
                    props.onSelectItem(pr.id)
                }
            })

        pier
            .filter((pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                if (prlegend) {
                    return prlegend.shape === "Square";
                }
            })
            .append("rect")
            .attr("class", `pier`)
            .style("cursor", `${(props.lockAll && props.selectedTab === "Piers") ? "pointer" : "default"}`)
            .attr("id", (_, pri) => `pier-${pri + 1}`)
            .attr("height", 14)
            .attr("width", 14)
            .attr("x", 43)
            .attr("y", (pr) => pierData.findIndex(o => o.id === pr.id) * 40 + 4)
            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props.selectedTab === "Piers" && ((props.lockAll))) {
                    props.onSelectItem(pr.id)
                }
            })

        pier
            .filter((pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                if (prlegend) {
                    return prlegend.shape === "Diamond";
                }
            })
            .append("rect")
            .attr("class", `pier`)
            .style("cursor", `${(props.lockAll && props.selectedTab === "Piers") ? "pointer" : "default"}`)
            .attr("id", (_, pri) => `pier-${pri + 1}`)
            .attr("height", 14)
            .attr("width", 14)
            .attr("x", 40)
            .attr("y", (pr) => pierData.findIndex(o => o.id === pr.id) * 40 - 5)
            .attr(
                "transform",
                (pr) =>
                    `translate(40, ${pierData.findIndex(o => o.id === pr.id) * 40 + 10}) rotate(45) translate(-40, -${pierData.findIndex(o => o.id === pr.id) * 40 + 10})`
            )
            .attr("stroke", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.border;
            })
            .attr("fill", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return prlegend.background;
            })
            .attr("stroke-width", (pr) => {
                let prlegend = props.pierLegend[pr.part_number]
                return `${prlegend.width}px`;
            })
            .on("click", (_, pr) => {
                if (props.selectedTab === "Piers" && ((props.lockAll))) {
                    props.onSelectItem(pr.id)
                }
            })

        pier
            .append("line")
            .style("cursor", `${(props.lockAll && props.selectedTab === "Piers") ? "pointer" : "default"}`)
            .attr("x1", 46)
            .attr("y1", (_, index) => index * 40 + 10)
            .attr("x2", 54)
            .attr("y2", (_, index) => index * 40 + 10)
            .attr("stroke", "black")
            .on("click", (_, pr) => {
                if (props.selectedTab === "Piers" && ((props.lockAll))) {
                    props.onSelectItem(pr.id)
                }
            })
    }

    return (
        <div className='flex flex-col h-full' id="Qcd3">
            <LiaLocationArrowSolid className='m-auto text-3xl' />
            <p className='font-bold text-base m-auto mb-1'>N</p>
            <svg ref={containerRef} className='h-[100%]' />
        </div>
    )
}

export default QcBlock
