import axios from "axios";
import { atom } from "jotai";
import { Qc_Enable, Staff_Enable, Access_enable, } from "../pages/constants";

const project_orgAtom = atom({
    "Dashboard":true,
    "Reports":true,
    "mech-Construction Progress":true,
    "mech-Quality Check":Qc_Enable,
    "mech-Inventory management":true,
    "electrical":true,
    "civil":true,
    "general-Staff and Plant":true,
    "general-Edit access rights":Access_enable,
    
})


export const fetchProjOrgAtom=atom(null,
    async(get,set,args)=>{
        if(Access_enable && (localStorage.getItem("project_id")!==null)){
            let res=await axios.get(`/project/get-org-permission?project_id=${parseInt(localStorage.getItem("project_id"))}`)
            if (res.data!==null && Array.isArray(res.data)&&res.data.length!==0){
                //flatten permissions
                try {
                    const flat_permission = {
                        "Dashboard":true,
                        "Setup":true,
                        "Reports":true,
                        "mech-Construction Progress": res.data[0]?.mechanical["Construction Progress"],
                        "mech-Quality Check": Qc_Enable && res.data[0]?.mechanical["Quality Check"],
                        "mech-Inventory management": res.data[0]?.mechanical["Inventory management"],
                        "electrical": res.data[1]?.electrical,
                        "civil": res.data[2]?.civil,
                        "general-Staff and Plant": Staff_Enable && res.data[3]?.general["Staff and Plant"],
                        "general-Edit access rights": Access_enable &&res.data[3]?.general["Edit access rights"],

                    }


                    set(project_orgAtom, flat_permission)
                }
                catch (err) {
                    console.log(err)
                }

                return res.data
            }
        }
        else { return 0 }
    })

export default project_orgAtom