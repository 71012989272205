import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
import { get_all_activities } from "../dal/activities";

const workHourAtom = atom({
    data: [],
    importData: [],
    activities: [],
    users: [],
    data_group: [],
    totalWorkHours: [],
    individualAct: [],
    plantId: [],
    rawData: []
})

export const fetchWorkHoursAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/work-hours/?date=${args}`)

        for (let pic of res.data) {
            let temp = {}
            if (pic?.Staff?.profile_data?.other_documents) {
                temp = pic?.Staff?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }

            if (temp?.upload_id) {
                // pic.Staff.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.Staff.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.Staff.profile_data["profile_url"] = null
            }
        }

        let newArr = []


        res.data.map((j) => {
            newArr.push({
                activity_id: j.Workhours.activity_id,
                date: j.Workhours.date,
                hours: j.Workhours.hours,
                id: j.Workhours.id,
                plant_project_id: j.Workhours.plant_pk,
                project_id: j.Workhours.project_id,
                type: j.Workhours.type,
                user_app_id: j.Workhours.user_app_id,
                plant_id: j.plant_id,
                plant_type: j.plant_type,
                firstname: j?.Staff?.name,
                profile_url: j?.Staff?.profile_data?.profile_url
            })
        })


        set(workHourAtom, produce((draft) => { draft.data = newArr }));
        let newGroup = []
        let temp = []
        for (let wh of res.data) {
            let index = newGroup.findIndex(act => act.activity_id === wh.Workhours.activity_id && act.type === wh.Workhours.type)
            if (index >= 0) {
                newGroup[index]["activity_id"] = wh.Workhours.activity_id
                newGroup[index]["type"] = wh.Workhours.type
                newGroup[index]["hours"] += wh.Workhours.hours
                newGroup[index]["user_app_id"] = wh.Workhours.user_app_id
                newGroup[index]["date"] = wh.Workhours.date
                newGroup[index]["id"] = wh.Workhours.id
                newGroup[index]["plant_pk"] = wh.Workhours.plant_pk
                newGroup[index]["project_id"] = wh.Workhours.project_id
                newGroup[index]["plant_type"] = wh.plant_type
                newGroup[index]["plant_id"] = wh.plant_id
                newGroup[index]["users"] += 1
                temp.push(wh.Workhours.user_app_id)
                newGroup[index]["profile_urls"] = temp
            } else {
                newGroup.push({
                    activity_id: wh.Workhours.activity_id,
                    type: wh.Workhours.type,
                    hours: wh.Workhours.hours,
                    user_app_id: wh.Workhours.user_app_id,
                    date: wh.Workhours.date,
                    id: wh.Workhours.id,
                    plant_pk: wh.Workhours.plant_pk,
                    project_id: wh.Workhours.project_id,
                    plant_type: wh.plant_type,
                    plant_id: wh.plant_id,
                    users: 1,
                    profile_urls: wh.Workhours.user_app_id
                })
                temp.push(wh.Workhours.user_app_id)
            }
        }
        set(workHourAtom, produce((draft) => { draft.data_group = newGroup }))
        set(workHourAtom, produce((draft) => { draft.rawData = res.data }))
    }
)

export const fetchImportWorkHoursAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/work-hours/?date=${args}`)

        for (let pic of res.data) {
            let temp = {}
            if (pic?.Staff?.profile_data?.other_documents) {
                temp = pic?.Staff?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }

            if (temp?.upload_id) {
                // pic.Staff.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.Staff.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.Staff.profile_data["profile_url"] = null
            }
        }

        let newArr = []

        res.data.map((j) => {
            newArr.push({
                activity_id: j.Workhours.activity_id,
                date: j.Workhours.date,
                hours: j.Workhours.hours,
                id: j.Workhours.id,
                plant_project_id: j.Workhours.plant_pk,
                project_id: j.Workhours.project_id,
                type: j.Workhours.type,
                user_app_id: j.Workhours.user_app_id,
                plant_id: j.plant_id,
                plant_type: j.plant_type,
                firstname: j?.Staff?.name,
                profile_url: j?.Staff?.profile_data?.profile_url
            })
        })

        set(workHourAtom, produce((draft) => { draft.importData = newArr }));
    }
)


export const fetchWorkHourActivitiesAtom = atom(
    null,
    async (get, set, args) => {
        let res = await get_all_activities()
        let temp = []

        for (let key in res) {
            if (key === "construction" || key === "work_hours") {
                for(let i in res[key]){
                    let tempObj = {...res[key][i]}
                    if(key === "construction"){
                        tempObj["type"] = "construction_activity"
                    }
                    if(key === "work_hours"){
                        tempObj["type"] = "workhours_activity"
                    }
                    // tempObj["type"] = key
                    temp.push(tempObj)
                }
            }
        }
        // let temp = []
        // for (let activity of res.data.data) {
        //     temp.push({
        //         id: activity.activity_id,
        //         name: activity.activity
        //     })
        //     for (let sub_activity of activity.sub_activity) {
        //         temp.push({
        //             id: sub_activity.activity_id,
        //             name: sub_activity.activity
        //         })
        //     }
        // }
        console.log(temp)
        set(workHourAtom, produce((draft) => { draft.activities = temp }))
    }
)
// export const fetchWorkHourActivitiesAtom = atom(
//     null,
//     async (get, set, args) => {
//         let res = await axios.get(`/projects/fetch/activities`)
//         let temp = []

//         for (let key of res.data) {
//             if (key.type === "construction_activity" || key.type === "workhours_activity") {
//                 temp.push(key)
//             }
//         }
//         console.log(temp)
//         // let temp = []
//         // for (let activity of res.data.data) {
//         //     temp.push({
//         //         id: activity.activity_id,
//         //         name: activity.activity
//         //     })
//         //     for (let sub_activity of activity.sub_activity) {
//         //         temp.push({
//         //             id: sub_activity.activity_id,
//         //             name: sub_activity.activity
//         //         })
//         //     }
//         // }
//         set(workHourAtom, produce((draft) => { draft.activities = temp }))
//     }
// )
export const fetchWorkHourUsersAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/staff/only_project`);

        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(workHourAtom, produce((draft) => { draft.users = res.data }));
    }
)

export const createWorkHoursAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/work-hours`, args.data);
        let res = await axios.get(`/projects/work-hours/?date=${args.date}`)

        for (let pic of res.data) {
            let temp = {}
            if (pic?.Staff?.profile_data?.other_documents) {
                temp = pic?.Staff?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }

            if (temp?.upload_id) {
                // pic.Staff.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.Staff.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.Staff.profile_data["profile_url"] = null
            }
        }

        let newArr = []


        res.data.map((j) => {
            newArr.push({
                activity_id: j.Workhours.activity_id,
                date: j.Workhours.date,
                hours: j.Workhours.hours,
                id: j.Workhours.id,
                plant_project_id: j.Workhours.plant_pk,
                project_id: j.Workhours.project_id,
                type: j.Workhours.type,
                user_app_id: j.Workhours.user_app_id,
                plant_id: j.plant_id,
                plant_type: j.plant_type,
                firstname: j?.Staff?.name,
                profile_url: j?.Staff?.profile_data?.profile_url
            })
        })

        set(workHourAtom, produce((draft) => { draft.data = newArr }));
    }
)

export const editWorkHoursAtom = atom(
    null,
    async (get, set, args) => {

        await axios.put(`/projects/work-hours/staff/`, args);
    }
)

export const deleteWorkHoursAtom = atom(
    null,
    async (get, set, args) => {
        await axios.delete(`/projects/work-hours/?workhours_id=${args}`);
        let res = await axios.get(`/projects/work-hours/?date=${new Date().toISOString().split('T')[0]}`)



        for (let pic of res.data) {
            let temp = {}
            if (pic?.Staff?.profile_data?.other_documents) {
                temp = pic?.Staff?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }

            if (temp?.upload_id) {
                // pic.Staff.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.Staff.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.Staff.profile_data["profile_url"] = null
            }
        }

        let newArr = []

        res.data.map((j) => {
            newArr.push({
                activity_id: j.Workhours.activity_id,
                date: j.Workhours.date,
                hours: j.Workhours.hours,
                id: j.Workhours.id,
                plant_project_id: j.Workhours.plant_pk,
                project_id: j.Workhours.project_id,
                type: j.Workhours.type,
                user_app_id: j.Workhours.user_app_id,
                plant_id: j.plant_id,
                plant_type: j.plant_type,
                firstname: j?.Staff?.name,
                profile_url: j?.Staff?.profile_data?.profile_url
            })
        })

        set(workHourAtom, produce((draft) => { draft.importData = newArr }));
    }
)

export const fetchTotalWorkHours = atom(
    null,
    async (get, set, args) => {

        let res = await axios.get(`/projects/work-hours/${args.date}`)
        set(workHourAtom, produce((draft) => { draft.totalWorkHours = res.data }))
    }
)

export const fetchIndividualAct = atom(
    null,
    async (get, set, args) => {

        let res = await axios.get(`/projects/work-hours/?date=${args.date}&activity_id=${args.act_id}&type=${args.type}`)

        for (let pic of res.data) {
            let temp = {}
            if (pic?.Staff?.profile_data?.other_documents) {
                temp = pic?.Staff?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.Staff.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.Staff.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.Staff.profile_data["profile_url"] = null
            }
        }

        let newArr = []

        res.data.map((j) => {
            newArr.push({
                activity_id: j.Workhours.activity_id,
                date: j.Workhours.date,
                hours: j.Workhours.hours,
                id: j.Workhours.id,
                plant_project_id: j.Workhours.plant_pk,
                project_id: j.Workhours.project_id,
                type: j.Workhours.type,
                user_app_id: j.Workhours.user_app_id,
                plant_id: j.plant_id,
                plant_type: j.plant_type,
                firstname: j?.Staff?.name,
                profile_url: j?.Staff?.profile_data?.profile_url
            })
        })
        set(workHourAtom, produce((draft) => { draft.individualAct = newArr }))
    }
)

export const fetchPlantId = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/org_plant?only_org=False`)
        set(workHourAtom, produce((draft) => { draft.plantId = res.data }))
    }
)

export const createNewActivity = atom(
    null,
    async (get, set, args) => {
        let temp = {}
        temp.name = args
        temp.weight = 0
        temp.parent_activity_id = 0
        temp.part_id = 0
        await axios.post(`/projects/activity`, temp)
    }
)

export const dateAtom = atom(new Date());
export default workHourAtom;
