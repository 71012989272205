import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import projectAtom, { fetchProjectAtom } from '../atoms/projectAtom'
import userAtom from '../atoms/userAtom'
import Cookies from "js-cookie";
import { IoLogOut } from "react-icons/io5"
import Modal from 'react-modal';

function ProjectProvider(props) {

    const [loading, setLoading] = useState(false)
    const [projects] = useAtom(projectAtom);
    const [user] = useAtom(userAtom);
    const [, fetchProject] = useAtom(fetchProjectAtom)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            if (user.loggedIn) {
                await fetchProject()
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px",
            zIndex: 999,
        },
    };

    const logout = () => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        localStorage.clear()
        window.location.href = "/login"
    }

    if (loading) {
        return <p className='loader m-auto' style={{ marginTop: '22vw' }}></p>
    }

    if (projects.length < 1 && user.loggedIn) {
        return (
            <div className="w-full h-screen bg-white">
                <div className="w-full h-[100%] flex flex-col">
                    <div className="flex justify-between border h-[9%]">
                        <div className="flex">
                            <div className="flex justify-center py-4 ml-5">
                                <img src="/suryalogo.svg" alt="" style={{ width: '175px' }} />
                            </div>
                        </div>
                        <div className="flex space-x-5 justify-end items-center mr-4">
                            <div className="dropdown dropdown-bottom dropdown-end mr-10 ml-6 ">
                                <label tabIndex={0} className="cursor-pointer">
                                    <div className="avatar placeholder">
                                        <div className=" bg-red-550 text-white text-neutral-content rounded-full w-10">
                                            <span className="text-base font-semibold">{user?.data?.profile_data?.first_name.charAt(0).toUpperCase()}</span>
                                        </div>
                                    </div>
                                </label>
                                <ul tabIndex={0} className="dropdown-content bg-white text-black menu p-2 shadow bg-base-100 rounded-box w-52 z-40">
                                    <li onClick={openModal}><a className="flex active:bg-red-500">
                                        <IoLogOut className="text-lg rotate-180" /><p>Log out</p></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="m-auto flex flex-col space-y-3 justify-center font-semibold text-gray-400" style={{ marginTop: "20%" }}>
                        <BsFillFileEarmarkImageFill className='text-6xl text-gray-300 m-auto' />
                        <p>No project available</p>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Logout Modal"
                >
                    <p className="text-lg font-bold text-black my-2 mb-8 pr-24">Are you sure you want to logout ?</p>
                    <div className="flex justify-end mt-4">
                        <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                        <button onClick={logout} className='btn bg-red-550 text-white border-none rounded-3xl'>Yes, Sure</button>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <div>{props.children}</div>
    )
}

export default ProjectProvider
