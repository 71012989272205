import { useAtom } from "jotai"
import { BiTimeFive } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { BsThreeDots } from "react-icons/bs"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import workHourAtom, { fetchIndividualAct, fetchWorkHourUsersAtom } from "../../../atoms/workHoursAtom"
import staffAtom, { fetchStaffAtom } from "../../../atoms/staffAtom"
import { useEffect } from "react"

export default function GridBoxView({ values, title, staff, hours, rawData, date }) {

    const [listWorkHours] = useAtom(workHourAtom)
    const [, setFetchIndividualAct] = useAtom(fetchIndividualAct)
    const [, setListUsers] = useAtom(fetchWorkHourUsersAtom)
    const [listStaff] = useAtom(staffAtom)
    const [, setFetchStaff] = useAtom(fetchStaffAtom)

    const navigate = useNavigate()

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await setFetchStaff()
            await setListUsers()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = async () => {
        try {
            await setFetchIndividualAct({ date: date.toISOString().split('T')[0], act_id: values?.activity_id, type: values?.type })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleClick = () => {
        handleSubmit()
        navigate("/staff_and_plant/workHour/activity", {
            state: {
                date: date,
                act_id: values?.activity_id,
                type: values?.type
            }
        })
    }


    const findProfileImg = (data) => {
        let temp = null
        listStaff?.users?.map((i) => {
            if (i?.user?.id === data) {
                temp = i?.user?.profile_data?.profile_url
            }
        })
        return temp
    }

    let newArr = []
    rawData.map((i) => {
        if (i.Workhours?.activity_id === values.activity_id && i?.Workhours?.type === values?.type) {
            newArr.push(i?.Staff?.id)
        }
    })

    const findUserFirstLetter = (user_id) => {
        let user = listWorkHours.users.find(user => user?.user?.id === user_id);
        if (user) {
            return user.user?.name?.charAt(0).toUpperCase()
        }
        return user_id
    }


    return (
        <div className='border border-gray-300 rounded-xl mx-5 mt-5 cursor-pointer' onClick={handleClick} style={{ width: '383px', height: '162px' }}>
            <div className='flex justify-between m-6'>
                <p className='font-semibold text-base'>{title}</p>
            </div>
            <div className='flex'>
                <div className='mt-1 ml-6'>
                    <p className='font-medium text-sm text-gray-400 mb-2'>Assigned Staff <span className='font-semibold text-sm text-black ml-2'>{staff}</span> </p>

                    {staff > 4 ?
                        <div className="avatar-group -space-x-3">
                            <div className="avatar" >
                                {findProfileImg(newArr[0]) !== null ?
                                    <div className="w-8 h-6 mt-2">
                                        <img src={findProfileImg(newArr[0])} alt='' />
                                    </div>
                                    :
                                    <div className='avatar'>
                                        <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                            <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[0])}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="avatar" >
                                {findProfileImg(newArr[1]) !== null ?
                                    <div className="w-8 h-6 mt-2">
                                        <img src={`${findProfileImg(newArr[1])}`} alt='' />
                                    </div>
                                    :
                                    <div className='avatar'>
                                        <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                            <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[1])}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="avatar" >
                                {findProfileImg(newArr[2]) !== null ?
                                    <div className="w-8 h-6 mt-2">
                                        <img src={`${findProfileImg(newArr[2])}`} alt='' />
                                    </div>
                                    :
                                    <div className='avatar'>
                                        <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                            <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[2])}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="avatar" >
                                {findProfileImg(newArr[3]) !== null ?
                                    <div className="w-8 h-6 mt-2">
                                        <img src={`${findProfileImg(newArr[3])}`} alt='' />
                                    </div>
                                    :
                                    <div className='avatar'>
                                        <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                            <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(newArr[3])}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='avatar placeholder'>
                                <div className="text-neutral-content rounded-full w-8 h-8 mt-1 text-center bg-gray-200 p-0.5 pt-1 text-white text-base">
                                    <span className='text-gray-500'>+{staff - 4}</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="avatar-group -space-x-4">
                            {newArr?.map((j) => {
                                return (
                                    <div className="avatar" >
                                        {findProfileImg(j) !== null ?
                                            <div className="w-8 h-6 mt-2">
                                                <img src={`${findProfileImg(j)}`} alt='' />
                                            </div>
                                            :
                                            <div className='avatar'>
                                                <div className="text-neutral-content rounded-full w-8 text-center bg-yellow-400 p-0.5 pt-1 text-white text-base">
                                                    <span className="text-base" style={{ textTransform: 'capitalize' }}>{findUserFirstLetter(j)}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>)

                            })}
                        </div>
                        // <div className="avatar-group -space-x-3">
                        //     {Array.from({length: staff}, (data,i) => 
                        //     <div key={i} className="avatar" >
                        //         <div className="w-8">
                        //             <img src="https://placeimg.com/192/192/people" alt='' />
                        //         </div>
                        //     </div>
                        //     )}
                        // </div>
                    }

                </div>
                <div className='mt-1 ml-8'>
                    <p className='font-medium text-sm text-gray-400 mb-2'>Total Work Hours</p>
                    <div className='flex'>
                        <BiTimeFive className="text-gray-400" style={{ width: "20px", height: "20px" }} />
                        {/* <img src='/Time.jpg' alt='' style={{ width: '20px' }} /> */}
                        <p className='font-semibold text-sm text-gray-500 ml-2'>{hours} Hrs</p>
                    </div>
                </div>
            </div>

        </div>
    )
}
