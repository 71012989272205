import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown } from "react-icons/md"

function DropdownMenu({ title, children, tableRef }) {

    const [isOpen, setIsOpen] = useState(false);
    const [showAbove, setShowAbove] = useState(false)
    const dropdownRef = useRef(null)
    const optionsRef = useRef(null)

    const calculatePosition = useCallback(() => {
        if (dropdownRef.current && optionsRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const optionsRect = optionsRef.current.getBoundingClientRect();
            const viewportHeight = tableRef.current.getBoundingClientRect();

            const spaceBelow = viewportHeight.bottom - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;

            setShowAbove(spaceBelow < optionsRect.height && spaceAbove > spaceBelow);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            calculatePosition();
            window.addEventListener('resize', calculatePosition);
            return () => window.removeEventListener('resize', calculatePosition);
        }
    }, [isOpen, calculatePosition]);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown relative" ref={dropdownRef}>
            <button tabIndex={0} className="dropdown-toggle flex items-center space-x-2 border px-2 py-1.5 bg-white w-auto" onClick={toggleMenu}>
                <p>{title}</p>
                <MdKeyboardArrowDown className='text-xl' />
            </button>
            {isOpen &&
                <div ref={optionsRef} className={`dropdown-menu border px-4 py-3 rounded-xl z-40 absolute ${showAbove ? 'bottom-full mb-1' : 'top-full mt-1'} bg-white`} onClick={toggleMenu}>
                    {children}
                </div>}
        </div>
    );
}

export default DropdownMenu

export function SubDropdownMenu({ tableRef, title, children, show }) {

    const [showAbove, setShowAbove] = useState(false)
    const dropdownRef = useRef(null)
    const optionsRef = useRef(null)

    const calculatePosition = useCallback(() => {
        if (dropdownRef.current && optionsRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const optionsRect = optionsRef.current.getBoundingClientRect();
            const viewportHeight = tableRef.current.getBoundingClientRect();

            const spaceBelow = viewportHeight.bottom - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;

            setShowAbove(spaceBelow < optionsRect.height && spaceAbove > spaceBelow);
        }
    }, []);

    useEffect(() => {
        if (show) {
            calculatePosition();
            window.addEventListener('resize', calculatePosition);
            return () => window.removeEventListener('resize', calculatePosition);
        }
    }, [show, calculatePosition]);

    return (
        <div className="sub-dropdown relative">
            <button className="sub-dropdown-toggle hover:bg-gray-100 px-3 py-2 rounded-xl">
                {title}
            </button>
            {show ?
                <div className={`sub-dropdown-menu absolute max-h-[120px] overflow-y-auto left-36 z-[99] border bg-white rounded-xl py-2 px-2 ${!showAbove ? 'bottom-0' : 'top-full mt-1'}`}>
                    {children}
                </div>
                :
                null
            }
        </div>
    );
}
